import i18n from "../locales/index"
export default function debounce(Vue) {
  return Vue.directive("debounce", {
    // 指令第一次绑定到元素时
    bind: function (el, binding, vnode) {
      if (binding.value === undefined) {
        binding.value = {}
      }
      if (binding.value === "number") {
        binding.value = {
          wait: binding.value,
        }
      }
      let wait = binding.value.wait // 防抖时间
      let disable = Boolean(binding.value.disable) // 是否禁用
      if (disable) return
      if (!wait) {
        // 用户若不设置防抖时间，则默认2s
        wait = 500
      }
      let timer
      el.addEventListener(
        "click",
        (event) => {
          if (!timer) {
            // 第一次执行: 不阻止click⌚️
            timer = setTimeout(() => {
              timer = null
            }, wait)
          } else {
            clearTimeout(timer)
            timer = setTimeout(() => {
              timer = null
            }, wait)
            event && event.stopImmediatePropagation()
          }
        },
        true
      )
    },
  })
}
