import i18n from "../../locales/index"
import {
  productList,
  productUnitsList,
  productUnitsCreate,
  productBatchAdjustEstimate,
} from "@/api/product"

const state = {
  products: [],
  searchProducts: [],
  queryProductInfo: "",
  selectedStartIndex: 0,
  selectedEndIndex: 5,
  showFastSearch: false, // 显示快速搜索
  fastSearchValue: "", // 快速搜索的内容
}
const mutations = {
  SET_FAST_SEARCH_VALUE: (state, value) => {
    state.fastSearchValue = value
  },
  SET_SHOW_FAST_SEARCH: (state, showFastSearch) => {
    state.showFastSearch = showFastSearch
    if (showFastSearch) {
      // 重置搜索值
      state.fastSearchValue = ""
      state.searchProducts = []
    }
  },
  RESET_FAST_SEARCH: (state) => {
    state.fastSearchValue = ""
    state.searchProducts = []
  },
  SET_SELECTED_START_INDEX: (state, selectedStartIndex) => {
    state.selectedStartIndex = selectedStartIndex
  },
  SET_SELECTED_END_INDEX: (state, selectedEndIndex) => {
    state.selectedEndIndex = selectedEndIndex
  },
  SET_PRODUCTS: (state, products) => {
    state.products = products
  },
  SET_SEARCH_PRODUCTS: (state, searchProducts) => {
    state.searchProducts = searchProducts
  },
  SET_QUERY_PRODUCT: (state, data) => {
    state.queryProductInfo = data
  },
}
const getters = {}

const actions = {
  productList({ commit }, query) {
    return new Promise((resolve, reject) => {
      productList(query)
        .then((res) => {
          // state.products = res.data;
          // state.categoryTotal = meta.total;
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  productUnitsList({ commit }, query) {
    return new Promise((resolve, reject) => {
      productUnitsList(query)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  productUnitsCreate({ commit }, data) {
    return new Promise((resolve, reject) => {
      productUnitsCreate(data)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 批量下单或退单调整估清额
  productBatchAdjustEstimate({ commit }, { products, type = "reduce" }) {
    console.log("products", products, type)
    const items = []
    products.forEach((product) => {
      if (product.open_estimation) {
        items.push({
          product_id: product.id,
          available_count: type === "reduce" ? -product.count : product.count,
        })
      }
    })
    const params = {
      items,
    }
    return productBatchAdjustEstimate(params)
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
