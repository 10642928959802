import i18n from "../../locales/index"
import { Message } from "element-ui"
import { billCreate, billPay, billQuery } from "@/api/bill"
import { openCashBox } from "@/utils/appInterface/jsbridge"
import router from "@/router"

const state = {
  cycleIndex: 0,
  timer: null,
  isShowCancelPayBtn: false, // 是否显示取消线上收款按钮
}

const mutations = {
  SET_IS_SHOW_CANCEL_PAY_BTN: (state, show) => {
    state.isShowCancelPayBtn = show
  },
}

const getters = {}

const actions = {
  billCreate({ state, commit, dispatch, rootState }, orderInfo) {
    console.log("调用了actions")
    rootState.gLoading = true
    return new Promise((resolve, reject) => {
      billCreate({
        amount: orderInfo.amount,
        give: orderInfo.give,
        second_card: orderInfo.second_card,
        customer_id: orderInfo.customer_id,
        receipt_width: localStorage.getItem("receipt_width"),
      })
        .then(({ data }) => {
          rootState.gLoading = false
          console.log("data", data)
          if (data.id) {
            dispatch("billPay", {
              id: data.id,
              payment_method: orderInfo.payment_method,
              auth_no: orderInfo.auth_no,
            })
          } else {
            Message({
              duration: 1000,
              message: i18n.t("store_modules_bill_839"),
              type: "error",
            })
          }
          resolve(data)
        })
        .catch((error) => {
          rootState.gLoading = false
          reject(error)
        })
    })
  },
  billPay({ state, commit, dispatch, rootState }, orderInfo) {
    rootState.gLoading = true
    return new Promise((resolve, reject) => {
      billPay(orderInfo)
        .then(({ data }) => {
          rootState.gLoading = false
          if (orderInfo.payment_method === "scan_code_pay") {
            commit("SET_IS_SHOW_CANCEL_PAY_BTN", true)
            rootState.gLoading = true
            dispatch("billStatusPoll", {
              id: orderInfo.id,
            })
          } else {
            Message({
              duration: 1000,
              message: i18n.t("store_modules_bill_840"),
              type: "success",
            })
            router.go(-1)
            if (orderInfo.payment_method === "cash_pay") {
              openCashBox()
            }
            dispatch(
              "printer_config/print",
              {
                fresh_bill_id: orderInfo.id,
                category: "charge",
              },
              { root: true }
            )
          }
          resolve()
        })
        .catch((error) => {
          rootState.gLoading = false

          reject(error)
        })
    })
  },
  billStatusPoll({ state, commit, dispatch }, orderInfo) {
    state.cycleIndex = 0
    dispatch("billQueryStatus", orderInfo).then((data) => {
      if (!data) {
        state.timer = setInterval(() => {
          if (window.location.href.indexOf("memberRecharge") > -1) {
            dispatch("billQueryStatus", orderInfo)
          } else {
            clearInterval(state.timer)
          }
        }, 5000)
      }
    })
  },
  billQueryStatus({ state, commit, dispatch, rootState }, orderInfo) {
    return new Promise((resolve, reject) => {
      billQuery(orderInfo)
        .then(({ data, message }) => {
          console.log("billQueryStatus", data)
          if (data && data.status === "paid") {
            rootState.gLoading = false
            commit("SET_IS_SHOW_CANCEL_PAY_BTN", false)
            dispatch(
              "printer_config/print",
              {
                fresh_bill_id: orderInfo.id,
                category: "charge",
              },
              { root: true }
            )
            Message({
              duration: 1000,
              message: i18n.t("store_modules_bill_840"),
              type: "success",
            })
            router.go(-1)
            if (orderInfo.payment_method === "cash_pay") {
              openCashBox()
            }
            clearInterval(state.timer)
            state.cycleIndex = 0
            resolve(data)
            return
          }
          state.cycleIndex++
          if (
            state.cycleIndex >= 15 ||
            message === i18n.t("store_modules_bill_841") ||
            message === i18n.t("store_modules_bill_842")
          ) {
            commit("SET_IS_SHOW_CANCEL_PAY_BTN", false)
            rootState.gLoading = false
            clearInterval(state.timer)
            state.cycleIndex = 0
          }
          resolve(data)
        })
        .catch((error) => {
          rootState.gLoading = false
          clearInterval(state.timer)
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
