import i18n from "../../locales/index"
import * as ANDROID from "./android_jsbridge.js"
import * as WINDOW from "./window_jsbridge.js"
import { env } from "@/utils"

const envName = env()

/**
 *钱箱
 */
export function openCashBox() {
  console.log("弹钱箱")
  // 打开钱箱
  if (envName === "android") {
    ANDROID.openCashBox()
    return true
  }
  if (envName === "windows") {
    WINDOW.OpenCashBox(localStorage.getItem("win_selected_printer"))
    return true
  }
}

/**
 * 打印
 * @content 打印内容
 * @printname 打印名称
 */
export function print(content, count = 1, printer_no) {
  console.log("print方法调用了", count)
  if (typeof count !== "number") {
    count = 1
  }
  while (count > 0) {
    console.log("测试打印次数")
    if (envName === "android") {
      ANDROID.print(content)
    } else if (envName === "windows") {
      console.log(
        content,
        localStorage.getItem(`${printer_no}_printer`) ||
          localStorage.getItem("win_selected_printer")
      )
      WINDOW.windowsPrint(
        content,
        localStorage.getItem(`${printer_no}_printer`) ||
          localStorage.getItem("win_selected_printer")
      )
    }
    count--
  }
}

/**
 * 标签打印
 * @content 打印内容
 * @printname 打印名称
 */
export function printLabel(content, count = 1, printer_no) {
  console.log("printLabel方法调用了", count)
  if (typeof count !== "number") {
    count = 1
  }
  while (count > 0) {
    console.log("测试次数")
    if (envName === "android") {
      ANDROID.printLabel(content)
    } else if (envName === "windows") {
      WINDOW.windowsPrint(
        content,
        localStorage.getItem(`${printer_no}_printer`) ||
          localStorage.getItem("win_lable_printer")
      )
    }
    count--
  }
}
/**
 * 蓝牙打印
 * @content 打印内容
 * @printname 打印名称
 */
export function printBluetooth(content, count = 1) {
  console.log("printBluetooth方法调用了", count)
  if (typeof count !== "number") {
    count = 1
  }
  while (count > 0) {
    console.log("测试次数")
    if (envName === "android") {
      ANDROID.printBluetooth(content)
    }
    // else if (envName === 'windows') {
    //   WINDOW.windowsPrint(content, localStorage.getItem('win_selected_lableprinter'));
    // }
    count--
  }
}

/**
 * 使用局域网内的打印机打印，如果有多台则全部打印
 * @param content
 */
export function printWithWLAN(content, count) {
  console.log("printWithWLAN方法调用了", count)
  if (typeof count !== "number") {
    count = 1
  }
  while (count > 0) {
    console.log("测试次数")
    if (envName === "android") {
      ANDROID.printWithWLAN(content)
    } else if (envName === "windows") {
      // TODO: 还没有
    }
    count--
  }
}

/**
 * 指定打印机打印
 *
 * @param printerId 打印机设置中服务端返回的打印机的id（仅限网络打印机 wlan）
 * @param content
 */
export function printSpecifyWithWLAN(printerId, content, count) {
  console.log("printSpecifyWithWLAN方法调用了", count)
  if (typeof count !== "number") {
    count = 1
  }
  while (count > 0) {
    console.log("测试次数")
    if (envName === "android") {
      ANDROID.printSpecifyWithWLAN(printerId, content)
    } else if (envName === "windows") {
      // TODO: 还没有
    }
    count--
  }
}
/**
 * 网络打印机设置
 * @param printerId 打印机已设置的情况下传打印机Id，未设置传0
 */
export function wlanPrinterSetting(printerId) {
  if (envName === "android") {
    ANDROID.wlanPrinterSetting(printerId)
  }
  if (envName === "windows") {
    // TODO: 还没有
  }
}
/**
 * 切换webview
 */
export function openUrlOnBrowser() {
  if (envName === "android") {
    ANDROID.openUrlOnBrowser()
  } else if (envName === "windows") {
    WINDOW.openUrlOnBrowser()
  }
}

/**
 * show "1" 显示副屏 "0" 隐藏副屏
 * type "1" 轮播图 "0" 视频
 * */
export function showSecondDisplay(show, type, alwayShowMedia) {
  if (envName === "android") {
    ANDROID.showSecondDisplay(show, type, alwayShowMedia)
  } else if (envName === "windows") {
    if (show === "1") {
      WINDOW.secondScreenShow()
    } else if (show === "0") {
      WINDOW.secondScreenClose()
    }
  }
}

/**
 *最小化应用
 */
export function backToHome() {
  if (envName === "android") {
    ANDROID.backToHome()
  } else if (envName === "windows") {
    WINDOW.minimizeWindow()
  }
}

/**
 * 设置IC读卡
 */
export function setReadIc(fn) {
  window.readIc = (code) => {
    fn(code)
  }
}
/**
 * 更新app版本
 */
export function downloadAndInstallApp(apkUrl, fileName) {
  if (envName === "android") {
    ANDROID.downloadAndInstallApp(apkUrl, fileName)
  } else if (envName === "windows") {
    // WINDOW.downloadAndInstallApp();
  }
}
