import i18n from "../locales/index"
/**
 * 设置 Storage
 * @param {*} key
 * @param {*} data
 */
export function setStorage(key, data) {
  if (data instanceof Object) {
    data = JOSN.stringfiy(data)
  }
  localStorage.setItem(key, data)
}

/**
 * 获取Storage
 * @param {*} key
 * @returns
 */
export function getStorage(key) {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (err) {
    return localStorage.getItem(key)
  }
}
