import i18n from "../locales/index"
import pluralize from "pluralize"
import _ from "lodash"
import axios from "@/libs/axios.js"
import { Message } from "element-ui"

window.pluralize = pluralize

function states(resource) {
  const resources = pluralize(resource)
  return {
    query: { page: 1, per: 10 },
    loading: false,
    meta: {},
    parent: {},
    [resource]: {},
    [resources]: [],
  }
}

function getters(resource) {
  const resources = pluralize(resource)
  return {
    [resources]: (state) => state[resources],
    [resource]: (state) => state[resource],
    getById: (state) => (id) => {
      return state[resources].find((record) => record.id === id)
    },
    loading: (state) => state.loading,
    meta: (state) => state.meta,
  }
}

function actions(resources) {
  return {
    async index({ commit }, params) {
      const { query, parent } = this.state[_singular(resources)]

      const { parent_name, parent_id } = parent
      const path = parent_name
        ? `${pluralize(parent_name)}/${parent_id}/${resources}`
        : resources
      const final_params = Object.assign(query, params)
      const response = await axios.get(path, { params: final_params })

      commit("setRecords", response.data)

      if (response.meta) commit("setMeta", response.meta)

      commit("loading", false)
    },
    async show({ commit }, id) {
      const response = await axios.get(`${resources}/${id}`)

      commit("setRecord", response.data)
    },
    async update({ commit }, params) {
      const { _mute_ = false, ...others } = params
      const response = await axios.put(`${resources}/${params.id}`, others)

      commit("updateRecordInRecords", response.data)
      if (!_mute_) {
        Message.success(i18n.t("store_modules_desk_845"))
      }
    },
    async create({ commit }, options) {
      const { _mute_ = false, ...params } = options
      const response = await axios.post(resources, params)
      commit("setRecord", response.data)
      commit("addRecordInRecords", response.data)
      if (!_mute_) {
        Message.success(i18n.t("pages_product_product_add_707"))
      }
      return response.data
    },
    async destroy({ commit }, id) {
      await axios.delete(`${resources}/${id}`)

      commit("removeRecordInRecords", id)

      Message.success(i18n.t("pages_product_product_add_704"))
    },
    async clear({ commit }) {
      commit("clearRecord")
    },
  }
}

function mutations(resourceType) {
  const resourceTypes = pluralize(resourceType)
  return {
    loading(state, boolean) {
      state.loading = boolean
    },
    setRecord(state, record) {
      state[resourceType] = record
    },
    setRecords(state, records) {
      state[resourceTypes] = records
    },
    setMeta(state, meta) {
      state.meta = meta
    },
    clearRecord(state) {
      state[resourceType] = {}
    },
    addRecordInRecords(state, record) {
      state[resourceTypes].splice(0, 0, record)
    },
    removeRecordInRecords(state, id) {
      const index = _.findIndex(
        state[resourceTypes],
        (record) => record.id === id
      )
      if (index >= 0) state[resourceTypes].splice(index, 1)
    },
    updateRecordInRecords(state, record) {
      const index = _.findIndex(
        state[resourceTypes],
        (_record) => _record.id === record.id
      )
      if (index >= 0) state[resourceTypes].splice(index, 1, record)
    },
    changeQuery: (state, query) => {
      state.query = { ...state.query, ...query }
    },
    resetQuery: (state) => {
      state.query = { page: 1, per: 10 }
    },
  }
}

export const genBasicStore = (resource) => ({
  namespaced: true,
  state: { ...states(resource) },
  getters: { ...getters(resource) },
  mutations: { ...mutations(resource) },
  actions: {
    ...actions(pluralize(resource)),
  },
})

function _singular(resources) {
  const result = pluralize.singular(resources)
  return result === resources ? result.replace(/s$/, "") : result
}

export default {
  states,
  getters,
  actions,
  mutations,
}
