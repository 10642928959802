import i18n from "../../locales/index"
import { Message, MessageBox } from "element-ui"
import router from "@/router"
import { getCustomers, getCustomerLevels } from "@/api/customer.js"

function goBack() {
  if (/member/g.test(window.location.hash)) {
    router.go(-2)
  } else {
    router.replace("/")
  }
}
const state = {
  memberInfo: {},
  customerLevels: [],
}

const mutations = {
  SET_CUSTOMER_LEVELS: (state, data) => {
    state.customerLevels = data
  },
}

const getters = {}

const actions = {
  selectMember_ac({ commit, dispatch, rootState }, member) {
    if (member.status === "stopped") {
      Message.warning(i18n.t("store_modules_member_847"))
      return
    }
    if (!member.can_use_huiyuanjia) {
      const id = member.id
      MessageBox.confirm(
        i18n.t("store_modules_member_848"),
        i18n.t("components_order_panel_index_157"),
        {
          confirmButtonText: i18n.t("store_modules_member_849"),
          cancelButtonText: i18n.t("components_shopping_cart_index_180"),
          type: "warning",
        }
      )
        .then(() => {
          router.push(`/memberRecharge?id=${id}`)
        })
        .catch(() => {
          commit("SET_MEMBER_INFO", member, { root: true })
          rootState.orderPlus.fresh_customer_id = member.id
          goBack()
        })
      return
    }
    commit("SET_MEMBER_INFO", member, { root: true })
    rootState.orderPlus.fresh_customer_id = member.id
    dispatch("activity/ordersCheckFirst", member.id, { root: true }) // 首单检测
    if (/cashier/g.test(window.location.hash)) return
    goBack()
  },
  searchMember_ac({ dispatch }, param) {
    getCustomers(param).then(({ data }) => {
      if (data && data.length === 1) {
        dispatch("selectMember_ac", data[0])
      }
    })
  },
  // 获取会员的等级
  getCustomerLevels({ commit, getters }) {
    return new Promise((resolve, reject) => {
      getCustomerLevels()
        .then(({ data }) => {
          commit("SET_CUSTOMER_LEVELS", data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
