import i18n from "../../locales/index"
import { Message } from "element-ui"
import { chargeCouponsList, chargeCouponsCreate } from "@/api/chargeCoupon"

const state = {
  chargeCoupons: [],
}

const mutations = {
  SET_TAXONS: (state, taxons) => {
    state.taxons = taxons
  },
  SET_CATEGORY_PAGE: (state, page) => {
    state.categoryPage = page
  },
}

const getters = {}

const actions = {
  chargeCouponsList({ state, commit, dispatch }, query) {
    return new Promise((resolve, reject) => {
      chargeCouponsList(query)
        .then(({ data, meta }) => {
          state.chargeCoupons = data
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  chargeCouponsCreate({ state, commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      chargeCouponsCreate(data)
        .then(({ data }) => {
          Message({
            duration: 1000,
            message: i18n.t("store_modules_chargecoupon_844"),
            type: "success",
          })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
