import i18n from "../../locales/index"
export default [
  {
    // 登录
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/pages/user/login_new.vue"),
  },
  {
    // 重置密码
    path: "/reset",
    name: "reset",
    component: () =>
      import(/* webpackChunkName: "reset" */ "@/pages/user/reset.vue"),
  },
  {
    // 选择店铺
    path: "/shops",
    name: "shops",
    component: () =>
      import(/* webpackChunkName: "shops" */ "@/pages/user/shops.vue"),
  },
]
