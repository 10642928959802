export const findComponentsDownward = (context, componentName) => {
    const arr = [];
    for (let i = 0; i < context.$children.length; i += 1) {
        if (context.$children[i].$options.name === componentName) {
            arr.push(context.$children[i]);
        }
    }
    return arr;
};
export const findComponentsSameLevel = (context) => {
    const parent = context.$parent || context.parent;
    return parent.$children;
};
export const isThisParent = (context, componentName) => {
    const parent = context.$parent || context.parent;
    return parent.$options.name === componentName;
};
