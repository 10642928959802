import i18n from "../../../locales/index"
import * as ANDROID from "./offlineAndroid"
// import * as WINDOW from './offlineWindows';
import { env } from "@/utils"

const envName = env()

/**
 * 是否支持脱网销售模式
 * @returns  boolean
 */
export function isSupportOfflineMode() {
  if (envName === "android") {
    return ANDROID.isSupportOfflineMode()
  }
  if (envName === "windows") {
    return false
  }
}
/**
 * 登录成功后调一下这个方法
 * @param {String} token
 * @param {String} shopNo
 */
export function offlineLogin(token, shopNo) {
  if (envName === "android") {
    ANDROID.login(token, shopNo)
  }
  if (envName === "windows") {
  }
}
/**
 * 同步数据
 */
export function startSyncData() {
  if (envName === "android") {
    ANDROID.startSyncData()
  }
  if (envName === "windows") {
  }
}

/**
 * 退出登录
 */
export function offlineLogout() {
  if (envName === "android") {
    ANDROID.logout()
  }
  if (envName === "windows") {
  }
}
/**
 * @param {*} orderBody
 * @returns  返回订单号
 */
export function offlineSaveOrder(orderBody) {
  console.log("调用了saveOrder")
  if (localStorage.getItem("isOffline") === "1") {
    if (envName === "android") {
      return ANDROID.saveOrder(orderBody)
    }
    if (envName === "windows") {
      return false
    }
  } else {
    return false
  }
}
/**
 * 获取脱网订单列表
 */
export function offlineGetOrderList() {
  console.log("dsaf")
  if (envName === "android") {
    return ANDROID.getOrderList()
  }
  if (envName === "windows") {
    return []
  }
}
/**
 * 同步成功后删除订单
 */
export function deleteOfflineOrder(orderId) {
  if (envName === "android") {
    console.log("同步成功后删除订单", orderId)
    ANDROID.deleteOfflineOrder(orderId)
  }
  if (envName === "windows") {
  }
}
/**
 * 切换脱网模式
 * @param {boolean} offline
 */
export function changeOffline(offline) {
  if (envName === "android") {
    return ANDROID.changeOffline(offline)
  }
  if (envName === "windows") {
  }
}
