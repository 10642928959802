import CheckboxGroup from "./checkbox/checkbox-group.vue";
import Checkbox from "./checkbox/checkbox.vue";
import RadioGroup from "./radio/radio-group.vue";
import Radio from "./radio/radio.vue";
import NumberKeyboard from "./number-keyboard/index.vue";
const babyPlugin = function (Vue) {
    Vue.component("BabyRadioGroup", RadioGroup);
    Vue.component("BabyRadio", Radio);
    Vue.component("BabyCheckboxGroup", CheckboxGroup);
    Vue.component("BabyCheckbox", Checkbox);
    Vue.component("BabyNumberKeyboard", NumberKeyboard);
};
export default babyPlugin;
