<template>
  <div class="baby-radio-group">
    <slot />
  </div>
</template>

<script>
import i18n from "../../../locales/index"

import { findComponentsDownward } from "../shared"
export default {
  name: "BabyRadioGroup",
  props: {
    modelValue: {
      type: [String, Number, Boolean, Date],
      default: "",
    },
    asdfs: {
      type: [String, Number, Boolean, Date],
      default: "",
    },
  },
  watch: {
    modelValue: {
      handler: function (value, newValue) {
        this.$nextTick(() => {
          const children = findComponentsDownward(this, "BabyRadio")
          for (let item of children) {
            item.init()
          }
        })
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    updateModel() {
      const children = findComponentsDownward(this, "BabyRadio")
      let result = ""
      for (let item of children) {
        if (item.isChecked) {
          result = item.name
        }
      }
      console.log("update:modelValue", result)
      this.$emit("update:modelValue", result)
      this.$emit("change", result)
    },
  },
}
</script>
<style scope lang="scss">
.baby-radio-group {
  display: flex;

  .baby-radio {
    margin-right: 40px;
  }
}
</style>
