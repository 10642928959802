import i18n from "../../locales/index"
export default [
  {
    // 商品添加
    path: "/productAdd",
    name: "productAdd",
    component: () =>
      import(
        /* webpackChunkName: "productAdd" */ "@/pages/product/product-add.vue"
      ),
  },
  {
    // 商品搜索
    path: "/productSearch",
    name: "productSearch",
    component: () =>
      import(
        /* webpackChunkName: "productSearch" */ "@/pages/product/product-search.vue"
      ),
  },
]
