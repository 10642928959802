import i18n from "../locales/index"
import { env } from "@/utils"

export const printerTestData = new Map([
  [
    "80",
    "G0AcJhtNAA0KG2EBHCEMGyEwtPO80rmy08O1xA0KHCEAGyEAo6i94dXKwaqjqQ0KG2EAHCEAGyEAtqm1pbrFo7oyMDIwMDcxOTIxNDAyOAocIQAbIQDPwrWlyrG85KO6MjAyMC0wNy0xOSAxNDowMToxNw0KHCEAGyEALS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tDQocIQAbIQDGt8P7ICAgICAgICAgICAgICAgICAgICAgICAgIMr9wb8gICAgICAgICAgILzbuPENChwhABshAC0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLQ0KHCEAGyEAt+G34dDc1NPBuM7luciw9DMwMGejqM/MtbC7xs62o6kKHCEAGyEAICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgMbT8ICAgICAgICAgIDEwLjAwChwhABshAM30zrDD9wocIQAbIQAgICAgICAgICAgICAgICAgICAgICAgICAgICAgMbmrve8gICAgICAgICAgIDAuMDENChwhABshAC0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLQ0KHCEAGyEAus+8xiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIDEwLjAxDQoKHCEAGyEALS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tChwhABshAMHjzbfTxbvdICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAtMC4wMQ0KChwhABshAC0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLQocIQwbITDKtcrVICAgICAgICAgICAgICAgMTAuMDAKHCEAGyEALS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tChwhABshAM/WvfDK1b/uICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAxMC4wMA0KDQoNCg0KHCEAGyEALS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tDQocIQAbIQAKHCEAGyEAChwhABshAAocIQAbIQANCh1WAQ==",
  ],
  [
    "58",
    "G0AcJhtNAA0KG2EBHCEMGyEweWlmZW5nDQocIQAbIQCjqL3h1crBqqOpDQobYQAcIQAbIQC2qbWlusWjujIwMjAwNTMwMjE1ODYyChwhABshAM/CtaXKsbzko7oyMDIwLTA1LTMwIDE0OjA2OjUwDQocIQAbIQAtLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLQ0KHCEAGyEAxrfD+yAgICAgICAgICAgICAgyv3BvyAgICAgILzbuPENChwhABshAC0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tDQocIQAbIQDP4722ChwhABshACAgICAgICAgICAgICAgICAgMcenv8sgICAgIDEyLjU1DQocIQAbIQAtLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLQ0KHCEAGyEAus+8xiAgICAgICAgICAgICAgICAgICAgICAgMTIuNTUNCg0KChwhABshAC0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tChwhDBshMMq1ytUgICAgICAgMTIuNTUKHCEAGyEALS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0KHCEAGyEAz9a98MrVv+4gICAgICAgICAgICAgICAgICAgMTIuNTUNCg0KDQoNChwhABshAC0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tDQocIQAbIQAKHCEAGyEAChwhABshAAocIQAbIQANCh1WAA==",
  ],
])
export const printer_types = [
  {
    text: i18n.t("utils_value_list_922", [
      env() === "windows" ? "驱动" : "USB",
    ]),
    code: "usb",
  },
  { text: i18n.t("utils_value_list_923"), code: "gprs" },
  { text: i18n.t("utils_value_list_924"), code: "network" },
  { text: i18n.t("utils_value_list_925"), code: "bluetooth" },
  { text: i18n.t("utils_value_list_926"), code: "fukun_thai" },
]
export const receipt_widths = [
  { text: "58", code: "58" },
  { text: "80", code: "80" },
]
export const kinds = [
  { text: i18n.t("utils_value_list_927"), code: "reception" },
  { text: i18n.t("utils_value_list_928"), code: "kitchen" },
]
export const channels = [
  { text: i18n.t("utils_value_list_929"), code: "fukun" },
  { text: "DR", code: "jiabo" },
  { text: i18n.t("utils_value_list_930"), code: "fukun_cloud" },
]
