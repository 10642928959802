import i18n from "../locales/index"
import { multiply, add } from "@/utils/math"

/**
 * 商品总价计算
 * @param {string} products // 商品列表
 * @param {Object} member // 会员信息
 * @returns {Object}
 */
export function totalPriceCalculation(products, member) {
  let totalPrice = 0 // 普通总价
  let memberTotal = 0 // 会员总价
  products.forEach((product) => {
    const count = parseFloat(product.count)
    let hPrice = Math.round(multiply(product.huiyuanjia, count) * 100) / 100
    let lPrice = Math.round(multiply(product.lingshoujia, count) * 100) / 100
    if (product.isPrePkgGood && product.prePkgGoodType === "p") {
      // 预包装并且条码是价格规则时, 直接给一个单品的总价
      hPrice = product.prePkgPrice
      lPrice = product.prePkgPrice
    }
    if (member) {
      const findLevel = product.fresh_product_customer_levels.find(
        (data) => data.fresh_customer_level_id === member.level_id
      )
      const mPrice = findLevel?.huiyuanjia ? findLevel.huiyuanjia : hPrice
      memberTotal = add(memberTotal, mPrice)
    } else {
      memberTotal = add(memberTotal, lPrice)
    }
    totalPrice = add(totalPrice, lPrice)
  })
  return { totalPrice, memberTotal }
}
