import i18n from "../../../locales/index"
import { getAppVersion } from "@/utils/appInterface/android_jsbridge"
import { compareVersion } from "@/utils/index.js"

const version = getAppVersion() || "1.0.8"
const isPermission = compareVersion("1.0.8", version)
console.log("version", version)
console.log("isPermission", isPermission)

/**
 * 是否支持脱网销售模式
 * @returns  boolean
 */
export function isSupportOfflineMode() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      console.log(
        "window.TurbolinksJavaScriptObject.isSupportOfflineMode()",
        window.TurbolinksJavaScriptObject.isSupportOfflineMode()
      )
      return window.TurbolinksJavaScriptObject.isSupportOfflineMode()
    } catch {
      console.log("isSupportOfflineMode报错了")
    }
  }
}
/**
 * 登录成功后调一下这个方法
 * @param {String} token
 * @param {String} shopNo
 */
export function login(token, shopNo) {
  if (isPermission === -1) {
    if (window.TurbolinksJavaScriptObject) {
      try {
        window.TurbolinksJavaScriptObject.login(token, shopNo)
      } catch {
        console.log("login报错了")
      }
    }
  }
}
/**
 * 同步数据
 */
export function startSyncData() {
  if (isPermission === -1) {
    if (window.TurbolinksJavaScriptObject) {
      try {
        window.TurbolinksJavaScriptObject.startSyncData(
          localStorage.getItem("authorization"),
          localStorage.getItem("shop_id")
        )
      } catch {
        console.log("startSyncData报错了")
      }
    }
  } else {
    console.log("startSyncData<======>版本不够")
  }
}
/**
 * 退出登录
 */
export function logout() {
  if (isPermission === -1) {
    if (window.TurbolinksJavaScriptObject) {
      try {
        window.TurbolinksJavaScriptObject.logout()
      } catch {
        console.log("logout报错了")
      }
    }
  }
}

/**
 * @param {*} orderBody
 * @returns  返回订单号
 */
export function saveOrder(orderBody) {
  if (window.TurbolinksJavaScriptObject) {
    try {
      console.log("orderBody", orderBody)
      return window.TurbolinksJavaScriptObject.saveOrder(
        JSON.stringify(orderBody)
      )
    } catch {
      console.log("saveOrder报错了")
    }
  }
}

/**
 * 获取脱网订单列表
 */
export function getOrderList() {
  if (isPermission === -1) {
    if (window.TurbolinksJavaScriptObject) {
      try {
        const list = window.TurbolinksJavaScriptObject.getOrderList()
        console.log("offlineGetOrderList", list)
        if (list) {
          return JSON.parse(list)
        }
        return null
      } catch {
        console.log("getOrderList报错了")
      }
    }
  } else {
    return null
  }
}

/**
 * 同步成功后删除订单
 */
export function deleteOfflineOrder(orderId) {
  if (isPermission === -1) {
    if (window.TurbolinksJavaScriptObject) {
      try {
        window.TurbolinksJavaScriptObject.deleteOfflineOrder(orderId)
      } catch {
        console.log("deleteOfflineOrder报错了")
      }
    }
  }
}

/**
 * 切换脱网模式
 * @param {boolean} offline
 */
export function changeOffline(offline) {
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.changeOffline(offline)
    } catch {
      console.log("changeOffline报错了")
    }
  }
}
