import i18n from "../locales/index"
import Vue from "vue"
import ElementUI from "element-ui"
const elCompontents = [
  "Pagination",
  "Dialog",
  "Dropdown",
  "DropdownMenu",
  "DropdownItem",
  "Menu",
  "Submenu",
  "MenuItem",
  "MenuItemGroup",
  "Input",
  "InputNumber",
  "Radio",
  "RadioGroup",
  "RadioButton",
  "Checkbox",
  "CheckboxButton",
  "CheckboxGroup",
  "Switch",
  "Select",
  "Option",
  "OptionGroup",
  "Button",
  "ButtonGroup",
  "Table",
  "TableColumn",
  "DatePicker",
  "TimeSelect",
  "TimePicker",
  "Popover",
  "Form",
  "FormItem",
  "Tabs",
  "TabPane",
  "Tag",
  "Alert",
  "Icon",
  "Row",
  "Col",
  "Upload",
  "Badge",
  "Card",
  "Container",
  "Header",
  "Aside",
  "Main",
  "Footer",
  "Image",
  "PageHeader",
  "Avatar",
  "Drawer",
  "InfiniteScroll",
  "Autocomplete",
]
const elementPlugin = function (Vue) {
  elCompontents.forEach((name) => {
    Vue.use(ElementUI[name])
  })
  Vue.use(ElementUI.Loading.directive)
  Vue.prototype.$loading = ElementUI.Loading.service
  Vue.prototype.$msgbox = ElementUI.MessageBox
  Vue.prototype.$alert = ElementUI.MessageBox.alert
  Vue.prototype.$confirm = ElementUI.MessageBox.confirm
  Vue.prototype.$prompt = ElementUI.MessageBox.prompt
  Vue.prototype.$message = ElementUI.Message
}
export default elementPlugin
