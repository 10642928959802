import i18n from "../../locales/index"
import { Message } from "element-ui"
import {
  categoryList,
  categoryUpdate,
  categoryDelete,
  categoryCreate,
} from "@/api/category"

const _ = require("lodash")

const state = {
  taxons: [],
  categoryPage: 1,
  categorySize: 11,
  categoryTotal: 0,
}

const mutations = {
  SET_TAXONS: (state, taxons) => {
    state.taxons = taxons
  },
  SET_CATEGORY_PAGE: (state, page) => {
    state.categoryPage = page
  },
  SET_CATEGORY_SIZE: (state, size) => {
    state.categorySize = size
  },
  SET_CATEGORY_TOTAL: (state, total) => {
    state.categoryTotal = total
  },
}

const getters = {}

const actions = {
  categoryList({ state, commit, dispatch }, query) {
    return new Promise((resolve, reject) => {
      categoryList(query)
        .then(({ data, meta }) => {
          state.taxons = data
          state.categoryTotal = meta.total
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  categoryCreate({ state, commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      categoryCreate(data)
        .then(({ data }) => {
          Message({
            duration: 1000,
            message: i18n.t("pages_product_product_add_703"),
            type: "success",
          })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  categoryUpdate({ state, commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      categoryUpdate(data)
        .then(({ data }) => {
          Message({
            duration: 1000,
            message: i18n.t("store_modules_category_843"),
            type: "success",
          })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  categoryDelete({ state, commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      categoryDelete(id)
        .then(({ data, status }) => {
          if (status === 204) {
            Message({
              type: "success",
              message: i18n.t("components_shopping_cart_index_194"),
              duration: 1000,
            })
          }
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
