import i18n from "../../locales/index"
export default [
  {
    // 交接班
    path: "/successionIndex",
    name: "successionIndex",
    component: () =>
      import(
        /* webpackChunkName: "successionIndex" */ "@/pages/succession/index.vue"
      ),
  },
  {
    // 交接班历史列表
    path: "/successionHistory",
    name: "successionHistory",
    component: () =>
      import(
        /* webpackChunkName: "successionHistory" */ "@/pages/succession/history.vue"
      ),
  },
  {
    // 交接班记录详情
    path: "/successionHistoryDetail",
    name: "successionHistoryDetail",
    component: () =>
      import(
        /* webpackChunkName: "successionHistoryDetail" */ "@/pages/succession/history-detail.vue"
      ),
  },
]
