import i18n from "../locales/index"
import { keepDecimal } from "@/utils"
import { add, multiply } from "@/utils/math"
/**
 * 计算全场打折价格
 * @param {Array} list 商品列表
 * @param {Number} discount 折扣
 * @param {Boolean} isMember 是否是会员
 * @returns {Number} 全场打折后价格
 */
export function computeFullSaleTotal(list, discount, isMember) {
  // 需要折扣的总价
  let needDiscountTotal = 0
  // 不需要折扣的总价
  let unwantedDiscountTotal = 0
  list.forEach((product) => {
    // 商品数量
    const count = parseFloat(product.count)
    // 会员价
    const hPrice = Math.round(multiply(product.huiyuanjia, count) * 100) / 100
    // 普通价
    const lPrice = Math.round(multiply(product.lingshoujia, count) * 100) / 100
    // 所用的价格
    const usedPrice = isMember ? hPrice : lPrice
    if (product.discount) {
      needDiscountTotal = add(needDiscountTotal, usedPrice)
    } else {
      unwantedDiscountTotal = add(unwantedDiscountTotal, usedPrice)
    }
  })
  console.log("list", list)
  console.log("needDiscountTotal", needDiscountTotal)
  console.log("unwantedDiscountTotal", unwantedDiscountTotal)

  const needDiscountedTotal = keepDecimal(
    multiply(needDiscountTotal, discount) / 100,
    2
  )
  return keepDecimal(add(needDiscountedTotal, unwantedDiscountTotal), 2)
}
export function test() {}
