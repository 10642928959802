import i18n from "../locales/index"
import { playVoice } from "@/utils/appInterface/android_jsbridge"

/**
 * 支付成功播报
 */
export function playPaySuccess(type, amount) {
  if (localStorage.getItem("isBroadcast") !== "1") return
  const typeMap = {
    cash_pay: i18n.t("components_keyboard_partial_refund_118"),
    svc_pay: i18n.t("components_keyboard_partial_refund_117"),
    alipay_offline: i18n.t("libs_value_list_215"),
    wx_pay_offline: i18n.t("libs_value_list_216"),
    bank_card: i18n.t("pages_member_member_recharge_499"),
    meituan_pay_offline: i18n.t("utils_broadcast_901"),
    scan_code_pay: i18n.t("utils_broadcast_902"),
    alipay_facepay: i18n.t("libs_value_list_220"),
  }
  const content =
    typeMap[type] +
    i18n.t("app_4") +
    amount +
    i18n.t("components_keyboard_coupon_97")
  console.log("playPaySuccess", content)
  playVoice(content)
}
