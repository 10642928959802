import i18n from "../locales/index"
import dayjs from "dayjs"

export default function time(Vue) {
  Vue.directive("time_format", {
    bind: function (el, binding) {
      el.innerHTML = dayjs(binding.value).format("YYYY-MM-DD")
    },
  })

  Vue.directive("time_format2", {
    bind: function (el, binding) {
      el.innerHTML = dayjs(binding.value).format("YYYY-MM")
    },
  })

  Vue.directive("time_format3", {
    bind: function (el, binding) {
      el.innerHTML = dayjs(binding.value).format("YYYY-MM-DD HH:mm:ss")
    },
  })
}
