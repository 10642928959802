import i18n from "../locales/index"
import request from "@/utils/request"
const baseurl = process.env.VUE_APP_BASE_URL

export function chargeCouponsList(query) {
  return request({
    url: baseurl + "/charge_coupons",
    method: "get",
    params: query,
  })
}
export function chargeCouponsCreate(data) {
  return request({
    url: baseurl + "/charge_coupons",
    method: "post",
    data,
  })
}
