import i18n from "../../locales/index"
export default [
  {
    // 设置
    path: "/options",
    name: "options",
    redirect: "/options/common",
    component: () =>
      import(/* webpackChunkName: "options" */ "@/pages/options/index.vue"),
    children: [
      {
        // 通用设置
        path: "/options/common",
        name: "optionsCommon",
        component: () =>
          import(
            /* webpackChunkName: "options" */ "@/pages/options/common.vue"
          ),
      },
      {
        // 扫码枪设置
        path: "/options/scan",
        name: "optionsScan",
        component: () =>
          import(
            /* webpackChunkName: "optionsscan" */ "@/pages/options/scan.vue"
          ),
      },
      {
        // 客显设置
        path: "/options/kexian",
        name: "optionsKexian",
        component: () =>
          import(
            /* webpackChunkName: "optionskexian" */ "@/pages/options/kexian.vue"
          ),
      },
      {
        // 电子称设置
        path: "/options/scale",
        name: "optionsScale",
        component: () =>
          import(
            /* webpackChunkName: "optionsscale" */ "@/pages/options/scale"
          ),
      },
      {
        // 传称设置
        path: "/options/transmissionScale",
        name: "optionsTransmissionScale",
        component: () =>
          import(
            /* webpackChunkName: "transmissionScale" */ "@/pages/options/scale/transmissionScale.vue"
          ),
      },
      {
        //副屏设置
        path: "/options/secondScreen",
        name: "optionsSecondScreen",
        component: () =>
          import(
            /* webpackChunkName: "optionssecondScreen" */ "@/pages/options/secondScreen.vue"
          ),
      },
      {
        // 销售设置
        path: "/options/sell",
        name: "optionsSell",
        component: () =>
          import(
            /* webpackChunkName: "optionssell" */ "@/pages/options/sell.vue"
          ),
      },
      {
        // 预包装设置
        path: "/options/prepackaging",
        name: "optionsPrepackaging",
        component: () =>
          import(
            /* webpackChunkName: "prepackaging" */ "@/pages/options/prepackaging.vue"
          ),
      },
      {
        // 收银设置
        path: "/options/settlement",
        name: "optionsSettlement",
        component: () =>
          import(
            /* webpackChunkName: "optionssettlement" */ "@/pages/options/settlement.vue"
          ),
      },
      {
        // 打印机设置
        path: "/options/printer",
        name: "optionsPrinter",
        component: () =>
          import(
            /* webpackChunkName: "optionsprinter" */ "@/pages/options/printer/index.vue"
          ),
      },
      {
        // 关于本机
        path: "/options/about",
        name: "optionsAbout",
        component: () =>
          import(
            /* webpackChunkName: "optionsabout" */ "@/pages/options/about.vue"
          ),
      },
      {
        // 打印机管理设置
        path: "/options/printerManagement",
        name: "optionsPrinterManagement",
        component: () =>
          import(
            /* webpackChunkName: "printerManagement" */ "@/pages/options/printer/printerManagement.vue"
          ),
      },
      {
        // 离线模式设置
        path: "/options/offline",
        name: "optionsOffline",
        component: () =>
          import(
            /* webpackChunkName: "offline" */ "@/pages/options/offline.vue"
          ),
      },
      {
        // AI设置
        path: "/options/AI",
        name: "optionsAI",
        component: () =>
          import(/* webpackChunkName: "optionsAI" */ "@/pages/options/AI.vue"),
      },
    ],
  },
]
