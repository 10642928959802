import i18n from "../../../locales/index"
import GBK from "@/libs/gbk.min.js"
import dayjs from "dayjs"

// 字符转10进制
function strToDecimal(str) {
  return parseInt(str.charCodeAt().toString(2), 10)
}
// bufferArr转base64
function arrayBufferToBase64(buffer) {
  var binary = ""
  var bytes = new Uint8Array(buffer)
  var len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}
// 转10进制
function toDecimal(data = []) {
  return data.map((data) => {
    let str = data.toString()
    if (typeof data === "string") {
      if (str === " ") {
        str = "32"
      }
      if (str === "\n") {
        str = "10"
      }
      if (str === "-") {
        str = strToDecimal(str)
      }
    }
    return parseInt(str)
  })
}
const initprint = toDecimal([0x1b, 0x40]) /* 初始化打印机*/
const chinesebyte = toDecimal([0x1c, 0x21, 0x0c]) /* 打印汉字字符*/
const fontSize12 = toDecimal([0x1d, 0x21, 0x00]) /* 设置字符大小 12x24*/
const fontSize24 = toDecimal([0x1d, 0x21, 0x01]) // 加粗
const fontSize36 = toDecimal([0x1d, 0x21, 0x11]) /* 横向 纵向放大两倍倍宽 倍高*/
const alignLeft = toDecimal([0x1b, 0x61, 0x00]) // 对齐方式左对齐
const alignCenter = toDecimal([0x1b, 0x61, 0x01]) // 对齐方式中间对齐
const alignRight = toDecimal([0x1b, 0x61, 0x02]) // 对齐方式右对齐
const br = toDecimal(["\n"]) // 换行
const space = toDecimal([" "]) // 空格
const hr = toDecimal([
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  "\n",
]) // 分割线
const end = toDecimal([0x1b, 0x4a, 0x90]) // 打印结束
const text = function (text) {
  // 文字
  return GBK.encode(text) || []
}
const orderItemsRow = function (name, num, price) {
  return [
    ...alignLeft,
    ...text(name),
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...text(num),
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...text(price),
    ...br,
    ...hr,
  ]
}
export function renderOrderItem(data) {
  // :Array
  let result = []
  data.forEach((item) => {
    result = result.concat(
      orderItemsRow(
        item.current_product_name,
        item.quantity,
        item.current_price
      )
    )
  })
  return result
}
const commonRow = function (key, value) {
  return [
    ...alignLeft, // 对齐方式左对齐
    ...text(key),
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...text(value),
    ...br, // 换行
    ...hr, // 分割线加换行
  ]
}
// 渲染优惠活动
export function renderDiscount(order) {
  const data = [
    {
      key: i18n.t("utils_appinterface_offline_localprint_882"),
      value: order.customer_discounted_total,
    },
    {
      key: i18n.t("utils_appinterface_offline_localprint_883"),
      value: order.enough_consume_discount,
    },
    {
      key: i18n.t("utils_appinterface_offline_localprint_884"),
      value: order.limit_time_discount,
    },
    {
      key: i18n.t("utils_appinterface_offline_localprint_885"),
      value: order.combo_discount,
    },
    {
      key: i18n.t("utils_appinterface_offline_localprint_886"),
      value: order.first_order_discount,
    },
    {
      key: i18n.t("utils_appinterface_offline_localprint_887"),
      value: order.offline_coupon,
    },
  ]
  let result = []
  data.forEach((item) => {
    if (item.value) {
      result = result.concat(commonRow(item.key, item.value))
    }
  })
  return result
}
// 渲染各类收款金额
export function renderCollection(order) {
  const data = [
    {
      key: getPayMethod(order.payment_method),
      value: order.finish_total,
    },
    {
      key: i18n.t("utils_appinterface_offline_localprint_888"),
      value: order.small_discounted_amount,
    },
    {
      key: i18n.t("utils_appinterface_offline_localprint_889"),
      value: order.custom_percentage_discount,
    },
  ]
  if (order.payment_method === "cash_pay") {
    const tmp = data.shift()
    data.unshift({
      key: i18n.t("utils_appinterface_offline_localprint_890"),
      value: order.change_amount,
    })
    data.unshift(tmp)
  }
  return data.reduce((acc, item) => {
    if (item.value) {
      return acc.concat(commonRow(item.key, item.value))
    } else {
      return acc
    }
  }, [])
}
function getOrderId(data) {
  console.log("getOrderId", data.local_order_id, data.local_order_id, data.code)
  return data.local_order_id || data.fresh_order_id || ""
}
function getPayMethod(type) {
  const payMethodMap = {
    cash_pay: i18n.t("libs_value_list_213"),
    svc_pay: i18n.t("libs_value_list_214"),
    lcsw_ali_pay: i18n.t("utils_appinterface_offline_localprint_891"),
    lcsw_wx_pay: i18n.t("utils_appinterface_offline_localprint_892"),
    wx_pay_offline: i18n.t("utils_appinterface_offline_localprint_893"),
    alipay_offline: i18n.t("utils_appinterface_offline_localprint_894"),
    meituan_pay_offline: i18n.t("utils_appinterface_offline_localprint_895"),
    bank_card: i18n.t("pages_member_member_recharge_499"),
    scan_code_pay: i18n.t("libs_value_list_219"),
    alipay_facepay: i18n.t("libs_value_list_220"),
  }
  return payMethodMap[type]
}
export function getBillContent(data) {
  const shop = JSON.parse(localStorage.getItem("shop") || {})
  const createdTime = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss")
  const bufferArr = [
    ...initprint, // 初始化打印机
    ...chinesebyte, // 开始打印汉字字符
    ...fontSize36, // 开始打印汉字字符
    ...alignCenter, // 横向 纵向放大两倍倍宽 倍高
    ...text(shop.name), // 这是标题
    ...br, // 换行
    ...fontSize12, // 设置字符大小 12x24
    ...alignCenter, // 居中对齐
    ...text(i18n.t("utils_appinterface_offline_localprint_896")), // 小票类型
    ...br, // 换行
    ...alignLeft, // 对齐方式左对齐
    // ...esc, // 换两行
    ...text(
      i18n.t("utils_appinterface_offline_localprint_897", [getOrderId(data)])
    ), // 订单号
    ...br, // 换行
    ...text(i18n.t("utils_appinterface_offline_localprint_898", [createdTime])), // 下单时间
    ...br, // 换行
    ...hr, // 分割线加换行
    ...alignLeft,
    ...text(i18n.t("components_counting_console_counting_47")), // 品名,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...text(i18n.t("components_dialog_order_list_84")),
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...text(i18n.t("pages_score_exchange_711")), // 价格,
    ...br,
    ...fontSize12, // 设置字符大小 12x24
    ...hr, // 分割线加换行
    ...fontSize12, // 设置字符大小 12x24
    ...renderOrderItem(data.order_items),
    ...alignLeft, // 对齐方式左对齐
    ...text(i18n.t("utils_appinterface_offline_localprint_899")), // 合计,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...text(data.total), // 合计价格,
    ...br, // 换行
    ...hr, // 分割线加换行
    ...fontSize24, // 加粗
    ...alignLeft,
    ...text(i18n.t("pages_cashier_index_391")), // 合计,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...space,
    ...text(data.finish_total),
    ...br, // 换行
    ...fontSize12, // 设置字符大小 12x24
    ...hr, // 分割线加换行
    ...fontSize12, // 设置字符大小 12x24
    ...renderCollection(data), // 其他各类收款金额
    ...renderDiscount(data), // 优惠活动金额
    ...alignLeft, // 对齐方式左对齐
    ...text(shop.bulletin),
    ...br, // 换行
    ...hr, // 分割线加换行
    ...end,
  ]
  return arrayBufferToBase64(bufferArr)
}
