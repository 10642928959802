import i18n from "../locales/index"
export default function throttle(Vue) {
  return Vue.directive("throttle", {
    // 指令第一次绑定到元素时
    bind: function (el, binding, vnode) {
      let wait = binding.value // 防抖时间
      if (!wait) {
        // 用户若不设置防抖时间，则默认2s
        wait = 500
      }
      let timer
      el.addEventListener(
        "click",
        (event) => {
          console.log("v-throttle")
          if (!timer) {
            // 第一次执行: 不阻止click⌚️
            timer = setTimeout(() => {
              timer = null
            }, wait)
          } else {
            event && event.stopImmediatePropagation()
          }
        },
        true
      )
    },
  })
}
