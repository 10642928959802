import i18n from "../locales/index"
/**
 * 设置Token
 */
export const setToken = (token) => {
  localStorage.setItem("authorization", token)
}
/**
 * 获取Token
 */
export const getToken = () => localStorage.getItem("authorization")
/**
 * 设置ShopId
 */
export const setShopId = (id) => {
  localStorage.setItem("shop_id", id)
}
/**
 * 获得ShopId
 */
export const getShopId = () => localStorage.getItem("shop_id")
