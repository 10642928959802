import i18n from "../locales/index"
import { multiply } from "@/utils/math"

// 是否在限时活动区间
export function islimitedTimeActivityRegion(start_at, end_at) {
  const hourNow = new Date().getHours()
  const hourStart = +start_at
  const hourEnd = +end_at
  return hourNow >= hourStart && hourNow <= hourEnd
}
// 限时活动优惠算法
export function limitedTimeCompute(orders, activities, isMember) {
  let amount = 0
  let text = ""
  console.log("limitedTimeComputeorders========>", orders)
  orders.forEach((pro) => {
    activities.forEach((activity) => {
      if (pro.fresh_category.name === activity.fresh_category_name) {
        activity.rules.forEach((rule) => {
          if (islimitedTimeActivityRegion(rule.start_at, rule.end_at)) {
            if (activity.category === i18n.t("utils_activity_879")) {
              text += i18n.t("utils_activity_880", [
                rule.start_at,
                rule.end_at,
                activity.fresh_category_name,
                rule.cut,
              ])
              amount += multiply(rule.cut, pro.count)
            } else if (activity.category === i18n.t("utils_activity_881")) {
              text += `${rule.start_at}点到${rule.end_at}点,${
                activity.fresh_category_name
              }分类下每件商品打${rule.cut / 10}折 ` // TODO 多个商品一个分类,这里的文字会重复
              if (!isMember) {
                amount +=
                  multiply(
                    multiply(pro.lingshoujia, 100 - rule.cut),
                    pro.count
                  ) / 100
              } else {
                amount +=
                  multiply(
                    multiply(pro.huiyuanjia, 100 - rule.cut),
                    pro.count
                  ) / 100
              }
            }
          }
        })
      }
    })
  })
  return {
    amount,
    text,
  }
}
//  套餐组合优惠算法
export function makeUp(orders, rules) {
  let totol = 0
  rules.forEach((rule) => {
    // 套餐列表遍历
    let isOk = true // 判断是否订单中有满足条件的套餐
    let stagingOrders = orders.slice()
    let combosTotol = 0 // 套餐中的商品总价
    rule.rules.forEach((pro) => {
      // 套餐列表中的规则遍历
      let hasPro = false // 判断套餐上是否规则中所有的商品订单中都有
      stagingOrders = stagingOrders.map((data) => {
        // 暂存订单的遍历和商品减少数量
        if (data.id === pro.fresh_product_id) {
          if (data.count >= pro.quantity) {
            data.count -= pro.quantity
            combosTotol += data.lingshoujia * pro.quantity
          } else {
            isOk = false
          }
          hasPro = true
        }
        return data
      })
      if (!hasPro) {
        isOk = false
      }
    })
    if (isOk) {
      const discountTol = combosTotol - parseFloat(rule.price)
      totol += discountTol
      orders = stagingOrders.slice()
    }
  })
  return totol
}
// 优惠券
/**
 * 时间是否有效
 * @export
 * @param {*} effective 生效时间
 * @param {*} expired 过期时间
 */
export function isTimeAvailable(effective, expired) {
  const cTime = new Date()
  return cTime >= new Date(effective) && cTime <= new Date(expired)
}
/**
 * 状态是否有效
 * @export
 * @param {*} status init used
 */
export function isStatusAvailable(status) {
  return status === "init"
}
/**
 * 是否到达优惠门槛
 * @export
 * @param {*} cill
 * @param {*} total
 */
export function isCill(cill, total) {
  return +cill <= +total
}

/**
 * 找对象的多层嵌套属性
 * @param {Object} data
 * @param {Array} attr
 * @return
 */
function mark(data, attr) {
  let result = data
  attr.forEach((item) => {
    result = result[item]
  })
  return result
}

/**
 * 计算符合条件的优惠金额
 * @param {Number} id 选择的id
 * @param {Array} products 商品列表
 * @param {Number} price 优惠价格
 * @param {Array} attr 判断的属性数组
 * @return {Number}
 */
function isFit(id, products, price, attr) {
  const cp = products.slice()
  let total = 0
  // 找该分类下的所有商品
  for (const data of cp) {
    if (id === mark(data, attr)) {
      total += data.lingshoujia * data.count
    }
  }
  return Math.min(total, price)
}
/**
 * 是否符合商品分类
 * @export
 * @param {*} cid
 * @param {*} products
 */
export function isFitCategory(id, products, price) {
  return isFit(id, products, price, ["fresh_category", "id"])
}

/**
 * 是否符合商品
 * @export
 * @param {*} id
 * @param {*} products
 */
export function isFitProduct(id, products, price) {
  return isFit(id, products, price, ["id"])
}
