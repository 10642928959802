import i18n from "../locales/index"
import { deleteOfflineOrder } from "@/utils/appInterface/offline"
import { localSave, localRead } from "@/utils"

let globleData = {
  authorization: null,
  shopId: null,
  exchangesId: null,
}
export function setGlobleData(data) {
  globleData = data
}
class Ajax {
  constructor(baseInfo = {}) {
    this.method = baseInfo.method
    this.url = baseInfo.url
    this.headers = baseInfo.headers || {}
    this.data = baseInfo.data || {}
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest()
      xhr.open(this.method, this.url, true)
      // 添加http头，发送信息至服务器时内容编码类型
      switch (this.method) {
        case "POST":
          xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
          break
        case "GET":
          break
        default:
          break
      }
      for (const key in this.headers) {
        xhr.setRequestHeader(key, this.headers[key])
      }
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (/^2\d\d$/.test(xhr.status)) {
            resolve(JSON.parse(xhr.responseText))
          } else {
            reject(JSON.parse(xhr.responseText))
          }
        }
      }
      if (JSON.stringify(this.data) === "{}") {
        xhr.send()
      } else {
        const data = JSON.stringify(this.data)
        xhr.send(data)
      }
    })
  }
}
function request(obj) {
  const baseurl = process.env.VUE_APP_API_HOST + process.env.VUE_APP_BASE_URL
  return new Ajax({
    url: baseurl + obj.url,
    method: obj.method,
    data: obj.data,
    headers: {
      Authorization: globleData.authorization,
      ShopId: globleData.shopId,
    },
  })
}
function createOrder(data) {
  return request({
    url: "/orders",
    method: "POST",
    data,
  })
}
function orderPay(id, data) {
  return request({
    url: `/orders/${id}/pay`,
    method: "POST",
    data,
  })
}
// 储存离线中只创建未支付订单
function saveOfflineUnpaidOrder(obj) {
  const stringList = localRead("offlineUnpaidOrderList")
  const list = stringList ? JSON.parse(stringList) : []
  list.push(obj)
  localSave("offlineUnpaidOrderList", JSON.stringify(list))
}
// 删除离线中只创建未支付订单
function deleteOfflineUnpaidOrder(offline_order_code) {
  const stringList = localRead("offlineUnpaidOrderList")
  const list = stringList ? JSON.parse(stringList) : []
  const newList = list.filter(
    (item) => item.offline_order_code != offline_order_code
  )
  localSave("offlineUnpaidOrderList", JSON.stringify(newList))
}
// 获得离线中只创建未支付订单项
function getOfflineUnpaidOrderItem(offline_order_code) {
  const stringList = localRead("offlineUnpaidOrderList")
  const list = stringList ? JSON.parse(stringList) : []
  return list.find((item) => item.offline_order_code == offline_order_code)
}
export class SyncOrder {
  constructor(list = [], id) {
    this.id = id
    this.index = 0
    this.list = list
    this.len = list.length
    this.oneTimeOrder(this.index)
  }
  // 处理订单创建
  handleOrderCreate() {
    if (localRead("syncOrderId") !== this.id) return
    createOrder({
      ...this.list[this.index],
      offline_order_code: this.list[this.index]?.local_order_id,
    })
      .then(({ data }) => {
        if (data.id) {
          // 有订单id
          const params = {
            id: data.id,
            payment_method: this.list[this.index].payment_method || "cash_pay",
            fresh_exchange_id: globleData.exchangesId,
            offline_order_code: this.list[this.index]?.local_order_id,
          }
          saveOfflineUnpaidOrder(params)
          this.handleOrderPay(params)
        }
      })
      .catch(() => {
        this.index++
        if (this.index + 1 > this.len) {
          try {
            self.postMessage({ cmd: "syncDone" })
          } catch (err) {
            console.log("向主线程发送消息错误", err)
          }
          return
        }
        this.handleRecur(this.index)
      })
  }
  // 处理订单支付
  handleOrderPay(params) {
    if (localRead("syncOrderId") !== this.id) return
    orderPay(params.id, {
      payment_method: params.payment_method,
      auth_no: "",
      fresh_exchange_id: params.fresh_exchange_id,
    })
      .then(() => {
        console.log("不用worker删除", params.offline_order_code)
        deleteOfflineOrder(params.offline_order_code)
        deleteOfflineUnpaidOrder(params.offline_order_code)
      })
      .finally(() => {
        this.index++
        if (this.index + 1 > this.len) {
          console.log("不用workersyncDone")
          localStorage.setItem("randomOrderSyncTime", "")
          console.log("同步完成了")
          return
        }
        console.log("第n次同步", this.index)
        this.handleRecur(this.index)
      })
  }
  // 处理递归时间
  handleRecur(index) {
    if (localRead("syncOrderId") !== this.id) return
    // if (syncFlag)
    // 递归中操作逻辑
    console.log("index", index)
    if (index % 10 === 1) {
      // 余数为1的时候, 延迟10秒
      setTimeout(() => {
        this.oneTimeOrder(index)
      }, 5000)
    } else {
      // 正常一次延迟5秒
      setTimeout(() => {
        this.oneTimeOrder(index)
      }, 5000)
    }
  }
  // 一次完成的创建订单和支付订单
  oneTimeOrder(i) {
    if (localRead("syncOrderId") !== this.id) return
    const unpaidOrderItem = getOfflineUnpaidOrderItem(
      this.list[i]?.local_order_id
    )
    if (!unpaidOrderItem) {
      this.handleOrderCreate()
    } else {
      this.handleOrderPay(unpaidOrderItem)
    }
  }
}
