import i18n from "../../locales/index"
import restful from "../vuex"
import axios from "@/libs/axios.js"
import { Message } from "element-ui"

const state = {
  ...restful.states("order"),
  infiniteOrders: [],
  repairInfo: {},
  partRefundInfo: {},
}

const getters = {
  ...restful.getters("order"),
  partialRefundProducts: (state) => {
    state.order.fresh_refund_orders.forEach((item) => {
      const sourceProduct = item.fresh_refund_order_items[0]
      const findProduct = state.order.fresh_order_items.find(
        (p) => p.fresh_product_id === sourceProduct.fresh_product_id
      )
      console.log("findProduct", findProduct)
      if (findProduct.refund_quantity) {
        findProduct.refund_quantity =
          findProduct.refund_quantity + sourceProduct.quantity
      } else {
        findProduct.refund_quantity = sourceProduct.quantity
      }
    })
    console.log("state.order.fresh_order_items", state.order.fresh_order_items)
    return state.order.fresh_order_items
  },
  aggregate_test: (state) =>
    i18n.t("store_modules_order_851", [
      state.meta.first,
      state.meta.second,
      state.meta.third,
    ]),
}

const mutations = {
  ...restful.mutations("order"),
  saveInfiniteOrders(state, value) {
    state.infiniteOrders = value
  },
  partRefundInfo(state, value) {
    state.partRefundInfo = value
  },
}

const actions = {
  ...restful.actions("orders"),
  async deliver_order({ commit }, { id, express_no }) {
    // Spin.show();
    const res = await axios.post(`orders/${id}/deliver`, { express_no })
    commit("setRecord", res.data)
    // Spin.hide();
    Message.success(i18n.t("store_modules_order_852"))
  },
  async refund_order({ commit }, params) {
    const res = await axios.post(`orders/${params.id}/refund`, {
      payment_method: params.payment_method,
    })
    commit("setRecord", res.data)
    Message.success(i18n.t("store_modules_order_853"))
  },
  async merchant_repair({ state }, id) {
    // Spin.show();
    await axios.post(`orders/${id}/merchant_repair`, state.repairInfo)
    // Spin.hide();
    Message.success(i18n.t("store_modules_order_854"))
  },
  async part_refund({ state }, params) {
    // Spin.show();
    const id = params.id
    Reflect.deleteProperty(params, "id") // 删除id属性
    await axios.post(`orders/${id}/part_refund`, params)
    // Spin.hide();
    Message.success(i18n.t("store_modules_order_855"))
  },
  // 合并订单
  async merge_order({ commit }, params) {
    const res = await axios.post(`orders/merge_order`, {
      from_order_id: params.from_order_id,
      to_order_id: params.to_order_id,
    })
    commit("setRecord", res.data)
    Message.success(i18n.t("store_modules_order_853"))
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
