import i18n from "../locales/index"
import request from "@/utils/request"
const baseurl = process.env.VUE_APP_BASE_URL
// 满减活动列表
export function getEnoughConsumeDiscounts(query) {
  return request({
    url: baseurl + "/enough_consume_discounts",
    method: "get",
    params: query,
  })
}
// 限时优惠
export function getLimitedTimeActivities(query) {
  return request({
    url: baseurl + "/limited_time_activities",
    method: "get",
    params: query,
  })
}
// 商品套餐
export function getCombos(query) {
  return request({
    url: baseurl + "/combos",
    method: "get",
    params: query,
  })
}
// 首单优惠检测
export function ordersCheckFirst(query) {
  return request({
    url: baseurl + "/orders/check_first",
    method: "get",
    params: query,
  })
}
