import i18n from "../../../locales/index"
// 快捷键说明弹框
import Vue from "vue"
import DialogSckey from "./DialogSckey.js"

export default function () {
  const v = new Vue({
    render(createElement) {
      return createElement(DialogSckey)
    },
  })
  v.$mount() // 渲染
  document.body.appendChild(v.$el) // 挂载
  const sckey = v.$children[0]
  // 显示弹窗
  function show(opt) {
    sckey.show(opt)
  }
  // 隐藏弹窗
  function hide(opt) {
    sckey.hide(opt)
  }
  // 切换显示状态
  function toggle(opt) {
    sckey.toggle(opt)
  }
  return {
    show,
    hide,
    toggle,
  }
}
