import i18n from "../../../locales/index"
import { transformBoolean } from "@/utils"

/**
 * 获取双屏显示
 * @returns {String} “1”已开启 “0”未开启
 */
export function isShowSecondDisplay() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.isShowSecondDisplay()
    } catch {
      console.log("isShowSecondDisplay报错了")
    }
  }
  return false
}

/**
 * 获取是否总是显示广告
 * @returns {String} “1”显示 “0”不显示
 */
export function secondDisplayAlwaysShowMedia() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.secondDisplayAlwaysShowMedia()
    } catch {
      console.log("secondDisplayAlwaysShowMedia报错了")
    }
  }
  return false
}
/**
 * 获取副屏要显示的视频文件存放路径
 * @returns
 */
export function getSecondDispalyVideoDirectory() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.getSecondDispalyVideoDirectory()
    } catch {
      console.log("getSecondDispalyVideoDirectory报错了")
    }
  }
  return i18n.t("pages_options_scale_index_611")
}
/**
 * 获取副屏要显示的轮播图存放路径
 * @returns
 */
export function getSecondDispalyImageDirectory() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.getSecondDispalyImageDirectory()
    } catch {
      console.log("getSecondDispalyImageDirectory报错了")
    }
  }
  return i18n.t("pages_options_scale_index_611")
}
/**
 * 获得播放类型
 * @returns "0"播放视频 "1"播放轮播图
 */
export function secondDispalyShowType() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.secondDispalyShowType()
    } catch {
      console.log("secondDispalyShowType报错了")
    }
  }
  return false
}
/**
 * 设置副屏显示和类型
 * @param {*} show "1" 显示副屏 "0" 隐藏副屏
 * @param {*} type "1" 轮播图 "0" 视频
 * @param {*} alwayShowMedia "1" 显示轮播图 "0" 显示视频
 */
export function showSecondDisplay(show, type, alwayShowMedia) {
  show = transformBoolean(show)
  alwayShowMedia = transformBoolean(alwayShowMedia)
  console.log("showSecondDisplay-======>", show, alwayShowMedia)
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.showSecondDisplay(
        show,
        type,
        alwayShowMedia
      )
    } catch {
      console.log("showSecondDisplay报错了")
    }
  }
}
/**
 * 是否固定左侧订单区
 * @param {*} show "0" 否 “1”是
 */
export function fixSecondDisplayLeftArea(show) {
  show = transformBoolean(show)
  console.log("fixSecondDisplayLeftAreashow", show)
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.fixSecondDisplayLeftArea(show)
    } catch {
      console.log("fixSecondDisplayLeftArea报错了")
    }
  }
}
/**
 * 设置副屏轮播图切换播放时间
 * @export
 * @param {*} data time 单位是秒
 */
export function switchSecondDisplayBannerTime(data) {
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.switchSecondDisplayBannerTime(data)
    } catch {
      console.log("switchSecondDisplayBannerTime报错了")
    }
  }
}
/**
 * 副屏商品列表
 * @param {*} data
 */
export function setSecondaryScreenDisplay(data) {
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.updateProduct(JSON.stringify(data))
    } catch {
      console.log("updateProduct报错了")
    }
  }
}
/**
 * 点单结束
 */
export function orderOver() {
  //
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.orderOver()
    } catch {
      console.log("orderOver报错了")
    }
  }
}
