import i18n from "../locales/index"
import { offlineGetOrderList } from "@/utils/appInterface/offline"

let worker = null
let mainWorker = null
let randomTime = null // 数据同步开始时间
export function initOrderSync() {
  console.log("初始化订单同步")
  setOrderSyncRandomTime()
}
// 设置随机同步时间
export function setOrderSyncRandomTime() {
  const list = offlineGetOrderList() ? offlineGetOrderList() : []
  if (list.length === 0) {
    return
  }
  if (!localStorage.getItem("randomOrderSyncTime")) {
    // 没有设置随机同步,就设置同步时间
    randomTime = new Date().getTime() + parseInt(Math.random() * 1000 * 60 * 60)
    localStorage.setItem("randomOrderSyncTime", randomTime)
  } else {
    randomTime = localStorage.getItem("randomOrderSyncTime")
  }
}
export function createOrderSync() {
  console.log("createOrderSync<=========")
  setOrderSyncRandomTime()
  if (new Date().getTime() > +randomTime) {
    manualOrderSync()
  } else {
    console.log("未到同步开始时间")
  }
}
// 手动订单同步
export function manualOrderSync() {
  console.log("createOrderSync<=========")
  const list = offlineGetOrderList() ? offlineGetOrderList() : []
  if (localStorage.getItem("isOffline") === "1" || list.length === 0) {
    if (worker) {
      worker.terminate() // 主进程关闭worker
    }
    return
  }
  // if (worker) {
  //   console.log('当前worker已经在执行之中了', worker);
  //   worker.terminate(); // 主进程关闭worker
  //   console.log('当前worker被关闭');
  // }
  console.log("开始同步订单了《==========")
  doOrderSync(list)
}
// 做订单同步操作
function doOrderSync(list) {
  worker = new Worker(
    new URL("@/worker/chirdren.webworker.js", import.meta.url)
  )
  mainWorker = new Worker(new URL("@/worker/main.worker.js", import.meta.url))
  mainWorker.postMsg({
    cmd: "start",
    list,
    globleData: {
      authorization: localStorage.getItem("authorization"),
      shopId: localStorage.getItem("shop_id"),
      exchangesId: localStorage.getItem("exchangesId"),
    },
  })
}
