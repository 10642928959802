import i18n from "../../locales/index"
// 挂单
import {
  productList,
  productUnitsList,
  productUnitsCreate,
} from "@/api/product"

const state = {
  pendOrderIndex: 0,
  isPendingToAdd: false,
  pendingToAddData: "",
}
const mutations = {
  SET_PENDING_TO_ADD_DATA: (state, data) => {
    state.pendingToAddData = data
  },
  SET_PENDING_TO_ADD: (state, data) => {
    state.isPendingToAdd = data
  },
  SET_PEND_ORDER_INDEX: (state, data) => {
    state.pendOrderIndex = data
  },
}

const getters = {}

const actions = {
  sliceSelectedPlist({ commit, rootState }, data) {
    // 切割购物车列表
    const products = rootState.orderPlus.selectProducts
    if (products.length > 5 && products.length % 5 === 1) {
      commit("product/SET_SELECTED_START_INDEX", products.length - 1, {
        root: true,
      })
      commit("product/SET_SELECTED_END_INDEX", products.length + 4, {
        root: true,
      })
    }
    commit(
      "orderPlus/SET_PAGE_SELECT_PRODUCTS",
      products.slice(
        rootState.product.selectedStartIndex,
        rootState.product.selectedEndIndex
      ),
      { root: true }
    )
  },
  pendingToAdd({ commit, dispatch }, data) {
    // 挂单追加操作
    commit("SET_PENDING_TO_ADD", true)
    commit("orderPlus/SET_SELECT_PRODUCTS", data.order_items, { root: true })
    commit(
      "orderPlus/SET_SELECTED_CART",
      data.order_items[data.order_items.length - 1],
      { root: true }
    )
    dispatch("sliceSelectedPlist")
    commit("SET_PENDING_TO_ADD_DATA", data)
    dispatch("orderPlus/computeTotal", null, { root: true })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
