<template>
  <div class="page-header-container">
    <div class="page-header-container__left">
      <slot v-if="isShowLeftSlot" name="left"></slot>
      <div v-else class="page-header-container__left--back" @click="clickLeft">
        <i class="el-icon-arrow-left"></i>
        {{ leftText }}
      </div>
    </div>
    <select-product-input v-if="isShowSearch" />
    <div v-if="isShowShop" class="page-header-container__right">
      <div class="page-header-container__right--shop_name">
        {{ shopName }}
      </div>
      <i
        :class="showUpArrow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
        @click="logOut"
      ></i>
      <div
        v-if="exitStatus"
        class="page-header-container__right--logout"
        @click="exit"
      >
        {{ $t("components_common_page_header_27") }}
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../../locales/index"

import { localRead } from "@/utils"
import SelectProductInput from "@/components/select-product-input.vue"
export default {
  props: {
    isShowLeftSlot: {
      type: Boolean,
    },
    isShowSearch: {
      type: Boolean,
      default: false,
    },
    isShowShop: {
      type: Boolean,
      default: true,
    },
    leftText: {
      type: String,
      default: i18n.t("components_common_page_header_28"),
    },
    shopName: {
      type: String,
      default: () => JSON.parse(localRead("shop"))?.name ?? "",
    },
  },
  components: {
    SelectProductInput,
  },
  data() {
    return {
      restaurants: [],
      state: "",
      keyValue: "",
      timeout: null,
      showUpArrow: false,
      exitStatus: false,
    }
  },
  methods: {
    exit() {
      this.exitStatus = false
      this.showUpArrow = false
      this.$router.push({
        path: "/successionIndex",
        query: {
          logout: 1,
        },
      })
    },
    logOut() {
      this.showUpArrow = !this.showUpArrow
      this.exitStatus = this.showUpArrow
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        cb(results)
      }, 1000 * Math.random())
    },
    clickLeft() {
      this.$emit("clickLeft")
    },
    loadAll() {
      return [
        {
          value: i18n.t("components_common_page_header_29"),
          address: i18n.t("components_common_page_header_30"),
        },
        {
          value: i18n.t("components_common_page_header_31"),
          address: i18n.t("components_common_page_header_32"),
        },
        {
          value: i18n.t("components_common_page_header_33"),
          address: i18n.t("components_common_page_header_34"),
        },
        {
          value: i18n.t("components_common_page_header_35"),
          address: i18n.t("components_common_page_header_36"),
        },
        {
          value: i18n.t("components_common_page_header_37"),
          address: i18n.t("components_common_page_header_38"),
        },
        {
          value: i18n.t("components_common_page_header_39"),
          address: i18n.t("components_common_page_header_40"),
        },
      ]
    },
    goBack() {},
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
      }
    },
    handleSelect(item) {
      console.log(item)
    },
  },
  mounted() {
    this.restaurants = this.loadAll()
  },
}
</script>

<style lang="scss">
.page-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  position: relative;
  &__left {
    font-weight: 400;
    font-size: 24px;
    &--back {
      display: flex;
      align-items: center;
    }
    .el-icon-arrow-left {
      font-size: 24px;
      font-weight: 600;
      margin-right: 10px;
    }
  }
  &__center {
    display: flex;
    align-items: center;
    width: 480px;
    height: 44px;
    > div {
      width: 100% !important;
      border: 1px solid rgba(143, 143, 143, 0.6);
      border-radius: 200px;
      overflow: hidden;
      .el-input__inner {
        background: rgba(253, 220, 220, 0.2);
      }
      .el-input__suffix {
        right: 15px;
        font-weight: 500;
        font-size: 15px;
      }
    }
    &--keyboard {
      width: 74px;
      height: 74px;
      margin-left: 20px;
    }
  }
  &__right {
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    &::before {
      content: "";
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: url("~@/assets/images/common/shop_icon.png") 100%/100%
        no-repeat;
    }
    .el-icon-arrow-down,
    .el-icon-arrow-up {
      font-size: 30px;
      font-weight: 600;
    }

    &--shop_name {
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #f35050;
      margin: 0 15px;
    }
    &--logout {
      position: absolute;
      top: 70px;
      right: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 287px;
      height: 60px;
      background: #ffffff;
      border: 1px solid #bcbcbc;
      border-radius: 6px;
      box-shadow: 0px 4px 19px 0px rgba(140, 133, 133, 0.25);
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #000000;
      box-sizing: border-box;
      &::before {
        content: "";
        display: block;
        width: 28px;
        height: 28px;
        margin-right: 20px;
        background: url("~@/assets/images/common/logout_btn.png") 100%/100%
          no-repeat;
      }
    }
  }
}
</style>
