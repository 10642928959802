import debounce from "./debounce";
import throttle from "./throttle";
import time from "./time";
import ownerless from "./ownerless";
const directives = function (Vue) {
    debounce(Vue);
    throttle(Vue);
    time(Vue);
    ownerless(Vue);
};
export default directives;
