import i18n from "../../locales/index"
const state = {
  readingWeight: 0.0, // 电子秤的读数
  isSteady: 0, // 电子秤是否稳定
  scaleInterval: null, // 电子秤清零的循环
  continue_times: 0, // 电子秤清零的计数
  weightError: false, // 电子秤重量报错
  scaleDataBuffer: [], // Windows电子秤存的数据
}

const mutations = {
  SET_READING_WEIGHT: (state, data) => {
    // console.log('SET_READING_WEIGHT', data);
    state.readingWeight = data
  },
  SET_IS_STEADY: (state, data) => {
    state.isSteady = data
  },
  SET_SCALE_INTERVAL: (state, data) => {
    state.scaleInterval = data
  },
  SET_CONTINUE_TIMES: (state, data) => {
    state.continue_times = data
  },
  SET_WEIGHT_ERROR: (state, data) => {
    state.weightError = data
  },
  SET_SCALEDATA_BUFFER: (state, data) => {
    state.scaleDataBuffer = data
  },
}

const getters = {}

const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
