import i18n from "../../locales/index"
// import { getOemInfo } from '@/api/oem';
// import { oemName } from '@/utils/appInterface/android_jsbridge';
import { env } from "@/utils"
// import { offlineGetOrderList } from '@/utils/appInterface/offline';

const state = {
  updateOffline: false,
  manualUpdateOffline: false,
}

const mutations = {
  CHANGE_UPDATE_OFFLINE: (state) => {
    if (env() === "android") {
      state.updateOffline = !state.updateOffline
    }
  },
  CHANGE_MANUAL_UPDATE_OFFLINE: (state) => {
    state.manualUpdateOffline = !state.manualUpdateOffline
  },
}

const getters = {
  isOffline: () => {
    return localStorage.getItem("isOffline") === "1"
  },
}

const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
