import i18n from "../locales/index"
import auth from "./modules/auth.js"
import member from "./modules/member.js"
import order from "./modules/order.js"
import product from "./modules/product.js"
import succession from "./modules/succession.js"
import setting from "./modules/setting.js"
import score from "./modules/score.js"
import other from "./modules/other.js"
import routeStatus from "./modules/routeStatus.js"

export default [
  {
    path: "/",
    name: "home",
  },
  {
    path: "/layout",
    name: "layout",
    component: () =>
      import(/* webpackChunkName: "layout" */ "@/pages/layout.vue"),
    children: [
      {
        // 点单页 - 选择商品
        path: "/selectProduct",
        name: "selectProduct",
        component: () => {
          const industry = localStorage.getItem("industry")
          const isAI = localStorage.getItem("isAI") === "1"
          if (isAI) {
            return import(
              /* webpackChunkName: "selectProductOfAI" */ "@/pages/selectProduct/AI/index.vue"
            )
          }
          switch (industry) {
            // 生鲜行业
            case "fresh_fruits":
              return import(
                /* webpackChunkName: "selectProductOfFresh" */ "@/pages/selectProduct/fresh/index.vue"
              )
            // 零售行业
            case "retail":
              return import(
                /* webpackChunkName: "selectProductOfRetail" */ "@/pages/selectProduct/retail/index.vue"
              )
            // 中餐行业
            case "chinese":
              return import(
                /* webpackChunkName: "selectProductOfChinese" */ "@/pages/selectProduct/catering/index.vue"
              )
            default:
              return import(
                /* webpackChunkName: "selectProductOfFresh" */ "@/pages/selectProduct/fresh/index.vue"
              )
          }
        },
      },
      {
        // 餐饮桌台页
        path: "/cateringTable",
        name: "cateringTable",
        component: () =>
          import(
            /* webpackChunkName: "cateringTable" */ "@/pages/cateringTable/index.vue"
          ),
      },
      {
        // 收银页面
        path: "/cashier",
        name: "cashier",
        component: () =>
          import(/* webpackChunkName: "cashier" */ "@/pages/cashier"),
      },
      {
        // 挂单 - 取单
        path: "/orderFetch",
        name: "orderFetch",
        component: () =>
          import(
            /* webpackChunkName: "orderFetch" */ "@/pages/order/order-fetch.vue"
          ),
      },
      {
        // 预包装
        path: "/prepackaging",
        name: "prepackaging",
        component: () => {
          const isAI = localStorage.getItem("isAI") === "1"
          if (isAI) {
            return import(
              /* webpackChunkName: "prepackagingOfAI" */ "@/pages/prepackaging/AI/index.vue"
            )
          }
          return import(
            /* webpackChunkName: "prepackaging" */ "@/pages/prepackaging/index.vue"
          )
        },
      },
      {
        // 盘点
        path: "/inventoryIndex",
        name: "inventoryIndex",
        component: () =>
          import(
            /* webpackChunkName: "inventoryIndex" */ "@/pages/inventory/index.vue"
          ),
      },
      {
        // 沽清
        path: "/selloutIndex",
        name: "selloutIndex",
        component: () =>
          import(
            /* webpackChunkName: "selloutIndex" */ "@/pages/sellOut/index.vue"
          ),
      },
      ...member, // 会员
      ...order, // 订单
      ...product, // 商品
      ...succession, // 交接班
      ...setting, // 设置
      ...score, // 积分
      ...other, // 其他不重要又分不开的路由
    ],
  },
  ...auth, // 用户
  ...routeStatus, // 路由状态 404 401等
]
