import i18n from "../../locales/index"
export default [
  {
    // 订单列表和退货
    path: "/orderHistory",
    name: "orderHistory",
    component: () =>
      import(
        /* webpackChunkName: "orderHistory" */ "@/pages/order/order-history.vue"
      ),
  },
  {
    // 外卖单据
    path: "/orderTakeout",
    name: "orderTakeout",
    component: () =>
      import(
        /* webpackChunkName: "orderTakeout" */ "@/pages/order/order-takeout.vue"
      ),
  },
]
