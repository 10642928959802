<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import i18n from "./locales/index"

import { createOrderSync, manualOrderSync } from "@/worker/orderSyncNoWorker.js"
import { mapState, mapActions } from "vuex"
export default {
  name: "App",
  components: {},
  props: [],
  data() {
    return {
      scExplain: "f1",
      scEmptyCart: "ctrl+z",
      scPopDrawer: "f7",
      scCollection: "enter",
      scSettlement: "space",
      scReturn: "esc",
    }
  },
  computed: {
    ...mapState("offline", ["updateOffline", "manualUpdateOffline"]),
    ...mapState("takeout_order", ["takeout_platform_status"]),
  },
  watch: {
    updateOffline: function (val) {
      console.log("updateOffline=======<", val)
      createOrderSync()
    },
    manualUpdateOffline: function (val) {
      console.log("manualUpdateOffline=======<", val)
      manualOrderSync()
    },
  },
  mounted() {
    // 快捷键显示切换
    this.sckey.scExplain(() => {
      this.$dialogsckey.toggle([
        { name: i18n.t("app_1"), key: "f1", name2: "scExplain" },
        { name: i18n.t("app_2"), key: "ctrl+z", name2: "scEmptyCart" },
        { name: i18n.t("app_3"), key: "f7", name2: "scPopDrawer" },
        { name: i18n.t("app_4"), key: "enter", name2: "scCollection" },
        { name: i18n.t("app_5"), key: "space", name2: "scSettlement" },
        { name: i18n.t("app_6"), key: "esc", name2: "scReturn" },
      ])
    })
    if (this.takeout_platform_status) {
      this.timer = setInterval(() => {
        this.getUsbUnprintedTakeoutOrders()
      }, 10000)
    }
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    ...mapActions("takeout_order", ["getUsbUnprintedTakeoutOrders"]),
  },
}
</script>
<style lang="scss">
@import "@/assets/sass/reset.scss";
</style>
