import i18n from "../locales/index"
import Vue from "vue"
import { getbarCodeRule } from "@/api/prepackaging"
export default {
  //   createOrder({ commit, state }, param) {

  //   },
  getSettingInfo({ commit, state }) {
    Vue.prototype
      .$axios({
        method: "get",
        url: "/settings/display",
      })
      .then(({ data }) => {
        commit("SET_SETTINGS", data)
        if (data.small_change_discount_digit === "none") {
          Vue.prototype.$axios({
            method: "post",
            url: "/settings/upgrade",
            data: {
              small_change_discount_digit: "fen",
            },
          })
        }
        localStorage.setItem("shop_code", data.shop_code)
        localStorage.setItem("user_says", data.receipt_bottom)
        localStorage.setItem("second_card_rate", data.second_card_rate)
        localStorage.setItem("integralOffset", data.customer_score_open ? 1 : 0)
        localStorage.setItem(
          "open_alipay_facepay",
          data.open_alipay_facepay ? 1 : 0
        )
        if (!localStorage.getItem("receipt_width")) {
          localStorage.setItem("receipt_width", 58)
          // if (data.receipt_width == 48) {
          //   localStorage.setItem('receipt_width', 80);
          // } else {
          //   localStorage.setItem('receipt_width', 58);
          // }
        }
        localStorage.setItem(
          "small_change_discount_digit",
          data.small_change_discount_digit === "none"
            ? "fen"
            : data.small_change_discount_digit
        )
        localStorage.setItem("ticket_qr_show", data.ticket_qr_show ? 1 : 0)
        localStorage.setItem("pickup_code_show", data.pickup_code_show ? 1 : 0)
        localStorage.setItem(
          "pickup_code_alias_name",
          data.pickup_code_alias_name
        )
        localStorage.setItem("small_discount", data.small_discount ? 1 : 0)
        localStorage.setItem(
          "only_online_charge",
          data.only_online_charge ? 1 : 0
        )
        localStorage.setItem("printer_select", data.printer_select)
        localStorage.setItem(
          "open_member_count",
          data.open_member_count ? "1" : "0"
        )
        localStorage.setItem(
          "auto_close_estimation",
          data.auto_close_estimation ? "1" : "0"
        )
        if (data.bar_code_id != null) {
          getbarCodeRule({
            // 货号规则
            id: data.bar_code_id,
          }).then(({ data }) => {
            localStorage.setItem("articleNumberRule", data.content)
          })
        }
        localStorage.setItem("customer_score_rate", data.customer_score_rate)
        state.material_alias_name = data.material_alias_name
        state.ticket_qr_show = localStorage.getItem("ticket_qr_show") == "1"
        state.pickup_code_show = localStorage.getItem("pickup_code_show") == "1"
        state.pickup_code_alias_name = localStorage.getItem(
          "pickup_code_alias_name"
        )
        state.small_discount = localStorage.getItem("small_discount") == "1"
        state.small_change_discount_digit = localStorage.getItem(
          "small_change_discount_digit"
        )
          ? localStorage.getItem("small_change_discount_digit")
          : "fen"
        state.receipt_width = localStorage.getItem("receipt_width")
        state.user_says = localStorage.getItem("user_says")
        state.open_member_count =
          localStorage.getItem("open_member_count") === "1"
        state.auto_close_estimation =
          localStorage.getItem("auto_close_estimation") === "1"

        commit(
          "activity/SET_FIRST_ORDER_DISCOUNT_OPEN",
          data.first_order_discount_open
        )
        commit("activity/SET_FIRST_ORDER_DISCOUNT", data.first_order_discount)
        commit(
          "activity/SET_BIRTHDAY_DISCOUNT_OPEN",
          data.birthday_discount_open
        )
        commit("activity/SET_BIRTHDAY_DISCOUNT", data.birthday_discount)
        commit(
          "activity/SET_OFFLINE_COUPON_ALIAS_NAME",
          data.offline_coupon_alias_name
        )
        commit(
          "takeout_order/SET_TAKEOUT_PLATFORM_STATUS",
          data.takeout_platform_status
        )
      })
  },
  updateSettingInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      Vue.prototype
        .$axios({
          method: "post",
          url: "/settings/upgrade",
          data: {
            receipt_bottom: state.user_says,
            customer_score_open: localStorage.getItem("integralOffset") === "1",
            small_change_discount_digit: state.small_change_discount_digit,
            ticket_qr_show: state.ticket_qr_show,
            pickup_code_show: state.pickup_code_show,
            pickup_code_alias_name: state.pickup_code_alias_name,
            small_discount: state.small_discount,
          },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  singleUpdateSetting({ commit, state }, body) {
    return new Promise((resolve, reject) => {
      Vue.prototype
        .$axios({
          method: "post",
          url: "/settings/upgrade",
          data: body,
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}
