import i18n from "../../locales/index"
export default [
  {
    // 历史销售商品记录
    path: "/historyCommodity",
    name: "historyCommodity",
    component: () =>
      import(
        /* webpackChunkName: "historyCommodity" */ "@/pages/succession/history-commodity.vue"
      ),
  },
  {
    // 消息中心
    path: "/messageIndex",
    name: "messageIndex",
    component: () =>
      import(
        /* webpackChunkName: "messageIndex" */ "@/pages/message/index.vue"
      ),
  },
  {
    // 日商品销售明细
    path: "/sellDayDetail",
    name: "sellDayDetail",
    component: () =>
      import(
        /* webpackChunkName: "sellDayDetail" */ "@/pages/order/sell-detail.vue"
      ),
  },
  {
    // 副屏页面
    path: "/secondaryScreen",
    name: "secondaryScreen",
    component: () =>
      import(
        /* webpackChunkName: "secondaryScreen" */ "@/pages/secondaryScreen"
      ),
  },
  {
    // 隐私政策
    path: "/privacy",
    name: "privacy",
    meta: { title: i18n.t("router_modules_other_836") },
    component: () =>
      import(/* webpackChunkName: "privacy" */ "@/pages/agreement/privacy.vue"),
  },
  {
    // ai测试
    path: "/babyUi",
    name: "babyUi",
    component: () =>
      import(/* webpackChunkName: "retail" */ "@/pages/baby-ui.vue"),
  },
]
