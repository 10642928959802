import i18n from "../../locales/index"
import { genBasicStore } from "../vuex"
import assign from "lodash/assign"
import axios from "@/libs/axios.js"
import { Message } from "element-ui"

const store = genBasicStore("desk")
assign(store.state, {
  currentDesk: null,
  currentDeskType: "union",
})
assign(store.mutations, {
  SET_CURRENT_DESK(state, val) {
    const desk = state.currentDesk
    if (desk) {
      if (typeof val == "string" && val.constructor == String) {
        desk.current_member_count = val
        state.currentDesk = desk
      }
    }
    if (val instanceof Object) {
      state.currentDesk = val
    }
  },
  SET_DESK_TYPE(state, val) {
    state.currentDeskType = val
  },
})
assign(store.actions, {
  async update({ commit }, params) {
    const { ...others } = params
    params.discount = +params.discount
    params.upgrade_standard = +params.upgrade_standard
    const response = await axios.put(`desks/${params.id}`, others)
    commit("updateRecordInRecords", response.data)
    Message.success(i18n.t("store_modules_desk_845"))
  },
  async create({ commit }, options) {
    const { ...params } = options
    params.discount = +params.discount
    params.upgrade_standard = +params.upgrade_standard
    const response = await axios.post("desks", params)
    commit("setRecord", response.data)
    commit("addRecordInRecords", response.data)
    Message.success(i18n.t("pages_product_product_add_707"))
  },
  async clear({ commit }, id) {
    if (!id) {
      Message.warning(i18n.t("components_order_orderchangedesk_132"))
      return
    }
    try {
      await axios.post(`desks/${id}/clear`)
      Message.success(i18n.t("store_modules_desk_846"))
    } catch (error) {}
    // commit('setRecord', response.data);
    // commit('addRecordInRecords', response.data);
  },
})

export default store
