import i18n from "../../locales/index"
import axios from "@/libs/axios.js"
import {
  print,
  printLabel,
  printBluetooth,
  printWithWLAN,
  printSpecifyWithWLAN,
} from "@/utils/appInterface/jsbridge"
import { getBillContent } from "@/utils/appInterface/offline/localPrint"
import restful from "../vuex"
import { printerTestData } from "@/utils/value-list.js"
import { getPrinterMode } from "@/utils"
import { localRead, isValidPaymentMode } from "@/utils"
const SHOP = JSON.parse(JSON.stringify(localRead("shop")))

const state = {
  ...restful.states("printer_config"),
}

const getters = {
  ...restful.getters("printer_config"),
}

const actions = {
  ...restful.actions("printer_configs"),
  // scene:  print_after_create print_after_pay
  async print({ commit }, { scene, ...params }) {
    // 在创建订单的时间点, 并且行业不是餐饮, 就不打印
    if (scene === "print_after_create" && SHOP.shop_type !== "chinese") {
      return
    }
    const { data = {} } = await axios.get(`printer_configs/print`, { params })
    const { contents = [] } = data
    for (const item of contents) {
      if (item.printer_receipt_config_mode === "prepackage") continue
      commit("handlePrint", {
        ...item,
        scene,
        id: item.printer_config_id,
        printer_type: item.printer_config_printer_type,
        mode: item.printer_receipt_config_mode,
        copies: item.printer_receipt_config_copies,
      })
    }
  },
  async offlinePrint({ rootState }, params) {
    const content = getBillContent({
      ...rootState.orderPlus.offlineSingleOrder,
      ...params,
    })
    console.log("打印离线订单", content)
    print(content, 1)
  },
  printerTest({ commit }, printer) {
    if (printer.printer_type === "gprs") {
      axios.get(`/printer_configs/${printer.id}/test_cloud_print`)
    } else {
      commit("handlePrint", {
        id: printer.id,
        scene: "manual",
        printer_type: printer.printer_type,
        mode: getPrinterMode(printer) || "checkout",
        content: printerTestData.get(printer.receipt_width || "58"),
        copies: 1,
      })
    }
  },
}

const mutations = {
  ...restful.mutations("printer_config"),
  handlePrint(context, params = {}) {
    console.log("handlePrint params", params)
    const isPrintScene =
      params[params.scene] ||
      params.scene === "manual" ||
      SHOP.shop_type !== "chinese"
    if (isPrintScene) {
      console.log("打印成功")
      // 时间点对的时候打印
      if (params.printer_type === "usb") {
        if (params.mode === "label" || params.mode === "prepackage") {
          printLabel(params.content, params.copies, params.id)
        } else {
          print(params.content, params.copies, params.id)
        }
      } else if (params.printer_type === "bluetooth") {
        printBluetooth(params.content, params.copies)
      } else if (params.printer_type === "network") {
        if (params.id) {
          printSpecifyWithWLAN(params.id, params.content, params.copies)
        } else {
          printWithWLAN(params.content, params.copies)
        }
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
