import i18n from "../../locales/index"
import restful from "../vuex"
import { localRead, localSave } from "@/utils"

const state = {
  ...restful.states("permissions"),
}

const getters = {
  ...restful.getters("permissions"),
  generalCashierPermissions: (state) => {
    const result = state.permissions.find(
      (item) => item.name === "generalCashier"
    )
    return result ? result.fresh_permission_items : []
  },
  standardCashierPermissions: (state) => {
    const result = state.permissions.find(
      (item) => item.name === "standardCashier"
    )
    return result ? result.fresh_permission_items : []
  },
}

const actions = {
  ...restful.actions("permissions"),
  async localSavePermissions({ getters, dispatch }) {
    await dispatch("index", {
      page: 1,
      per: 999,
    })
    const se = localRead("software_edition")
    if (se === "standard") {
      localSave(
        "cashierExcludePermissions",
        JSON.stringify(getters.standardCashierPermissions)
      )
    } else {
      localSave(
        "cashierExcludePermissions",
        JSON.stringify(getters.generalCashierPermissions)
      )
    }
  },
}

const mutations = {
  ...restful.mutations("permissions"),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
