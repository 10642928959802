import i18n from "../locales/index"
import axios from "axios"
import { Message } from "element-ui"
import router from "@/router"
import { getToken, setToken, getShopId } from "@/utils/auth"
import { getResMsg } from "@/utils"

let lastMsg = ""
let lastResTime = 0
function debounceMsg(msg, code) {
  // 错误提示防抖
  if (
    !(lastMsg === msg && lastResTime - new Date().getTime() < 1000) &&
    code !== 200
  ) {
    Message.error(msg)
  }
  lastMsg = msg
  lastResTime = new Date().getTime()
}
const service = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
})
service.interceptors.request.use(
  (config) => {
    const conf = { ...config }
    conf.validateStatus = (status) => status >= 200 && status <= 505
    if (getToken()) {
      conf.headers.Authorization = getToken()
    }
    if (getShopId()) {
      conf.headers.ShopId = getShopId()
    }
    return conf
  },
  (error) => Promise.reject(error) // 返回接口返回的错误信息
)
service.interceptors.response.use(
  (response) => {
    const res = response.data
    const code = response.status
    let msg = response?.data?.message
    if (msg === "Network Error") {
      msg = i18n.t("libs_axios_200")
    }
    if (!msg) msg = getResMsg(code)
    debounceMsg(msg, code)
    if (/^401$/.test(code)) {
      setToken("")
      router.replace("/login")
    }
    return { ...res, status: response.status }
  },
  (error) => {
    let msg = error.message
    if (msg === "Network Error") {
      msg = i18n.t("libs_axios_200")
    }
    debounceMsg(msg, 201)
    return Promise.reject(error)
  }
)

export default service
