import i18n from "../locales/index"
import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store"
import routes from "./routes"
import { canTurnTo } from "@/utils/permission"
import { localRead } from "@/utils"
import { getDeadComponents } from "@/fp/keepAlive.js"
Vue.use(VueRouter)
// 解决路由地址重复的报错问题
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => {
    console.log("err", err)
    return err
  })
}
const turnTo = (to, next) => {
  if (canTurnTo(to.name) || to.name === "error401") next()
  // 有权限，可访问
  else {
    next({
      replace: true,
      name: "error401",
    })
  } // 无权限，重定向到401页面
}
const router = new VueRouter({
  // mode: 'history',
  routes,
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  // 不走拦截的路由
  const nextRoute = ["login", "shops", "reset", "privacy"]
  // 是否登录
  const isLogin =
    window.localStorage.getItem("authorization") &&
    localStorage.getItem("shop_id")
  // 餐饮行业的点餐模式
  const orderMode = localRead("order_mode")
  // 店铺行业
  const industry = localRead("industry")
  // 设置网页标题
  document.title =
    to?.meta?.title || i18n.t("router_index_835", [process.env.VUE_APP_NAME])

  console.log("to from2", to, from)
  // 全局路由拦截
  if (nextRoute.indexOf(to.name) < 0) {
    if (!isLogin) {
      next({ path: "/login" })
    } else {
      if (
        to.name === "home" &&
        industry === "chinese" &&
        orderMode !== "pickup"
      ) {
        next({ path: "/cateringTable" })
      } else if (to.name === "home") {
        next({ path: "/selectProduct" })
      } else {
        turnTo(to, next)
      }
    }
  } else {
    next()
  }
  console.log("to from", to, from)
  // keep alive组件控制
  if (to.name === "login") {
    store.commit("keepAlive/clearAliveCompontent")
  }
  if (from.name === "login") {
    store.commit("keepAlive/resetAliveCompontent")
  }
  if (to.name !== "login" && from.name !== "login") {
    const excludeComponents = getDeadComponents(to.name, from.name)
    console.log("excludeComponents", excludeComponents)
    store.commit("keepAlive/SET_DEAD_COMPONTENT", excludeComponents)
  }
})
export default router
