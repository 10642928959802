import i18n from "../../locales/index"
/**
 *电子秤
 */
export function setZero() {
  // 归零
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.setZero()
    } catch {
      console.log("setZero报错了")
    }
  }
}
export function setTare() {
  // 去皮
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.setTare()
    } catch {
      console.log("setTare报错了")
    }
  }
}
export function getWight() {
  // 获取电子秤重量
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.getWight()
    } catch {
      console.log("getWight报错了")
    }
  }
  return false
}
export function openScale(val, addr, type, port) {
  // 开启或关闭安卓推送的重量
  if (window.TurbolinksJavaScriptObject) {
    try {
      if (val && (addr || type || port)) {
        window.TurbolinksJavaScriptObject.openScale(val, addr, type, port)
      }
    } catch {
      console.log("openScale报错了")
    }
  }
}

/**
 *钱箱
 */
export function openCashBox() {
  // 打开钱箱
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.openCashBox()
    } catch {
      console.log("openCashBox报错了")
    }
    return true
  }
  return false
}

/**
 *客显
 */
export function initGuestDisplay(addr, port) {
  // 初始化客显
  if (window.TurbolinksJavaScriptObject) {
    try {
      if (addr && port) {
        window.TurbolinksJavaScriptObject.initGuestDisplay(addr, port)
      }
    } catch {
      console.log("initGuestDisplay报错了")
    }
  }
}

export function clearGuestDisplay() {
  // 客显清屏
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.clearGuestDisplay()
    } catch {
      console.log("clearGuestDisplay报错了")
    }
  }
}

export function showGuestDisplayType(type) {
  // 客显类型 0 全暗 1.单价 2 总计 3. 收款 4 找零
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.showGuestDisplayType(type)
    } catch {
      console.log("showGuestDisplayType报错了")
    }
  }
}

export function showGuestDisplayData(data) {
  // 显示数据,与showGuestDisplayType配合使用
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.showGuestDisplayData(data)
    } catch {
      console.log("showGuestDisplayData报错了")
    }
  }
}

export function showGuestDisplayLight(d1, d2, d3, d4) {
  /**
   * 用来开关客显的四个灯
   * 当d1=0时，单价灯灭；d1=1时，单价灯亮
   * 当d2=0时，总计灯灭；d2=1时，总计灯亮
   * 当d3=0时，收款灯灭；d3=1时，收款灯亮
   * 当d4=0时，找零灯灭；d4=1时，找零灯亮
   * */
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.showGuestDisplayLight(d1, d2, d3, d4)
    } catch {
      console.log("showGuestDisplayLight报错了")
    }
  }
}

/**
 * 双屏显示
 * */

export function isShowSecondDisplay() {
  // 返回结果 “1”已开启 “0”未开启
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.isShowSecondDisplay()
    } catch {
      console.log("isShowSecondDisplay报错了")
    }
  }
  return false
}

export function secondDisplayAlwaysShowMedia() {
  // 返回结果 “1”显示 “0”不显示
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.secondDisplayAlwaysShowMedia()
    } catch {
      console.log("secondDisplayAlwaysShowMedia报错了")
    }
  }
  return false
}

export function showSecondDisplay(show, type, alwayShowMedia) {
  /**
   * show "1" 显示副屏 "0" 隐藏副屏
   * type "1" 轮播图 "0" 视频
   * */
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.showSecondDisplay(
        show ? "1" : "0",
        type,
        alwayShowMedia ? "1" : "0"
      )
    } catch {
      console.log("showSecondDisplay报错了")
    }
  }
}

export function fixSecondDisplayLeftArea(show) {
  /**
   *  是否固定左侧订单区 "0" 否 “1”是
   * */
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.fixSecondDisplayLeftArea(
        show ? "1" : "0"
      )
    } catch {
      console.log("fixSecondDisplayLeftArea报错了")
    }
  }
}

export function switchSecondDisplayBannerTime(data) {
  // 副屏轮播图切换播放时间  time 单位是秒
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.switchSecondDisplayBannerTime(data)
    } catch {
      console.log("switchSecondDisplayBannerTime报错了")
    }
  }
}

export function secondDispalyShowType() {
  // 返回 "0"播放视频 "1"播放轮播图
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.secondDispalyShowType()
    } catch {
      console.log("secondDispalyShowType报错了")
    }
  }
  return false
}

export function setSecondaryScreenDisplay(data) {
  // 副屏商品列表
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.updateProduct(JSON.stringify(data))
    } catch {
      console.log("updateProduct报错了")
    }
  }
}

export function orderOver() {
  // 点单结束
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.orderOver()
    } catch {
      console.log("orderOver报错了")
    }
  }
}

export function getSecondDispalyVideoDirectory() {
  // 获取副屏要显示的视频文件存放路径
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.getSecondDispalyVideoDirectory()
    } catch {
      console.log("getSecondDispalyVideoDirectory报错了")
    }
  }
  return i18n.t("pages_options_scale_index_611")
}

export function getSecondDispalyImageDirectory() {
  // 获取副屏要显示的轮播图存放路径
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.getSecondDispalyImageDirectory()
    } catch {
      console.log("getSecondDispalyImageDirectory报错了")
    }
  }
  return i18n.t("pages_options_scale_index_611")
}

/**
 * 打印
 * @printerConfigNO 打印机编号
 * @suborderNumber 子订单标号
 */
export function print(content) {
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.print(content)
    } catch {
      console.log("print报错了")
    }
  }
}
/**
 * 标签打印
 * @printerConfigNO 打印机编号
 * @suborderNumber 子订单标号
 */
export function printLabel(content) {
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.printLabel(content)
    } catch {
      console.log("printLabel报错了")
    }
  }
}
/**
 * 蓝牙打印
 */
export function printBluetooth(content) {
  console.log("printBluetooth方法调用了")
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.bluetoothPrint(content)
    } catch {
      console.log("printBluetooth报错了")
    }
  }
}
/**
 * 使用局域网内的打印机打印，如果有多台则全部打印
 * @param content
 */
export function printWithWLAN(content) {
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.printWithWLAN(content)
    } catch {
      console.log("printWithWLAN报错了")
    }
  }
}

/**
 * 指定打印机打印
 *
 * @param printerId 打印机设置中服务端返回的打印机的id（仅限网络打印机 wlan）
 * @param content
 */
export function printSpecifyWithWLAN(printerId, content) {
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.printWithWLAN(printerId, content)
    } catch {
      console.log("printWithWLAN报错了")
    }
  }
}
/**
 * 网络打印机设置
 * @param printerId 打印机已设置的情况下传打印机Id，未设置传0
 */
export function wlanPrinterSetting(printerId) {
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.wlanPrinterSetting(printerId)
    } catch {
      console.log("wlanPrinterSetting报错了")
    }
  }
}
/**
 * 蓝牙打印机设置
 */
export function openBluetoothPrinterSettings() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.openBluetoothPrinterSettings()
    } catch {
      console.log("openBluetoothPrinterSettings报错了")
    }
  }
}
/**
 * 标签打印机设置
 */
export function showUsbLabelPrinterSetting() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.showUsbLabelPrinterSetting()
    } catch {
      console.log("showUsbLabelPrinterSetting报错了")
    }
  }
}
// USB打印机设置
export function showPrinterSetting() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.showPrinterSetting()
    } catch {
      console.log("showPrinterSetting报错了")
    }
  }
}

// 通用打印机设置
export function openPrinterSetting(printer_type, printerId) {
  switch (printer_type) {
    case "usb":
      showPrinterSetting()
      break
    case "gprs":
      break
    case "network":
      wlanPrinterSetting()
      break
    case "bluetooth":
      openBluetoothPrinterSettings()
      break
    case "label":
      showUsbLabelPrinterSetting()
      break
  }
}

/**
 * 退到桌面
 */

export function backToHome() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.backToHome()
    } catch {
      console.log("backToHome报错了")
    }
  }
}

/**
 * 切换webview
 */

export function openUrlOnBrowser() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      window.TurbolinksJavaScriptObject.openAdmin()
    } catch {
      console.log("openUrlOnBrowser")
    }
  }
}

/**
 * 获取App版本
 */

export function getAppVersion() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.getAppVersion()
    } catch {
      console.log("openUrlOnBrowser")
    }
  }
}
/**
 * 更新App版本
 */
export function downloadAndInstallApp(apkUrl, fileName) {
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.downloadAndInstallApp(
        apkUrl,
        fileName
      )
    } catch {
      console.log("downloadAndInstallApp")
    }
  }
}

/**
 * 禁用输入法
 */

export function forbiddSysSoftInput() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.forbiddSysSoftInput()
    } catch {
      console.log("forbiddSysSoftInput")
    }
  }
}

/**
 * 弹出输入法
 */

export function allowSysSoftInput() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.allowSysSoftInput()
    } catch {
      console.log("allowSysSoftInput")
    }
  }
}

/**
 * 选择文件
 * params @type
 * 0 视频  1 图片
 */

export function selectDirectory(type) {
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.selectDirectory(type)
    } catch {
      console.log("selectDirectory")
    }
  }
}

/**
 * 初始化副屏设置
 *
 */
export function getSecondDisplaySetup() {
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.getSecondDisplaySetup()
    } catch {
      console.log("getSecondDisplaySetup")
    }
  }
}

/**
 *  请求支付宝扫脸的MetaInfo
 *
 */
export function requestAlipayMetaInfo() {
  console.log("requestAlipayMetaInfo")
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.requestMetaInfo()
    } catch (error) {
      console.log(error)
    }
  }
}

/**
 *  支付宝扫脸设备端支付
 *
 */
export function alipaySimlePay(zimid, zim_init) {
  console.log("alipaySimlePay")
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.simlePay(zimid, zim_init)
    } catch (error) {
      console.log(error)
    }
  }
}

/**
 * 设置IC读卡
 */
export function setReadIc(fn) {
  window.readIc = (code) => {
    fn(code)
  }
}

/**
 *获取OEM名字
 */
export function oemName() {
  console.log("android getoemName")
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.oemName()
    } catch (error) {
      console.log(error)
    }
  }
  return null
}

/**
 *  播放声音的内容
 *
 */
export function playVoice(content) {
  console.log("playVoice")
  if (window.TurbolinksJavaScriptObject) {
    try {
      return window.TurbolinksJavaScriptObject.playVoice(content)
    } catch (error) {
      console.log(error)
    }
  }
}
