import i18n from "../locales/index"
import request from "@/utils/request"
const baseurl = process.env.VUE_APP_BASE_URL

export function categoryList(query) {
  return request({
    url: baseurl + "/categories",
    method: "get",
    params: query,
  })
}
export function categoryCreate(data) {
  return request({
    url: baseurl + "/categories",
    method: "post",
    data,
  })
}

export function categoryUpdate(data) {
  return request({
    url: baseurl + `/categories/${data.id}`,
    method: "put",
    data,
  })
}
export function categoryDelete(id) {
  return request({
    url: baseurl + `/categories/${id}`,
    method: "delete",
  })
}
