import i18n from "../locales/index"
import request from "@/utils/request"
const baseurl = process.env.VUE_APP_BASE_URL

// 获取会员列表
export function getCustomers(param) {
  return request({
    url: baseurl + "/customers",
    method: "get",
    params: param,
  })
}

// 获取会员详情
export function customerDetail(param) {
  return request({
    url: baseurl + `/customers/${param.id}`,
    method: "get",
  })
}

// 获取会员等级
export function getCustomerLevels(param) {
  return request({
    url: baseurl + "/customer_levels",
    method: "get",
  })
}
