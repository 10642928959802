import i18n from "../../locales/index"
// alive compontent状态
const initialCompontent = [
  "home",
  "cashier",
  "selectProduct",
  "cateringTable",
  "prepackaging",
  "memberSearch",
  "productSearch",
]
const state = {
  aliveCompontent: initialCompontent,
  deadCompontent: [],
}
const mutations = {
  SET_ALIVE_COMPONTENT(state, data) {
    state.aliveCompontent = data
  },
  SET_DEAD_COMPONTENT(state, data) {
    state.deadCompontent = data
  },
  resetAliveCompontent(state) {
    state.aliveCompontent = initialCompontent
    state.deadCompontent = []
  },
  clearAliveCompontent(state) {
    state.aliveCompontent = []
    state.deadCompontent = []
  },
}
const getters = {}
const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
