import i18n from "../locales/index"
/**
 * 设备称重量过滤
 * @param {String, Number} weight
 * @returns {String, Number}
 */
export function scaleWeightFilter(weight) {
  let w = weight
  if (/-/g.test(w)) {
    try {
      let after = w.split(".")[1]
      if (!after) {
        after = 0
      }
      w = `-0.${after}`
      if (w === "-0.0") {
        w = "0.0"
      }
    } catch (err) {
      console.log("split报错了")
    }
  }
  return w
}

/**
 *
 *
 * 保留几位小数
 * @param {*} value
 * @param {number} [digit=2] 保留小数的位数
 * @returns {string} 保留后的数
 */
export function decimal(value, digit = 2) {
  const f = parseFloat(value)
  if (isNaN(f)) {
    return ""
  }
  let str = f.toString()
  const index = str.indexOf(".")
  if (index < 0) {
    str += "."
    while (digit > 0) {
      str += "0"
      digit--
    }
    return str
  }
  const strArr = str.split(".")
  let tail = strArr[1]
  const head = strArr[0]

  tail = tail.slice(0, digit)
  let len = tail.length
  while (len !== digit) {
    tail += "0"
    len++
  }
  return head + "." + tail
}
