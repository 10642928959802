import VueI18n from "vue-i18n"
import Vue from "vue"
import zh from "./zh.json"
import ft from "./ft.json"
import en from "./en.json"

Vue.use(VueI18n)

export default new VueI18n({
  locale: localStorage.getItem("language") || "zh", // 从localStorage中获取 默认英文
  messages: {
    zh,
    ft,
    en,
  },
})
