import i18n from "../locales/index"
export default {
  usedScore: (state) => {
    const rate = parseFloat(localStorage.getItem("customer_score_rate"))
    let score = 0
    let amount = 0
    if (state.memberInfo?.id) {
      const scoreTotal = state.memberInfo.score
      amount = Math.floor(scoreTotal * rate)
      if (amount !== 0 && rate !== 0) {
        score = amount / rate
      }
    }
    return {
      score,
      amount,
    }
  },
}
