import i18n from "../../locales/index"
import axios from "@/libs/axios.js"
import restful from "../vuex"

const state = {
  ...restful.states("takeout_order"),
  takeout_platform_status: false,
  code: "",
  finished: false,
}

const getters = {
  ...restful.getters("takeout_order"),
  finished: (state) => state.finished,
}

const actions = {
  ...restful.actions("takeout_orders"),
  async getOrderList(context, params) {
    const { dispatch, state, commit, getters } = context
    const { query, code } = state

    const path = `/takeout_orders`
    const final_params = Object.assign(query, params)
    const response = await axios.get(path, { params: final_params })
    if (query.page === 1) {
      commit("setRecord", response.data)
    } else {
      commit("setRecord", state.takeout_orders.concat(response.data))
    }
    if (response.meta) commit("setMeta", response.meta)
    if (getters.meta.current * getters.meta.pageSize >= getters.meta.total) {
      commit("finished", true)
    } else {
      commit("finished", false)
    }
    commit("loading", false)
  },
  async getUsbUnprintedTakeoutOrders({ dispatch }, params) {
    console.log("getUsbUnprintedTakeoutOrders调用")
    const { data: idList } = await axios.get(
      `takeout_orders/usb_unprinted_takeout_orders`,
      { params }
    )
    const len = idList.length
    console.log("idList", idList)
    if (len <= 0) return
    let count = 1
    async function print() {
      await dispatch(
        "printer_config/print",
        {
          takeout_order_id: idList[count - 1],
          category: "takeout",
        },
        { root: true }
      )
      await dispatch("noticePrinted", { id: idList[count - 1] })
      count++
      if (count <= len) {
        print()
      }
    }
    await print()
  },
  async noticePrinted(context, params) {
    console.log("noticePrinted调用")
    await axios.post(`/takeout_orders/${params.id}/printed`)
  },
}

const mutations = {
  ...restful.mutations("takeout_orders"),
  finished(state, boolean) {
    state.finished = boolean
  },
  code(state, text) {
    state.code = text
  },
  SET_TAKEOUT_PLATFORM_STATUS(state, data) {
    state.takeout_platform_status = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
