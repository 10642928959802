import i18n from "../../locales/index"
import restful from "../vuex"

const state = {
  ...restful.states("banner"),
}

const getters = {
  ...restful.getters("banner"),
}

const actions = {
  ...restful.actions("banners"),
}

const mutations = {
  ...restful.mutations("banner"),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
