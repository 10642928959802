import i18n from "../locales/index"
import { productInfoByCode } from "@/api/product"
import { analysisArticleNumber, keepDecimal } from "@/utils"
import { divide } from "@/utils/math"
import { startSyncData } from "@/utils/appInterface/offline"

export default {
  data() {
    return {}
  },
  mounted() {
    startSyncData()
  },
  activated() {},
  computed: {},
  deactivated() {},
  methods: {
    // data 扫码的数据
    scanCodeSearch(data) {
      console.log("扫码成功home")
      const dataStr = data.toString()
      const head = dataStr.substring(0, 2)
      console.log("head", head)
      console.log("dataStr.length", dataStr.length)
      const shop_code = localStorage.getItem("shop_code")
      let isHuohao = false
      if (shop_code) {
        if (shop_code === head) {
          isHuohao = true
        }
      } else {
        isHuohao = /^2[0-9]$/.test(head)
      }
      if (isHuohao) {
        // 货号
        const analysisInfo = analysisArticleNumber(dataStr)
        productInfoByCode({
          code: analysisInfo.code,
        }).then(({ data }) => {
          let prePkgPrice = 0
          if (analysisInfo.type === "p") {
            const total = parseFloat(
              analysisInfo.priceOrWeight.substr(0, 3) +
                "." +
                analysisInfo.priceOrWeight.substr(3, 2)
            )
            prePkgPrice = total
            if (data.need_to_weight) {
              analysisInfo.count = keepDecimal(
                divide(total * 1000, data.lingshoujia) / 1000,
                3
              )
              console.log("p_total", analysisInfo.priceOrWeight, total)
              console.log("p_analysisInfo.count", analysisInfo.count)
            } else {
              analysisInfo.count = 1
              data.lingshoujia = total
            }
          } else if (analysisInfo.type === "w") {
            if (data.need_to_weight) {
              analysisInfo.count = parseFloat(
                analysisInfo.priceOrWeight.substr(0, 2) +
                  "." +
                  analysisInfo.priceOrWeight.substr(2, 3)
              )
              console.log("w_analysisInfo.count", analysisInfo.count)
            } else {
              this.$message.warning(i18n.t("minxins_commonmixins_225"))
            }
          }
          if (!data.id) {
            this.$message.warning(i18n.t("minxins_commonmixins_226"))
            return
          }
          this.selectProduct({
            ...data,
            count: analysisInfo.count,
            isPrePkgGood: true,
            prePkgGoodType: analysisInfo.type,
            prePkgPrice,
          })
        })
      } else {
        // 条码
        this.SET_FAST_SEARCH_VALUE(data)
        this.searchProductTit("scan")
      }
    },
  },
}
