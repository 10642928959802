import i18n from "../../../locales/index"
/**
 * 开启副屏
 */
export function secondScreenShow() {
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      console.log("windows 开启副屏方法调用")

      ipcRenderer.send("secondScreenShow")
    }
  }
}
/**
 * 关闭副屏
 */
export function secondScreenClose() {
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      console.log("windows 关闭副屏方法调用")

      ipcRenderer.send("secondScreenClose")
    }
  }
}
/**
 * 副屏传数据
 */
export function setSecondaryScreenDisplay(data) {
  console.log("向副屏推送数据", data)
  if (window.require) {
    const { ipcRenderer } = window.require("electron")
    if (ipcRenderer) {
      ipcRenderer.send("secondScreenDataTransfer", JSON.stringify(data))
    }
  }
}
