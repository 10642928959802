import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import Vconsole from "vconsole";
import FastClick from "fastclick";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import LongPress from "./plugins/longPress.js";
import babyPlugin from "@/components/baby-ui/plugin";
import { touch, optionalChaining, disableCope } from "@/utils";
import * as filters from "@/utils/filters"; // global filters
import lodash from "lodash";
import * as globalVar from "@/utils/globalVar";
import sckey from "@/utils/shortcutkey.js";
import "@/libs/axios";
import directives from "@/directives";
import elementPlugin from "@/plugins/element.js";
import commonComponents from "@/components/common";
import i18n from "./locales/index";
import { changeOffline } from "@/utils/appInterface/offline";
// import { changeOffline } from '@/utils/appInterface/offline';
import { initOrderSync } from "@/worker/orderSync.js";
// import { initOrderSync } from '@/worker/orderSync.js';
// 项目共用指令
Vue.use(directives);
// element UI 按需引入
Vue.use(elementPlugin);
// 跨项目的自己的UI组件库
Vue.use(babyPlugin);
// 项目全局组件
Vue.use(commonComponents);
// 初始化同步订单
initOrderSync();
Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});
Vue.prototype.customevent = touch();
FastClick.attach(document.body);
if (process.env.VUE_APP_ENV === "development" ||
    process.env.VUE_APP_ENV === "staging") {
    const vConsole = new Vconsole();
    Vue.use(vConsole);
}
Vue.use(VueAxios, axios);
Vue.use(LongPress, {
    time: 1500,
    isHideDefaultMenu: true, // 隐藏长按菜单
});
Vue.config.productionTip = false;
Vue.prototype.$store = store;
Vue.prototype.$$ = optionalChaining;
Vue.prototype._ = lodash;
Vue.prototype.sckey = sckey;
Object.keys(globalVar).forEach((key) => {
    Vue.prototype[key] = globalVar[key];
});
window.toChangeOffline = (val) => {
    // 全局切换离线模式
    const result = changeOffline(val);
    if (result === true) {
        localStorage.setItem("isOffline", val ? "1" : "0");
        // window.location.reload();
    }
};
// 禁用复制
disableCope();
new Vue({
    el: "#app",
    router,
    store,
    i18n,
    beforeCreate() {
        Vue.prototype.APP_NAME = process.env.VUE_APP_NAME;
        Vue.prototype.APP_NAME_ALL = i18n.t("main_224", [process.env.VUE_APP_NAME]);
    },
    render: (h) => h(App),
});
