import i18n from "../locales/index"
import hotkeys from "hotkeys-js"
import { openCashBox } from "@/utils/appInterface/jsbridge"
import router from "@/router"
const scName = {
  scExplain: "f1",
  scEmptyCart: "ctrl+z",
  scPopDrawer: "f7",
  scCollection: "enter",
  scSettlement: "space",
  scReturn: "esc",
}
/**
 * @description: 快捷键说明
 * @param {*}
 * @return {*}
 */
function scExplain(fn) {
  return hotkeys(scName.scExplain, function (event) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    fn()
    console.log("scExplain 快捷键说明")
  })
}
/**
 * @description: 清空收银列表
 * @param {*}
 * @return {*}
 */
function scEmptyCart(fn) {
  return hotkeys(scName.scEmptyCart, function (event) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    fn()
    console.log("scEmptyCart 清空收银列表")
  })
}
/**
 * @description: 弹钱箱
 * @param {*}
 * @return {*}
 */
function scPopDrawer() {
  return hotkeys(scName.scPopDrawer, function (event) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    openCashBox()
    console.log("scPopDrawer 弹钱箱")
  })
}
/**
 * @description: 收款并打小票
 * @param {*}
 * @return {*}
 */
function scCollection(fn, type) {
  if (type === "unbind") {
    hotkeys.unbind(scName.scCollection) // 解除绑定
    console.log("scCollection 收款并打小票 unbind")
    return
  }
  hotkeys(scName.scCollection, function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    fn()
    console.log("scCollection 收款并打小票 bind")
  })
}
/**
 * @description: 收银
 * @param {*}
 * @return {*}
 */
function scSettlement(fn) {
  return hotkeys(scName.scSettlement, function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    fn()
    console.log("scSettlement 收银")
  })
}
/**
 * @description: 返回上一个页面
 * @param {*}
 * @return {*}
 */
function scReturn() {
  return new Promise((resovle) => {
    hotkeys(scName.scReturn, function (event, handler) {
      // Prevent the default refresh event under WINDOWS system
      event.preventDefault()
      router.go(-1)
      console.log("scReturn 返回上一个页面")
    })
  })
}

export default {
  scExplain,
  scEmptyCart,
  scPopDrawer,
  scCollection,
  scSettlement,
  scReturn,
}
