import i18n from "../../locales/index"
import { mapState, mapMutations, mapActions } from "vuex"
import commonMixins from "@/minxins/commonMixins"
export default {
  mixins: [commonMixins],
  computed: {
    ...mapState("product", [
      "products",
      "searchProducts",
      "showFastSearch",
      "fastSearchValue",
    ]),
  },
  methods: {
    ...mapMutations("product", [
      "SET_SEARCH_PRODUCTS",
      "SET_FAST_SEARCH_VALUE",
      "SET_SHOW_FAST_SEARCH",
    ]),
    ...mapActions("product", ["productList"]),
    // 修改搜索值
    changeSearchValue(value) {
      this.SET_FAST_SEARCH_VALUE(value)
      this.prepareToSearch()
    },
    // 准备去搜索
    prepareToSearch(type) {
      if (this.fastSearchValue && this.fastSearchValue.length === 13) {
        // 13位码 处理货号
        this.scanCodeSearch(this.fastSearchValue)
      } else {
        this.queryProduct(type)
      }
    },
    // 查询商品
    queryProduct(type) {
      if (!this.fastSearchValue) {
        this.SET_SEARCH_PRODUCTS([])
        return
      }
      if (this.searchTitTimer) {
        clearTimeout(this.searchTitTimer)
      }
      this.searchTitTimer = setTimeout(() => {
        this.productList({
          page: 1,
          per: this.thingsSize,
          query: this.fastSearchValue,
        }).then(({ data }) => {
          this.SET_SEARCH_PRODUCTS(data)
          if (type === "scan") {
            this.confirmSearchProduct()
          }
        })
      }, 200)
    },
  },
}
