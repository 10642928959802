import i18n from "../locales/index"
import request from "@/utils/request"
const baseurl = process.env.VUE_APP_BASE_URL

export function productList(query) {
  return request({
    url: baseurl + "/products",
    method: "get",
    params: query,
  })
}
export function productCreate(data) {
  return request({
    url: baseurl + "/products",
    method: "post",
    data,
  })
}
export function productUpdate({ id, ...body }) {
  return request({
    url: baseurl + `/products/${id}`,
    method: "put",
    data: body,
  })
}

export function productUnitsList(data) {
  return request({
    url: baseurl + "/units",
    method: "get",
    params: data,
  })
}

// 商品详情
export function productDetail(data) {
  return request({
    url: baseurl + `/products/${data.id}`,
    method: "get",
    params: data,
  })
}
// 生成条码
export function productInfoByCode(data) {
  return request({
    url: baseurl + "/products/info_by_code",
    method: "get",
    params: data,
  })
}
// 分析包装码
export function productInfoByBarCode(data) {
  return request({
    url: baseurl + "/products/info_by_bar_code",
    method: "get",
    params: data,
  })
}
// 创建产品单位
export function productUnitsCreate(data) {
  return request({
    url: baseurl + "/units",
    method: "post",
    data,
  })
}
// 商品规格名称列表
export function productPropertyNames() {
  return request({
    url: baseurl + "/mall_property_names",
    method: "get",
  })
}
// 获取商品规格属性列表
export function productPropertyValues(data) {
  return request({
    url: baseurl + "/mall_property_values",
    method: "get",
    params: data,
  })
}
// 估清
export function productEstimate({ id, ...body }) {
  return request({
    url: baseurl + `/products/${id}/estimate`,
    method: "post",
    data: body,
  })
}
// 下单或退单调整估清额
export function productAdjustEstimate({ id, ...body }) {
  return request({
    url: baseurl + `/products/${id}/adjust_estimate`,
    method: "post",
    data: body,
  })
}
// 批量下单或退单调整估清额
export function productBatchAdjustEstimate({ id, ...body }) {
  return request({
    url: baseurl + `/products/batch_adjust_estimate`,
    method: "post",
    data: body,
  })
}
