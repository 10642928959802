import i18n from "../locales/index"
// 放各种工具类,方法

import OSS from "ali-oss"
/**
 * 创建一个OSS对象
 */
export const ossClient = (conf) => new OSS(conf)

export function touch() {
  return "touchstart" in window ? "touchstart" : "click"
}
/**
 * 判断系统类型
 */
export function env() {
  const agent = window.navigator.userAgent.toLowerCase()
  console.log("agent", agent)
  if (/win32|wow32|windows|win64|wow64/.test(agent)) {
    return "windows"
  }
  if (/android|linux/.test(agent)) {
    return "android"
  }
}
/**
 * 检测输入的数据合法, 只有一个小数点, 输入最多到分
 * @param {String}  data
 * @returns {Boolean}
 */
export function checkDataLegitimate(d) {
  const data = String(d)
  if (/^\./.test(data)) {
    return false
  }
  const dot = data.toString().match(/\./g)
  if (!dot) {
    return true
  }
  if (dot && dot.length > 1) {
    // 如果输入不只一个小数点. 就返回
    return false
  }
  if (dot && dot.length === 1) {
    // 如果输入有一个小数点
    const index = data.indexOf(".")
    const subDtLen = data.substring(index).length

    if (subDtLen > 3) {
      // 多出两位小数的话, 就返回
      return false
    }
  }
  return true
}
/**
 * 获得抹零位数
 * @returns {string | null}
 */
export function getDigit() {
  if (
    !localStorage.getItem("small_discount") ||
    localStorage.getItem("small_discount") === "0"
  ) {
    return null
  }
  return localStorage.getItem("small_change_discount_digit")
}
/**
 * 设置保留的小数点
 * @param {(Object|string|number)} num
 * @param {number} digit
 * @returns {number}
 */
export function keepDecimal(num, digit) {
  if (/\.0$/.test(num) && (digit === 2 || digit === 1)) return num // *.0这种情况, 并且保留1位或者两位小数时
  if (/\.[0-9]0$/.test(num) && digit === 2) return num // .*0显示, 这种情况, 并且两位小数时
  num = parseFloat(num)
  const multiple = Math.pow(10, digit)
  return Math.floor(num * multiple) / multiple
}
/**
 * 设置四舍五入保留的小数点
 * @param {(Object|string|number)} num
 * @param {number} digit
 * @returns {number}
 */
export function keepRoundDecimal(num, digit) {
  if (/\.0|\.[0-9]0/.test(num) && digit === 2) return num // fix 0.0显示
  if (/\.0$/.test(num) && digit === 1) return num // fix 0.0显示
  num = parseFloat(num)
  const multiple = Math.pow(10, digit)
  return Math.round(num * multiple) / multiple
}
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}"
  let date
  if (typeof time === "object") {
    date = time
  } else {
    if (typeof time === "string") {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), "/")
      }
    }

    if (typeof time === "number" && time.toString().length === 10) {
      time *= 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return [
        i18n.t("utils_index_904"),
        i18n.t("utils_index_905"),
        i18n.t("pages_agreement_privacy_303"),
        i18n.t("pages_agreement_privacy_330"),
        i18n.t("pages_agreement_privacy_337"),
        i18n.t("pages_agreement_privacy_359"),
        i18n.t("pages_agreement_privacy_361"),
      ][value]
    }
    return value.toString().padStart(2, "0")
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (`${time}`.length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return i18n.t("utils_index_906")
  }
  if (diff < 3600) {
    // less 1 hour
    return i18n.t("utils_index_907", [Math.ceil(diff / 60)])
  }
  if (diff < 3600 * 24) {
    return i18n.t("utils_index_908", [Math.ceil(diff / 3600)])
  }
  if (diff < 3600 * 24 * 2) {
    return i18n.t("utils_index_909")
  }
  if (option) {
    return parseTime(time, option)
  }
  return `${
    d.getMonth() + 1
  }月${d.getDate()}日${d.getHours()}时${d.getMinutes()}分`
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf("?") + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (let i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xdc00 && code <= 0xdfff) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ""
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return ""
      return `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
    })
  ).join("&")
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split("?")[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    `{"${decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, " ")}"}`
  )
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement("div")
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== "object") {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === "object") {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += `${className}`
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === "start") {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  }
  return new Date(new Date().toDateString())
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout
  let args
  let context
  let timestamp
  let result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone")
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = `${+new Date()}`
  const randomNum = `${parseInt((1 + Math.random()) * 65536)}`
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp(`(\\s|^)${cls}(\\s|$)`))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ` ${cls}`
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp(`(\\s|^)${cls}(\\s|$)`)
    ele.className = ele.className.replace(reg, " ")
  }
}

/**
 * 解决Vue Template模板中无法使用可选链的问题
 * @param obj
 * @param rest
 * @returns {*}
 */
export const optionalChaining = (obj, ...rest) => {
  let tmp = obj
  for (const key in rest) {
    const name = rest[key]
    tmp = tmp?.[name]
  }
  return tmp || ""
}

/**
 * 格式化副屏货物数据
 * @param cartlist
 * @returns {Array}
 */
export const subScreenCargoFormat = (cartlist) => {
  //
  const list = cartlist.map((item, index) => {
    const projuct = {
      serialNumber: index + 1,
      title: item.name,
      price: `￥${parseFloat(item.lingshoujia).toFixed(2)}`,
      count: item.count + item.unit,
      subTotal: `￥${(item.count * item.lingshoujia).toFixed(2)}`,
      thumbnailFileUrl: item.image,
    }
    return projuct
  })
  return list
}

/**
 * 格式化提交订单商品数据
 * @param cartlist
 * @returns {Array}
 */
export const submitOrderCargoFormat = (cartlist) => {
  const list = cartlist.map((item) => {
    const product = {
      current_product_name: item.name,
      product_id: item.id,
      quantity: item.count,
      current_price: parseFloat(item.current_price) || item.lingshoujia,
      fresh_material_fresh_order_items_attributes:
        item.fresh_material_fresh_order_items_attributes || [],
      preferences: item.preferences || [],
    }
    if (item.mall_sku_id != null) {
      product.mall_sku_id = item.mall_sku_id
    }
    return product
  })
  return list
}
/**
 * 格式化更新订单商品数据
 * @param cartlist
 * @returns {Array}
 */
export const updateOrderCargoFormat = (cartlist) => {
  return cartlist.map((item, index) => ({
    unique: index + 1,
    name: item.current_product_name,
    id: item.fresh_product_id,
    count: item.quantity,
    current_price: item.current_price,
    fresh_material_fresh_products: item.fresh_material_fresh_order_items,
    preferences: item.preferences,
    mall_sku_id: item.mall_sku_id,
    mall_skus: item.mall_skus,
    checkedMaterials: item.checkedMaterials,
    selectedProperty: item.selectedProperty,
    huiyuanjia: item.fresh_product.huiyuanjia,
    lingshoujia: item.fresh_product.lingshoujia,
    isPrePkgGood: false,
    prePkgPrice: null,
    prePkgGoodType: "",
    image: item.fresh_product_image,
    unit: item.fresh_product_unit,
    fresh_category: item.fresh_product.fresh_category,
    fresh_product_customer_levels:
      item.fresh_product.fresh_product_customer_levels,
    discount: item.fresh_product.discount,
    fresh_product_preferences: item.fresh_product.fresh_product_preferences,
    need_to_weight: item.fresh_product.need_to_weight,
    stocks: item.fresh_product.stocks,
    open_estimation: item.open_estimation,
  }))
}

/**
 * 解析货号
 * @param {*} articleNumber 货号
 */
export function analysisArticleNumber(articleNumber) {
  const rule = localStorage.getItem("articleNumberRule") // DD-IIIII-WW.WWW-C
  let finish_total = 0
  let weight = 0
  let type = ""
  const code = articleNumber.substr(2, 5)
  const priceOrWeight = articleNumber.substr(7, 5)
  switch (rule) {
    case "DD-IIIII-PPPPP-C":
      finish_total = parseFloat(priceOrWeight)
      weight = 0
      type = "p"
      break
    case "DD-IIIII-WW.WWW-C":
      finish_total = 0
      weight = parseFloat(priceOrWeight)
      type = "w"
      break
    default:
      finish_total = 0
      weight = parseFloat(priceOrWeight)
      type = "p"
  }
  return {
    code,
    priceOrWeight,
    type,
  }
}
/**
 * Utf8ToGb2312
 * @param {*} articleNumber 货号
 */
export function utf8ToGb2312(str) {
  var substr = ""
  var a = ""
  var b = ""
  var c = ""
  var i = -1
  i = str.indexOf("%")
  if (i == -1) {
    return str
  }
  while (i != -1) {
    if (i < 3) {
      substr = substr + str.substr(0, i - 1)
      str = str.substr(i + 1, str.length - i)
      a = str.substr(0, 2)
      str = str.substr(2, str.length - 2)
      if (parseInt("0x" + a) & (0x80 === 0)) {
        substr = substr + String.fromCharCode(parseInt("0x" + a))
      } else if (parseInt("0x" + a) & (0xe0 === 0xc0)) {
        // two byte
        b = str.substr(1, 2)
        str = str.substr(3, str.length - 3)
        let widechar = (parseInt("0x" + a) & 0x1f) << 6
        widechar = widechar | (parseInt("0x" + b) & 0x3f)
        substr = substr + String.fromCharCode(widechar)
      } else {
        b = str.substr(1, 2)
        str = str.substr(3, str.length - 3)
        c = str.substr(1, 2)
        str = str.substr(3, str.length - 3)
        let widechar = (parseInt("0x" + a) & 0x0f) << 12
        widechar = widechar | ((parseInt("0x" + b) & 0x3f) << 6)
        widechar = widechar | (parseInt("0x" + c) & 0x3f)
        substr = substr + String.fromCharCode(widechar)
      }
    } else {
      substr = substr + str.substring(0, i)
      str = str.substring(i)
    }
    i = str.indexOf("%")
  }

  return substr + str
}
/**
 * 格式化提交订单商品数据
 * @param start 开始时间
 * @param end 结束时间
 * @param current 当前时间
 * @returns {Boolean}
 */
export function isItInTimeInterval(start, end, current) {
  const st = new Date(start).getTime()
  const et = new Date(end).getTime()
  const ct = new Date(current).getTime()
  return ct >= st && ct <= et
}
/**
 * 请求默认消息提示
 * @param code 状态码
 * @returns {String}
 */
export function getResMsg(code) {
  const msgMap = new Map([
    [400, i18n.t("utils_index_910")],
    [401, i18n.t("utils_index_911")],
    [403, i18n.t("utils_index_912")],
    [404, i18n.t("utils_index_913")],
    [408, i18n.t("utils_index_914")],
    [422, i18n.t("utils_index_915")],
    [500, i18n.t("utils_index_916")],
    [501, i18n.t("utils_index_917")],
    [502, i18n.t("utils_index_918")],
    [503, i18n.t("utils_index_919")],
    [504, i18n.t("utils_index_920")],
    [505, i18n.t("utils_index_921")],
  ])
  return msgMap.get(code)
}
/**
 * 是否是字符串类型
 *
 * @param {string} s
 * @returns {boolean}
 */
function isString(s) {
  return typeof s === "string"
}
/**
 * 将版本号字符串拆分为数组
 *
 * @param {string} version 1.0.0
 * @returns {Array} [1, 0, 0]
 */
export function getVersionNumber(version) {
  if (!version || !isString(version)) return []
  return version.split(".").map((n) => Number(n))
}

/**
 * 比较版本号
 *
 * @export
 * @param {string} version1
 * @param {string} version2
 * @returns {number} | -1 | 0 | -1 |
 */
export function compareVersion(version1, version2) {
  const v1 = getVersionNumber(version1)
  const v2 = getVersionNumber(version2)
  const max = Math.max(v1.length, v2.length)
  let i = 0
  while (i < max) {
    if (v1[i] > v2[i]) {
      return 1
    } else if (v1[i] < v2[i]) {
      return -1
    }
    i++
  }
  return 0
}
/**
 * 页面禁止复制
 */
export function disableCope() {
  const envname = env()
  if (envname === "windows") {
    // 使右键和复制失效
    document.oncontextmenu = new Function("event.returnValue=false")
    document.onselectstart = new Function("event.returnValue=false")
  } else if (envname === "android") {
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault()
    })
  }
}
export function transformBoolean(boo) {
  if (typeof boo === "boolean") {
    return boo ? "1" : "0"
  }
  if (boo === "true") {
    return "1"
  }
  if (boo === "false") {
    return "0"
  }
  return boo
}
/**
 * 获取字符串实际长度
 * @param {*} str
 * @return number
 */
export function strlen(str) {
  var len = 0
  for (var i = 0; i < str.length; i++) {
    var c = str.charCodeAt(i)
    // 单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
      len++
    } else {
      len += 2
    }
  }
  return len
}
/**
 * 设置本地存储
 * @param {*} key
 * @param {*} value
 */
export function localSave(key, value) {
  localStorage.setItem(key, value)
}
/**
 * 获取本地存储
 * @param {*} key
 * @param {*} value
 */
export function localRead(key) {
  return localStorage.getItem(key) || ""
}
/**
 * 获取打印机模式
 * @param {obj} printer
 */
export function getPrinterMode(printer) {
  if (printer?.printer_receipt_configs_attributes) {
    if (printer?.printer_receipt_configs_attributes.length > 0) {
      return printer?.printer_receipt_configs_attributes[0]?.mode
    }
  }
  if (printer?.printer_receipt_configs) {
    if (printer?.printer_receipt_configs.length > 0) {
      return printer?.printer_receipt_configs[0]?.mode
    }
  }
  return ""
}
// 判断打印模式core
function judgMode(payment_mode, scene) {
  if (
    (payment_mode === "eat_first" && scene !== "print_after_create") ||
    (payment_mode === "pay_first" && scene !== "print_after_pay")
  ) {
    return false
  }
  return true
}
/**
 *
 * @param {*} order_mode 订单模式
 * @param {*} desk_payment_mode 桌台打印模式
 * @param {*} pickup_payment_mode 快餐打印模式
 * @param {*} scene 打印触发时间点
 * @param {*} hasdesk 订单有桌子没
 * @return {Boolean} 是否有效
 */
export function isValidPaymentMode(
  order_mode,
  desk_payment_mode,
  pickup_payment_mode,
  scene,
  hasdesk
) {
  switch (order_mode) {
    case "desk":
      return judgMode(desk_payment_mode, scene)
    case "pickup":
      return judgMode(pickup_payment_mode, scene)
    case "mixin":
      return judgMode(hasdesk ? desk_payment_mode : pickup_payment_mode, scene)
  }
}
