import i18n from "../locales/index"
export default {
  SET_GLOADING(state, gLoading) {
    if (!gLoading) {
      setTimeout(() => {
        state.gLoading = gLoading
      }, 1000)
    } else {
      state.gLoading = gLoading
    }
  },
  SET_FETCH_ORDER_DATA(state, data) {
    state.fetchOrderData = {}
    state.fetchOrderData = data
  },
  SEt_SECOND_SCREEN_SETTING(state, data) {
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        state.secondaryScreenData.setting[key] = data[key]
      }
    })
  },
  CLEAR_SECOND_SCREEN_DATA(state) {
    state.secondaryScreenData = {
      cartlist: [],
      total: 0,
      change_amount: 0,
      collection_amount: 0,
      isDisplayChange: false,
      setting: {
        screenType: localStorage.getItem("second_screen_type"),
        alwayShowMedia: localStorage.getItem("alwayShowMedia"),
        fixedOrder: localStorage.getItem("fixedOrder"),
        swicthTime: localStorage.getItem("swicthTime"),
      },
    }
  },
  SET_SECOND_SCREEN_DATA(state, data) {
    Object.keys(data).forEach((key) => {
      if (
        data[key] ||
        (key === "isDisplayChange" && String(data[key]) !== "undefined")
      ) {
        state.secondaryScreenData[key] = data[key]
      }
    })
  },
  SET_MEMBER_INFO(state, data) {
    state.memberInfo = data
  },
  UPDATE_ORDER(state, data) {
    if (data.orderNo) {
      state.order.orderNo = data.orderNo
      if (data.orderNo === "cl") {
        state.order.orderNo = ""
      }
    }
    if (data.totalPrice) {
      state.order.totalPrice = data.totalPrice
      if (data.totalPrice === "cl") {
        state.order.totalPrice = 0
      }
    }
    if (data.discountedTotal) {
      state.order.discountedTotal = data.discountedTotal
      if (data.discountedTotal === "cl") {
        state.order.discountedTotal = 0
      }
    }
    if (data.discountValue) {
      state.order.discountValue = data.discountValue
      if (data.discountValue === "cl") {
        state.order.discountValue = ""
      }
    }
    if (data.realAmount) {
      state.order.realAmount = data.realAmount
      if (data.realAmount === "cl") {
        state.order.realAmount = 0
      }
    }
    if (data.changeAmount) {
      state.order.changeAmount = data.changeAmount
      if (data.changeAmount === "cl") {
        state.order.changeAmount = 0
      }
    }
    if (data.smallDiscount) {
      state.order.smallDiscount = data.smallDiscount
      if (data.smallDiscount === "false") {
        state.order.smallDiscount = false
      }
    }
    if (data.customerId) {
      state.order.customerId = data.customerId
    }
    if (data.order_items) {
      state.order.order_items = data.order_items
    }
  },
  SET_SUCCESSION_DETAIL(state, data) {
    state.successionDetailData = data
  },
  SET_ticket_qr_show(state, data) {
    state.ticket_qr_show = data
  },
  SET_pickup_code_show(state, data) {
    state.pickup_code_show = data
  },
  SET_pickup_code_alias_name(state, data) {
    state.pickup_code_alias_name = data
  },
  SET_SETTINGS(state, data) {
    state.settings = data
  },
  SET_small_discount(state, data) {
    state.small_discount = data
  },
  SET_MERGE_SAME_GOOD(state, data) {
    state.mergeSameGood = data
  },
  SET_MEMBER_DISCOUNT(state, data) {
    state.memberDiscount = data
  },
  SET_PREPKG_CHANGE_PRICE(state, data) {
    state.prepkgChangePrice = data
  },
  SET_PETTY_CASH(state, data) {
    state.isPettyCash = data
  },
  SET_small_change_discount_digit(state, data) {
    state.small_change_discount_digit = data
  },
  SET_receipt_width(state, data) {
    state.receipt_width = data
  },
  SET_user_says(state, data) {
    state.user_says = data
  },
  SET_second_screen(state, data) {
    state.second_screen = data
  },
  SET_exchange_receipts(state, data) {
    state.exchange_receipts = data
  },
  SET_cashing_receipts(state, data) {
    state.cashing_receipts = data
  },
  SET_auto_close_estimation(state, data) {
    state.auto_close_estimation = data
  },
}
