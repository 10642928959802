import i18n from "../../locales/index"
import { getOemInfo } from "@/api/oem"
import { oemName } from "@/utils/appInterface/android_jsbridge"
import { env } from "@/utils"

let localOemInfo = localStorage.getItem("oemInfo")
if (localOemInfo) {
  try {
    localOemInfo = JSON.parse(localOemInfo)
  } catch (err) {
    console.log(err)
    localStorage.setItem("oemInfo", "")
  }
}
const defaultOemInfo = {
  name: i18n.t("main_224", [process.env.VUE_APP_NAME]),
  icon: require(process.env.VUE_APP_LOGO),
  copyright: i18n.t("store_modules_oem_850"),
  product_name: i18n.t("main_224", [process.env.VUE_APP_NAME]),
  contact_mobile: "15921215626",
  contact_name: "wck2",
}
const state = {
  oemInfo: localOemInfo || defaultOemInfo, // OEM信息
}

const mutations = {
  SET_OEM_INFO: (state, data) => {
    state.oemInfo = data
  },
}

const getters = {}

const actions = {
  getOemInfo({ commit }) {
    const envName = env()
    let name = i18n.t("main_224", [process.env.VUE_APP_NAME])
    if (envName === "windows") {
      if (/\?oem_name=/.test(window.location.href)) {
        name = decodeURIComponent(
          window.location.href.split("?oem_name=")[1].split("#")[0]
        )
      }
    } else if (envName === "android") {
      if (oemName()) {
        name = oemName()
      }
    } else {
      if (/\?oem_name=/.test(window.location.href)) {
        name = decodeURIComponent(
          window.location.href.split("?oem_name=")[1].split("#")[0]
        )
      }
    }
    return new Promise((resolve, reject) => {
      getOemInfo({
        name,
      })
        .then(({ data }) => {
          if (data) {
            commit("SET_OEM_INFO", data)
            localStorage.setItem("oemInfo", JSON.stringify(data))
          }
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
