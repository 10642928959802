import i18n from "../locales/index"
import { orderStatus } from "@/libs/value-list.js"

export default function ownerless(Vue) {
  Vue.directive("order_status", {
    bind: function (el, binding) {
      const status = orderStatus.find((item) => item.value === binding.value)
      el.innerHTML = status.name
    },
  })
}
