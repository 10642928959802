import i18n from "../../locales/index"
// import restful from '../vuex';
import { generateBarCode } from "@/api/prepackaging"
import { printLabel } from "@/utils/appInterface/jsbridge"
import { Message } from "element-ui"

const state = {
  loading: false,
  product: {},
}

const getters = {
  loading: (state) => state.loading,
}

const mutations = {
  loading(state, boolean) {
    state.loading = boolean
  },
  product(state, obj) {
    state.product = obj
  },
}

const actions = {
  async generateBarCode({ commit, state }, params) {
    commit("loading", true)
    const { data } = await generateBarCode(params)
    commit("loading", false)
    if (!data) {
      return
    }
    printLabel(data.content, state.product.printNum)
    Message({
      message: i18n.t("pages_prepackaging_index_676"),
      type: "success",
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
