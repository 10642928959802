import i18n from "../locales/index"
import request from "@/utils/request"
const baseurl = process.env.VUE_APP_BASE_URL

export function indexOrder(params) {
  return request({
    url: baseurl + "/orders",
    method: "get",
    params,
  })
}

export function showOrder(id) {
  return request({
    url: baseurl + `/orders/${id}`,
    method: "get",
  })
}

export function createOrder(data) {
  return request({
    url: baseurl + "/orders",
    method: "post",
    data,
  })
}
export function updateOrder(params) {
  return request({
    url: baseurl + "/orders",
    method: "put",
    params,
  })
}

export function noticeGuadan(data) {
  return request({
    url: baseurl + "/orders/guadan",
    method: "post",
    data,
  })
}

export function ordersPrint(no) {
  return request({
    url:
      baseurl +
      `/orders/${no}/receipt_content?receipt_width=${localStorage.getItem(
        "receipt_width"
      )}`,
    method: "get",
  })
}

export function orderPay(id, data) {
  return request({
    url: baseurl + `/orders/${id}/pay`,
    method: "post",
    data,
  })
}

export function orderStatusQuery(id) {
  return request({
    url: baseurl + `/orders/${id}/query`,
    method: "get",
  })
}
// 取消订单支付
export function orderCancel(query) {
  return request({
    url: baseurl + `/orders/${query.id}/cancel`,
    method: "post",
  })
}
// 获取扫脸支付信息
export function orderFaceinfo(data) {
  return request({
    url: baseurl + "/orders/faceinfo",
    method: "post",
    data,
  })
}
