import i18n from "../locales/index"
import request from "@/utils/request"
const baseurl = process.env.VUE_APP_BASE_URL

export function billCreate(data) {
  return request({
    url: baseurl + "/bills",
    method: "post",
    data,
  })
}

export function billPay(data) {
  return request({
    url: baseurl + `/bills/${data.id}/pay`,
    method: "post",
    data: {
      payment_method: data.payment_method,
      auth_no: data.auth_no,
    },
  })
}

export function billQuery(data) {
  return request({
    url: baseurl + `/bills/${data.id}/query`,
    method: "get",
  })
}

export function billReceiptContent(data) {
  return request({
    url:
      baseurl +
      `/bills/${data.id}/receipt_content?receipt_width=${localStorage.getItem(
        "receipt_width"
      )}`,
    method: "get",
  })
}

// 取消会员支付
export function billCancel(query) {
  return request({
    url: baseurl + `/bills/${query.id}/cancel`,
    method: "post",
  })
}
