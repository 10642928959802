import i18n from "../locales/index"
import { offlineGetOrderList } from "@/utils/appInterface/offline"
import { setGlobleData, SyncOrder } from "@/worker/orderSyncMain.js"

let randomTime = null // 数据同步开始时间
export function initOrderSync() {
  console.log("初始化订单同步")
  setOrderSyncRandomTime()
}
// 设置随机同步时间
export function setOrderSyncRandomTime() {
  const list = offlineGetOrderList() ? offlineGetOrderList() : []
  if (list.length === 0) {
    return
  }
  if (!localStorage.getItem("randomOrderSyncTime")) {
    // 没有设置随机同步,就设置同步时间
    randomTime = new Date().getTime() + parseInt(Math.random() * 1000 * 60 * 60)
    localStorage.setItem("randomOrderSyncTime", randomTime)
  } else {
    randomTime = localStorage.getItem("randomOrderSyncTime")
  }
}
export function createOrderSync() {
  console.log("createOrderSync<=========")
  setOrderSyncRandomTime()
  if (new Date().getTime() > +randomTime) {
    manualOrderSync()
  } else {
    console.log("未到同步开始时间")
  }
}
// 手动订单同步
export function manualOrderSync(l = []) {
  console.log("manualOrderSync<=========")
  const list = offlineGetOrderList() ? offlineGetOrderList() : l
  if (list.length <= 0) return
  console.log("开始同步订单了《==========", list)

  doOrderSync(list)
}
// 做订单同步操作
function doOrderSync(list) {
  setGlobleData({
    authorization: localStorage.getItem("authorization"),
    shopId: localStorage.getItem("shop_id"),
    exchangesId: localStorage.getItem("exchangesId"),
  })
  const timestamp = "" + new Date().getTime()
  localStorage.setItem("syncOrderId", timestamp)
  new SyncOrder(list, timestamp)
}
