import i18n from "../locales/index"
const LongPress = {}
LongPress.install = function (
  Vue,
  options = {
    time: 2000,
    isHideDefaultMenu: true,
  }
) {
  // 2. 添加全局指令
  Vue.directive("longpress", {
    bind(el, binding, vNode) {
      if (typeof binding.value.mtd !== "function") {
        const compName = vNode.context.name

        let warn = "[longpress:] mtd is not afunction, but has to be "

        if (compName) {
          warn += `Found in component '${compName}' `
        }

        console.warn(warn)
      }

      // 定义变量

      let pressTimer = null

      // 定义函数处理程序

      // 创建计时器（ 1秒后执行函数 ）

      const start = (e) => {
        if (e.type === "click" && e.button !== 0) {
          return
        }

        if (pressTimer === null) {
          pressTimer = setTimeout(() => {
            // 执行函数

            handler()
          }, options.time)
        }
      }

      // 取消计时器

      const cancel = (e) => {
        // 检查计时器是否有值

        if (pressTimer !== null) {
          clearTimeout(pressTimer)

          pressTimer = null
        }
      }

      // 运行函数

      const handler = (e) => {
        // 执行传递给指令的方法

        binding.value.mtd(binding.value.data)
      }
      // 屏蔽长按菜单
      if (options.isHideDefaultMenu) {
        el.addEventListener("contextmenu", (e) => {
          e.preventDefault()
        })
      }
      // 添加事件监听器

      el.addEventListener("mousedown", start)

      el.addEventListener("touchstart", start)

      // 取消计时器
      el.addEventListener("click", cancel)

      el.addEventListener("mouseout", cancel)

      el.addEventListener("touchend", cancel)

      el.addEventListener("touchcancel", cancel)
    },
  })
}

export default LongPress
