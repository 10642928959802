import i18n from "../../../locales/index"
import Vue from "vue"
import * as ANDROID from "./displayAndroid"
import * as WINDOW from "./displayWindows"
import { env } from "@/utils"

const envName = env()
/**
 *
 * @param {*} show "1" 显示副屏 "0" 隐藏副屏
 * @param {*} type "1" 轮播图 "0" 视频
 * @param {*} alwayShowMedia "1" 显示轮播图 "0" 显示视频
 */
export function showSecondDisplay(show, type, alwayShowMedia) {
  if (envName === "android") {
    ANDROID.showSecondDisplay(show, type, alwayShowMedia)
  } else if (envName === "windows") {
    if (show) {
      WINDOW.secondScreenShow()
    } else {
      WINDOW.secondScreenClose()
    }
    Vue.prototype.$store.commit("SEt_SECOND_SCREEN_SETTING", {
      screenType: type, // "1" 轮播图 "0" 视频
      alwayShowMedia, // "1" 显示轮播图 "0" 显示视频
    })
    WINDOW.setSecondaryScreenDisplay(
      Vue.prototype.$store.state.secondaryScreenData
    )
  }
}
/**
 * 是否固定左侧订单区
 * @param {*} show "0" 否 “1”是
 */
export function fixSecondDisplayLeftArea(show) {
  if (envName === "android") {
    ANDROID.fixSecondDisplayLeftArea(show)
  } else if (envName === "windows") {
    Vue.prototype.$store.commit("SEt_SECOND_SCREEN_SETTING", {
      fixedOrder: show, // 否固定左侧订单区 "0" 否 “1”是
    })
    WINDOW.setSecondaryScreenDisplay(
      Vue.prototype.$store.state.secondaryScreenData
    )
  }
}
/**
 * 设置副屏轮播图切换播放时间
 * @export
 * @param {*} data time 单位是秒
 */
export function switchSecondDisplayBannerTime(data) {
  if (data === "0") data = "5"
  if (envName === "android") {
    ANDROID.switchSecondDisplayBannerTime(data)
  } else if (envName === "windows") {
    Vue.prototype.$store.commit("SEt_SECOND_SCREEN_SETTING", {
      swicthTime: data, // 轮播图切换播放时间 time 单位是秒
    })
    WINDOW.setSecondaryScreenDisplay(
      Vue.prototype.$store.state.secondaryScreenData
    )
  }
}
/**
 * 设置副屏数据
 * @param {*} data
 */
export function setSecondaryScreenDisplay(data) {
  if (envName === "android") {
    ANDROID.setSecondaryScreenDisplay(data)
  } else if (envName === "windows") {
    WINDOW.setSecondaryScreenDisplay(data)
  }
}
/**
 * 点单结束
 */
export function orderOver() {
  if (envName === "android") {
    ANDROID.orderOver()
  } else if (envName === "windows") {
    Vue.prototype.$store.commit("CLEAR_SECOND_SCREEN_DATA")
    WINDOW.setSecondaryScreenDisplay(
      Vue.prototype.$store.state.secondaryScreenData
    )
  }
}
