import i18n from "../../locales/index"
import axios from "@/libs/axios.js"
import { Message } from "element-ui"

const baseUrl = "https://fresh.chengbao68.com" + "/ai"
// const baseUrl = "http://110.40.136.12:6036";
const baseUrl2 = process.env.VUE_APP_API_HOST + "/fresh"
const baseUrl3 = baseUrl2 + "/ai"

const state = {
  productImg: "",
  vcp: null,
  predictData: null,
  predictProducts: null,
  predictionTimes: 0,
}

const mutations = {
  SET_PRODUCT_IMG: (state, img) => {
    state.productImg = img
  },
  SET_VCP: (state, vcp) => {
    state.vcp = vcp
  },
  SET_PREDICT_DATA: (state, data) => {
    state.predictData = data
  },
  SET_PREDICT_PRODUCTS: (state, data) => {
    state.predictProducts = data
  },
  SET_PREDICTION_TIMES: (state, data) => {
    state.predictionTimes = data
  },
}

const getters = {
  productUsedImg: (state) => state.productImg.split("base64,")[1],
  predictState: (state) => {
    if (state.predictProducts) {
      if (
        Array.isArray(state.predictProducts) &&
        state.predictProducts.length > 0
      ) {
        return 3
      } else {
        return 2
      }
    } else {
      return 1
    }
  },
}

const actions = {
  async learn_wai({ commit }, params) {
    params.alg = localStorage.getItem("aiAlgorithm") || "1"
    const body = new FormData()
    body.append("accSecurityId", "9E0DEF83C6DB4F753949")
    body.append("label", params.name)
    body.append("labelcn", params.name)
    body.append("labelcount", 10)
    body.append("img", params.image)
    await axios({
      method: "post",
      url: baseUrl + "/learn_wai",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: body,
    })
  },
  async predict_wai({ commit }, params) {
    params.alg = localStorage.getItem("aiAlgorithm") || "1"
    const body = new FormData()
    body.append("accSecurityId", "9E0DEF83C6DB4F753949")
    body.append("img", params.image)
    body.append("topk", 10)
    const data = await axios({
      method: "post",
      url: baseUrl + "/predict_wai",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: body,
    })
    console.log("data", data)
    const result = data.result.map((item) => {
      item.name = item.label
      return item
    })
    commit("SET_PREDICT_DATA", result)
    if (data.length === 0) {
      console.log("没有匹配的商品<=========")
    }
  },
  async learn_2({ commit, dispatch }, params) {
    const body = {
      base64_image: params.image,
      fresh_product_id: params.fresh_product_id,
      label_count: 8,
    }
    await axios({
      method: "post",
      url: baseUrl3 + "/add",
      data: body,
    })
  },
  async learn({ commit, dispatch }, params) {
    const aiAlgorithm = localStorage.getItem("aiAlgorithm") || "1"
    if (aiAlgorithm === "3") {
      await dispatch("learn_wai", params)
      return
    }
    if (aiAlgorithm === "4") {
      await dispatch("learn_2", params)
      return
    }
    params.alg = aiAlgorithm
    const body = new FormData()
    for (const key in params) {
      body.append(key, params[key])
    }
    await axios({
      method: "post",
      url: baseUrl + "/learn",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: body,
    })
  },
  async predict_2({ commit, dispatch }, params) {
    const body = {
      base64_image: params.image,
      topk: 8,
    }
    try {
      const { data = [] } = await axios({
        method: "post",
        url: baseUrl3 + "/predict",
        data: body,
      })
      commit("SET_PREDICT_DATA", data)
    } catch (err) {
      commit("SET_PREDICT_DATA", [])
    }
  },
  async predict({ commit, dispatch }, params) {
    const aiAlgorithm = localStorage.getItem("aiAlgorithm") || "1"
    if (aiAlgorithm === "4") {
      await dispatch("predict_2", params)
      return
    }
    if (aiAlgorithm === "3") {
      await dispatch("predict_wai", params)
      return
    }
    params.alg = aiAlgorithm
    const body = new FormData()
    for (const key in params) {
      body.append(key, params[key])
    }
    const data = await axios({
      method: "post",
      url: baseUrl + "/predict",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: body,
    })
    commit("SET_PREDICT_DATA", data)
    if (data.length === 0) {
      console.log("没有匹配的商品<=========")
    }
  },
  async destroy_all({ commit, dispatch }) {
    await axios({
      method: "post",
      url: baseUrl3 + "/destroy_all",
    })
    Message({
      message: i18n.t("store_modules_ai_838"),
      type: "success",
    })
  },

  aiSearch({ commit }, params) {
    return axios({
      method: "get",
      url: baseUrl2 + "/products/ai_index" + params,
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
