import i18n from "../../locales/index"
/* eslint-disable */
import { genBasicStore } from "../vuex"
import assign from "lodash/assign"
import axios from "@/libs/axios.js"
import {
  isTimeAvailable,
  isStatusAvailable,
  isCill,
  isFitCategory,
  isFitProduct,
} from "@/utils/activity.js"

const store = genBasicStore("deduct_coupon")

assign(store.states, {
  discount: 0,
})
assign(store.actions, {
  async use({ state, dispatch }) {
    if (!state.deduct_coupon.id) return
    await axios.post(`deduct_coupons/${state.deduct_coupon.id}/use`)
    await dispatch("index")
    state.deduct_coupon = {} // deduct_coupon设置为空
  },
})
assign(store.getters, {
  deduct_coupons_available: (state) => {
    return state.deduct_coupons.filter(
      (item) =>
        isTimeAvailable(item.effective_date, item.expired_date) &&
        isStatusAvailable(item.status)
    )
  },
  total: (state, getters) => {
    return getters.deduct_coupons_available.length
  },
  // TODO:这个值不响应式
  discount: (state) => state.discount,
})
assign(store.mutations, {
  customerDiscount(state, { products, total }) {
    console.log("products total", products, total)
    if (!state.deduct_coupon.id) return
    state.discount = 0 // 重置价格
    const type = state.deduct_coupon.deduct_category_type
    const timeAvailable = isTimeAvailable(
      state.deduct_coupon.effective_date,
      state.deduct_coupon.expired_date
    )
    const cill = isCill(state.deduct_coupon.use_it_minimum_consumption, total)
    switch (type) {
      case "shop":
        state.discount = Math.min(total, state.deduct_coupon.price)
        break
      case "fresh_category":
        if (timeAvailable && cill) {
          state.discount = isFitCategory(
            state.deduct_coupon.deduct_category_id,
            products,
            state.deduct_coupon.price
          )
        }
        break
      case "fresh_product":
        if (timeAvailable && cill) {
          state.discount = isFitProduct(
            state.deduct_coupon.deduct_category_id,
            products,
            state.deduct_coupon.price
          )
        }
        break
      default:
        break
    }
  },
  total: (state) => state.meta.total,
})
export default store
