import i18n from "../locales/index"
import axios from "axios"
import { Message } from "element-ui"
import Vue from "vue"
import router from "@/router"
import { getToken, setToken, getShopId } from "@/utils/auth"
import { getResMsg } from "@/utils"
import { validate as validateWhitelist } from "@/libs/response-intercept-whitelist.js"

let isfilterRes = true // 是否过滤res
let lastMsg = ""
let lastResTime = 0
function debounceMsg(msg) {
  // 错误提示防抖
  if (!(lastMsg === msg && lastResTime - new Date().getTime() < 1000)) {
    Message.error(msg)
  }
  lastMsg = msg
  lastResTime = new Date().getTime()
}
// request拦截器
axios.interceptors.request.use(
  (config) => {
    const conf = { ...config }
    // conf.validateStatus = (status) => status >= 200 && status <= 505;
    if (getToken()) {
      conf.headers.Authorization = getToken()
      conf.headers.ShopId = parseInt(getShopId()) || ""
    }
    isfilterRes = conf.headers.isfilterRes !== "no"
    return conf
  },
  (error) => Promise.reject(error) // 返回接口返回的错误信息
)
// response拦截器
axios.interceptors.response.use(
  (response) => (isfilterRes ? response.data : response),
  (error) => {
    if (error.response && !validateWhitelist(error.response.config.url)) {
      const code = error.response.status
      let msg = error.response?.data?.message
      if (msg === "Network Error") {
        msg = i18n.t("libs_axios_200")
      }
      if (!msg) msg = getResMsg(code)
      debounceMsg(msg)
      if (/^401$/.test(code)) {
        setToken("")
        router.replace("/login")
      }
    }
    return Promise.reject(error.response)
  }
)
// 跨域
axios.defaults.withCredentials = false
axios.defaults.baseURL =
  process.env.VUE_APP_API_HOST + process.env.VUE_APP_BASE_URL
Vue.prototype.$axios = axios
export default axios
