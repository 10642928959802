import i18n from "../../locales/index"
import { genBasicStore } from "../vuex"
import assign from "lodash/assign"
import axios from "@/libs/axios.js"
import { Message } from "element-ui"

const store = genBasicStore("desk_partition")

assign(store.actions, {
  async update({ commit }, params) {
    const { ...others } = params
    params.discount = +params.discount
    params.upgrade_standard = +params.upgrade_standard
    const response = await axios.put(`desk_partitions/${params.id}`, others)
    commit("updateRecordInRecords", response.data)
    Message.success(i18n.t("store_modules_desk_845"))
  },
  async create({ commit }, options) {
    const { ...params } = options
    params.discount = +params.discount
    params.upgrade_standard = +params.upgrade_standard
    const response = await axios.post("desk_partitions", params)
    commit("setRecord", response.data)
    commit("addRecordInRecords", response.data)
    Message.success(i18n.t("pages_product_product_add_707"))
  },
})

export default store
