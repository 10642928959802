import i18n from "../../locales/index"
/**
 * 注册全局组件
 * */
import PageHeader from "./page-header"
import PageTitle from "./page-title"

import createDialogSckey from "./DialogSckey"

export default {
  install(Vue, options = {}) {
    Vue.component("PageHeader", PageHeader)
    Vue.component("PageTitle", PageTitle)
    // 快捷键弹框组件
    Vue.prototype.$dialogsckey = createDialogSckey()
  },
}
