import i18n from "../locales/index"
export function getDeadComponents(to, from) {
  const exclude = []
  if (to === "selectProduct") {
    // 去点单页或从点单页来时, 清空上一个页面的alive
    exclude.push(from)
  }
  if (to === "home") {
    // 来去根路径, 清空上一个页面的alive
    exclude.push(from)
  }
  return exclude
}
