import i18n from "../../locales/index"
import { Message } from "element-ui"
import {
  noticeGuadan,
  createOrder,
  ordersPrint,
  orderPay,
  orderStatusQuery,
} from "@/api/order"

import { orderOver } from "@/utils/appInterface/secondDisplay"

import { print, openCashBox } from "@/utils/appInterface/jsbridge"

import router from "@/router"
import { keepDecimal, getDigit, checkDataLegitimate } from "@/utils"
import { add, subtract, multiply, divide, sd } from "@/utils/math"
import { computeFullSaleTotal } from "@/utils/discount"
import { playPaySuccess } from "@/utils/broadcast"
import { totalPriceCalculation } from "@/utils/priceCalculation"
import { offlineSaveOrder } from "@/utils/appInterface/offline"
import { localRead } from "@/utils"

const state = {
  selectProducts: [],
  pageSelectProducts: [],
  totalPiece: 0,
  total: 0, // 总价
  sd_total: 0, // 抹零后的总价
  discounted_total: 0, // 折后价
  sd_discounted_total: 0, // 抹零后的折后价
  finish_total: 0, // 实收
  change_amount: 0, // 找零

  payment_total: 0, // 用户支付的钱
  last_total: 0, // 一口价

  small_discount: false, // 是否抹零
  small_discounted_amount: 0, // 抹零了的金额

  discount: 100, // 整单打折折扣
  custom_percentage_discount: 0, // 打折优惠的金额
  discount_small_discounted_amount: 0, // 打折抹零的金额

  fresh_customer_id: null, // 会员ID
  customer_discounted_total: 0, // 会员价优惠了的金额

  order_items: [], // 所选商品
  payment_method: "cash_pay",
  loading: false,

  realFirstChange: true, // 用户第一次输入实收
  cycleIndex: 0,
  timer: null,

  selectedCart: { id: "" }, // 选中的购物车商品
  isShowCancelPayBtn: false, // 是否显示取消线上收款按钮

  playPaySuccessObj: {}, // 支付播报的对象

  offlineSingleOrder: {}, // 离线单个订单

  customer_count: 0, // 用餐人数
  isShowCustomerCount: false,
  orderId: null, // 当前订单id
}

const mutations = {
  SET_ORDER_ID: (state, data) => {
    state.orderId = data
  },
  SET_SHOW_CUSTOMER_COUNT: (state, data) => {
    state.isShowCustomerCount = data
  },
  SET_CUSTOMER_COUNT: (state, data) => {
    state.customer_count = data
  },
  SET_OFFLINE_SINGLE_ORDER: (state, data) => {
    state.offlineSingleOrder = data
  },
  SET_PAY_SUCCESS_OBJ_type: (state, data) => {
    state.playPaySuccessObj.type = data
  },
  SET_PAY_SUCCESS_OBJ_amount: (state, data) => {
    state.playPaySuccessObj.amount = data
  },
  SET_IS_SHOW_CANCEL_PAY_BTN: (state, show) => {
    state.isShowCancelPayBtn = show
  },
  SET_SELECTED_CART: (state, pro) => {
    state.selectedCart = pro
  },
  SET_REAL_FIRST_CHANGE: (state, realFirstChange) => {
    state.realFirstChange = realFirstChange
  },
  SET_SD_TOTAL: (state, sd_total) => {
    state.sd_total = sd_total
  },
  SET_SD_DISCOUNTED_TOTAL: (state, sd_discounted_total) => {
    state.sd_discounted_total = sd_discounted_total
  },
  SET_TOTAL_PIECE: (state, totalPiece) => {
    state.totalPiece = totalPiece
  },
  SET_SELECT_PRODUCTS: (state, selectProducts) => {
    state.selectProducts = selectProducts
  },
  SET_PAGE_SELECT_PRODUCTS: (state, pageSelectProducts) => {
    state.pageSelectProducts = pageSelectProducts
  },
  SET_PAYMENT_METHOD: (state, payment_method) => {
    state.payment_method = payment_method
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading
  },
  SET_ORDER_ITEMS: (state, order_items) => {
    state.order_items = order_items
  },
  SET_CUSTOMER_ID: (state, fresh_customer_id) => {
    state.fresh_customer_id = fresh_customer_id
  },
  SET_DISCOUNT: (state, discount) => {
    state.discount = discount
  },
  SET_CUSTOMER_DISCOUNTED_TOTAL: (state, customer_discounted_total) => {
    state.customer_discounted_total = customer_discounted_total
  },
  SET_TOTAL: (state, total) => {
    state.total = total
  },
  SET_SMALL_DISCOUNTED_AMOUNT: (state, small_discounted_amount) => {
    state.small_discounted_amount = small_discounted_amount
  },
  SET_DISCOUNT_SMALL_DISCOUNTED_AMOUNT: (
    state,
    discount_small_discounted_amount
  ) => {
    state.discount_small_discounted_amount = discount_small_discounted_amount
  },
  SET_DISCOUNTED_TOTAL: (state, discounted_total) => {
    state.discounted_total = discounted_total
  },
  SET_CUSTOM_PERCENTAGE_DISCOUNT: (state, custom_percentage_discount) => {
    state.custom_percentage_discount = custom_percentage_discount
  },
  SET_FINISH_TOTAL: (state, finish_total) => {
    state.finish_total = finish_total
  },
  SET_PAYMENT_TOTAL: (state, total) => {
    state.payment_total = total
  },
  SET_CHANGE_AMOUNT: (state, change_amount) => {
    state.change_amount = change_amount
  },
  SET_SMALL_DISCOUNT: (state, small_discount) => {
    state.small_discount = small_discount
  },
}

const getters = {
  // payment_total: (state) => state.finish_total,
  last_total: (state) => state.finish_total,
  last_discounted_total: (state) =>
    state.small_discount ? state.sd_discounted_total : state.discounted_total,
}

const actions = {
  computeTotalPiece({ state }) {
    // 计算总的商品件数
    state.totalPiece = 0
    state.selectProducts.forEach((item) => {
      if (item.need_to_weight) {
        state.totalPiece += 1
      } else {
        state.totalPiece += Number(item.count)
      }
    })
  },
  computeTotal({ state, commit, dispatch, rootState, rootGetters }) {
    console.log("计算了")
    // 计算总价、折后价
    let { memberInfo } = rootState
    let memberObj = null
    if (!memberInfo) memberInfo = {}
    if (memberInfo.can_use_huiyuanjia) {
      memberObj = {}
      memberObj.level_id = memberInfo.fresh_customer_level_id
    }
    const { totalPrice, memberTotal } = totalPriceCalculation(
      state.selectProducts,
      memberObj
    )
    // 折后价
    const discountedPrice = memberObj ? memberTotal : totalPrice
    commit("SET_CUSTOMER_DISCOUNTED_TOTAL", subtract(totalPrice, memberTotal))
    commit("SET_TOTAL", totalPrice)
    commit("SET_DISCOUNTED_TOTAL", discountedPrice)

    if (memberInfo.id && localStorage.getItem("integralOffset") === "1") {
      if (discountedPrice < rootGetters.usedScore.amount) {
        // 折后价小于积分的钱
        commit("SET_FINISH_TOTAL", 0)
      } else {
        commit(
          "SET_FINISH_TOTAL",
          subtract(discountedPrice, rootGetters.usedScore.amount)
        )
      }
    } else {
      commit("SET_FINISH_TOTAL", discountedPrice)
    }
    dispatch("computeTotalPiece")
  },
  // 更新抹零
  updateSmallDiscount({ state, commit }) {
    return new Promise((resolve, reject) => {
      const digit = getDigit()
      commit("SET_SD_TOTAL", sd(state.total, digit))
      commit("SET_SD_DISCOUNTED_TOTAL", sd(state.discounted_total, digit))
      resolve()
    })
  },
  // 改变实收价格
  changePaymentTotal({ state, commit }, total) {
    if (!checkDataLegitimate(total)) return
    if (/^0([0-9])/.test(total)) {
      commit("SET_PAYMENT_TOTAL", RegExp.$1)
    } else {
      commit("SET_PAYMENT_TOTAL", total)
    }
  },
  // 这里只做折后价的赋值
  setDiscountedTotal({ state, dispatch, commit, getters }, discounted_total) {
    if (!checkDataLegitimate(discounted_total)) return
    const digit = getDigit()
    commit("SET_DISCOUNTED_TOTAL", discounted_total)
    commit("SET_SD_DISCOUNTED_TOTAL", sd(state.discounted_total, digit))
    if (state.realFirstChange) {
      // 用户还没改变实收金额时, 实收金额等于最终的折后价
      state.payment_total = getters.last_discounted_total
    }
  },
  // 改变折后总价时, 相关所有价格的变动
  setDiscountedRelatedPrice(
    { state, dispatch, commit, getters },
    discounted_total
  ) {
    dispatch("setDiscountedTotal", discounted_total)
    if (state.realFirstChange) {
      // 用户还没改变实收金额时, 实收金额等于最终的折后价
      dispatch("changePaymentTotal", getters.last_discounted_total)
    }
  },
  // 改变折后总价
  changeDiscountedTotal(
    { state, dispatch, commit, getters },
    discounted_total
  ) {
    // if (!checkDataLegitimate(discounted_total)) return
    // const digit = getDigit()
    // if (discounted_total.toString().slice(-1) === ".") {
    //   commit("SET_SD_DISCOUNTED_TOTAL", discounted_total)
    //   commit("SET_DISCOUNTED_TOTAL", discounted_total)
    // } else {
    // commit("SET_DISCOUNTED_TOTAL", sd(discounted_total, digit))
    // commit("SET_SD_DISCOUNTED_TOTAL", sd(state.discounted_total, digit))
    // }
    dispatch("setDiscountedRelatedPrice", discounted_total)
    commit(
      "SET_DISCOUNT",
      keepDecimal(divide(getters.last_discounted_total, state.total) * 100, 2)
    )
  },
  // 改变全场折扣
  changeDiscount({ state, dispatch, commit, rootState }, discount) {
    if (!checkDataLegitimate(discount)) return
    commit("SET_DISCOUNT", discount)
    const isMember =
      rootState.memberInfo && rootState.memberInfo.can_use_huiyuanjia
    const total = computeFullSaleTotal(state.selectProducts, discount, isMember)
    dispatch("setDiscountedRelatedPrice", total)
  },
  // 创建订单
  createOrder({ commit }, orderData) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      if (localStorage.getItem("isOffline") === "1") {
        const orderid = offlineSaveOrder(orderData)
        if (orderid) {
          commit("SET_OFFLINE_SINGLE_ORDER", orderData)
          commit("SET_LOADING", false)
          resolve({ data: { id: orderid } })
        } else {
          const message = i18n.t("store_modules_orderplus_856")
          Message.warning(message)
          commit("SET_LOADING", false)
          reject({ message })
        }
      } else {
        createOrder(orderData)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
            commit("SET_LOADING", false)
          })
      }
    })
  },
  // 订单状态清空
  orderClear({ state, commit, dispatch, rootState }, payInfo) {
    commit("SET_MEMBER_INFO", null, { root: true })
    rootState.product.selectedStartIndex = 0
    rootState.product.selectedEndIndex = 5
    rootState.product.pendOrderIndex = 0
    rootState.product.searchProducts = []
    commit("SET_SELECT_PRODUCTS", [])
    commit("SET_PAGE_SELECT_PRODUCTS", [])
    commit("SET_FINISH_TOTAL", 0)
    commit("SET_TOTAL", 0)
    commit("SET_DISCOUNTED_TOTAL", 0)
    commit("SET_SD_TOTAL", 0)
    commit("SET_SD_DISCOUNTED_TOTAL", 0)
    commit("SET_SELECTED_CART", { id: "" })
    commit("activity/SET_ORDER_IS_FIRST", false, { root: true }) // 首单检测设置为false
    commit("SET_CUSTOMER_COUNT", 0) // 订单有几个人吃
    commit("SET_ORDER_ID", null) // 当前订单ID
  },
  // 订单支付
  orderPay({ state, commit, dispatch, rootState }, payInfo) {
    return new Promise((resolve, reject) => {
      // 离线支付
      if (localStorage.getItem("isOffline") === "1") {
        Message.success(i18n.t("pages_order_order_history_647"))
        playPaySuccess(
          state.playPaySuccessObj.type,
          state.playPaySuccessObj.amount
        )
        commit("SET_LOADING", false)
        dispatch("orderSuccessAfter", payInfo)
        resolve(payInfo)
      } else {
        orderPay(payInfo.id, payInfo.body)
          .then(({ data, status }) => {
            if (status === 422 && state.payment_method === "alipay_facepay") {
              commit("SET_LOADING", false)
              return
            }
            if (
              state.payment_method === "scan_code_pay" ||
              state.payment_method === "alipay_facepay"
            ) {
              commit("SET_IS_SHOW_CANCEL_PAY_BTN", true)
              dispatch("orderStatusPoll", payInfo)
            } else {
              Message.success(i18n.t("pages_order_order_history_647"))
              playPaySuccess(
                state.playPaySuccessObj.type,
                state.playPaySuccessObj.amount
              )
              commit("SET_LOADING", false)
              dispatch("orderSuccessAfter", data)
            }
            resolve(data)
          })
          .catch((error) => {
            commit("SET_LOADING", false)
            reject(error)
          })
      }
    })
  },
  // 支付成功后的操作
  orderSuccessAfter({ state, dispatch }, data) {
    if (localStorage.getItem("isOffline") === "1") {
      // TODO 打印逻辑
      console.log("printer_config/offlinePrint马上调用了")
      dispatch(
        "printer_config/offlinePrint",
        {
          fresh_order_id: data.id,
          category: "pay",
        },
        { root: true }
      )
    } else {
      dispatch(
        "printer_config/print",
        {
          fresh_order_id: data.id,
          category: "pay",
          scene: "print_after_pay",
          fresh_desk_id: data.fresh_desk_id,
        },
        { root: true }
      )
    }

    dispatch("orderClear")
    orderOver() // 副屏点单结束
    if (state.payment_method === "cash_pay") {
      openCashBox()
    }
    router.replace("/")
  },
  // 订单状态轮训
  orderStatusPoll({ state, commit, dispatch }, payInfo) {
    state.cycleIndex = 0
    dispatch("orderStatusQuery", payInfo).then((data) => {
      console.log("orderStatusQuerydata", data)
      if (!data) {
        if (state.timer) state.timer = null
        state.timer = setInterval(() => {
          if (window.location.href.indexOf("cashier") > -1) {
            dispatch("orderStatusQuery", payInfo)
          } else {
            clearInterval(state.timer)
          }
        }, 5000)
      }
    })
  },
  // 查询订单状态
  orderStatusQuery({ state, commit, dispatch }, payInfo) {
    return new Promise((resolve, reject) => {
      orderStatusQuery(payInfo.id)
        .then(({ data, message }) => {
          console.log("data111", data)
          console.log("message111", message)
          if (data && data.status === "paid") {
            commit("SET_IS_SHOW_CANCEL_PAY_BTN", false)
            Message.success(i18n.t("pages_order_order_history_647"))
            playPaySuccess(
              state.playPaySuccessObj.type,
              state.playPaySuccessObj.amount
            )
            commit("SET_LOADING", false)
            // dispatch('ordersPrint', id);
            dispatch(
              "printer_config/print",
              {
                fresh_order_id: payInfo.id,
                category: "pay",
                scene: "print_after_pay",
                fresh_desk_id: payInfo.fresh_desk_id,
              },
              { root: true }
            )
            dispatch("orderClear")
            orderOver() // 副屏点单结束
            if (state.payment_method === "cash_pay") {
              openCashBox()
            }
            router.replace("/")
            clearInterval(state.timer)
            state.cycleIndex = 0
            resolve(data)
            return
          }
          state.cycleIndex++
          if (
            state.cycleIndex >= 15 ||
            message === i18n.t("store_modules_bill_841") ||
            message === i18n.t("store_modules_bill_842")
          ) {
            clearInterval(state.timer)
            state.cycleIndex = 0
            commit("SET_LOADING", false)
            commit("SET_IS_SHOW_CANCEL_PAY_BTN", false)
          }
          resolve(data)
        })
        .catch((error) => {
          commit("SET_LOADING", false)
          clearInterval(state.timer)
          state.cycleIndex = 0
          reject(error)
        })
    })
  },
  ordersPrint({ commit }, no) {
    return new Promise((resolve, reject) => {
      ordersPrint(no)
        .then(({ data }) => {
          const cReceipts = localStorage.getItem("cashing_receipts")
          print(data.content, cReceipts ? parseInt(cReceipts) : 1)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 通知服务端我挂单了
  noticeGuadan({ commit }, pendInfo) {
    return new Promise((resolve, reject) => {
      noticeGuadan(pendInfo)
        .then(({ data }) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
