import i18n from "../../locales/index"
export default [
  {
    // 积分兑换
    path: "/scoreExchange",
    name: "scoreExchange",
    component: () =>
      import(
        /* webpackChunkName: "scoreExchange" */ "@/pages/score/exchange.vue"
      ),
  },
  {
    // 积分兑换记录
    path: "/scoreExchangeHistory",
    name: "scoreExchangeHistory",
    component: () =>
      import(
        /* webpackChunkName: "scoreExchangeHistory" */ "@/pages/score/history.vue"
      ),
  },
]
