import i18n from "../locales/index"
import { localRead } from "@/utils"
/**
 * 权鉴
 * @param {*} name 即将跳转的路由name
 * @description 用户是否可跳转到该页
 */
export const canTurnTo = (name) => {
  let permissions = null
  try {
    permissions = JSON.parse(localRead("cashierExcludePermissions"))
  } catch {
    permissions = []
  }
  const result = permissions.find((item) => {
    const p = item.name.split(":").pop()
    if (p === name) {
      return item
    }
  })
  return !result
}
