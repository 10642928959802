var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'baby-radio',
    {
      'baby-radio__checked': _vm.isChecked,
    },
    {
      'baby-radio__disabled': _vm.disabled,
    },
  ],on:{"click":_vm.change}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.$t("components_baby_ui_radio_radio_18")))]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }