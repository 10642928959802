<template>
  <div class="keyboard">
    <div
      v-for="(item, index) in keys"
      :key="item[0]"
      :class="['keyboard__item', getKeyClassName(item[1], index)]"
      @click="tapKey(item[1], index)"
    >
      <i v-if="item[1] === 'backspace'" class="backspace-icon"></i>
      <span v-else>{{ item[0] }}</span>
    </div>
  </div>
</template>
<script>
import i18n from "../../../locales/index"

const EXTRA_KEY = [
  {
    type: "value",
    key: "00",
    value: "00",
  },
  {
    type: "event",
    key: i18n.t("components_baby_ui_number_keyboard_index_12"),
    value: "complete",
    className: "keyboard__item--merge",
  },
]
// 是额外的键
function isExtraKey(index) {
  return [13, 14, 15].indexOf(index) > -1
}
// 校验额外的值
function validateExtraValue(value) {
  if (typeof value !== "object") {
    throw new TypeError(i18n.t("components_baby_ui_number_keyboard_index_13"))
  }
  if (!Reflect.has(value, "type")) {
    throw new TypeError(i18n.t("components_baby_ui_number_keyboard_index_14"))
  }
  if (!Reflect.has(value, "key")) {
    throw new TypeError(i18n.t("components_baby_ui_number_keyboard_index_15"))
  }
  if (!Reflect.has(value, "value")) {
    throw new TypeError(i18n.t("components_baby_ui_number_keyboard_index_16"))
  }
  return true
}
export default {
  name: "NumberKeyboard",
  props: {
    inputRange: {
      // 有几位小数点
      type: Number,
      default: 2,
    },
    modelValue: {
      type: String,
    },
    extraKey: {
      // 额外的健
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      basekeys: [
        [1, 1],
        [2, 2],
        [3, 3],
        ["backspace", "backspace"],
        [4, 4],
        [5, 5],
        [6, 6],
        [i18n.t("components_baby_ui_number_keyboard_index_17"), "clear"],
        [7, 7],
        [8, 8],
        [9, 9],
        [".", "."],
        [0, 0],
        ["00", "00"],
      ],
    }
  },
  computed: {
    keys() {
      let data = [...this.basekeys]
      const extraKey = this.extraKey || EXTRA_KEY
      extraKey.forEach((keyObj, index) => {
        const { type, key, value } = keyObj
        let current = [key, keyObj]
        if (index === 0) {
          data[13] = current
        } else {
          data.push(current)
        }
      })
      return data
    },
  },
  methods: {
    tapKey(value, index) {
      if (value === "backspace") {
        this.$emit("update:modelValue", this.modelValue.slice(0, -1) || "")
        this.$emit("delete", null)
      } else if (value === "clear") {
        this.$emit("update:modelValue", "")
        this.$emit("clear", null)
      } else if (isExtraKey(index)) {
        // 额外的健处理
        if (validateExtraValue(value)) {
          if (value.type === "value") {
            this.updateKeyboardValue(value.value)
          } else if (value.type === "event") {
            // 事件类型, 发一个emit
            this.$emit(value.value, null)
          }
        }
      } else {
        this.updateKeyboardValue(value)
      }
    },
    // 更新键盘的值
    updateKeyboardValue(value) {
      const val = this.modelValue + value.toString()
      // 首位不能为小数点
      if (val.startsWith(".")) return
      // 就餐人数首位不为0
      if (/cateringtable/g.test(window.location.hash) && val.startsWith(0))
        return
      // 只能有一个小数点
      if (val.indexOf(".") > 0) {
        // 只能有一个小数点
        if (value === ".") return
        // 只能有几个小数点
        if (val.split(".")[0].length > this.inputRange) return
      }
      // inputRange
      this.$emit("update:modelValue", val)
      this.$emit("input", value)
    },
    // 获取额外键的className
    getKeyClassName(value, index) {
      if (isExtraKey(index)) {
        if (validateExtraValue(value) && value.className) {
          return value.className
        }
      }
      return ""
    },
  },
}
</script>

<style scope lang="scss">
.backspace-icon {
  @include bady-icon("backspace", 78px, 44px);
}

.keyboard {
  width: 100%;
  height: 100%;
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
  background: #f6f6f6;
  // justify-items: stretch;
  // align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  &__item {
    padding: 17px;
    border: 1px solid #cecece;
    font-weight: 500;
    text-align: center;
    font-size: 40px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 25%;
    box-sizing: border-box;
    &--merge {
      // grid-column: 3 / 5;
      width: 50%;
      color: #ffffff;
      font-size: 32px;
      background: #f35050;
    }
    &:nth-child(8) {
      font-size: 32px;
    }
  }
}
</style>
