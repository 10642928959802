import i18n from "../locales/index"
import request from "@/utils/request"
const baseurl = process.env.VUE_APP_BASE_URL

// 创建一个货号
export function generateBarCode(query) {
  return request({
    url: baseurl + `/products/${query.id}/generate_bar_code`,
    method: "post",
    data: query,
  })
}

// 分析条码
export function analyzeBarCode(query) {
  return request({
    url: baseurl + "/products/analyze_bar_code",
    method: "get",
    params: query,
  })
}

// 获取条码规则
export function getbarCodeRule(query) {
  return request({
    url: baseurl + `/bar_codes/${query.id}`,
    method: "get",
  })
}
