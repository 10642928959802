import i18n from "../../locales/index"
import {
  getEnoughConsumeDiscounts,
  ordersCheckFirst,
  getLimitedTimeActivities,
  getCombos,
} from "@/api/activity"
import { isItInTimeInterval } from "@/utils"

const state = {
  enoughConsumeDiscounts: [], // 满减活动列表
  limitedTimeActivities: [], // 限时优惠活动列表
  combos: [], // 商品套餐
  orderIsFirst: false, // 订单是否是首单
  firstOrderDiscountOpen: false, // 订单是否打开首单优惠
  firstOrderDiscount: 0, // 首单优惠金额
  offline_coupon_alias_name: i18n.t("store_modules_activity_837"),
  birthday_discount: 100,
  birthday_discount_open: false,
}

const mutations = {
  SET_COMBOS: (state, data) => {
    state.combos = data
  },
  SET_LIMITED_TIME_ACTIVITIES: (state, data) => {
    state.limitedTimeActivities = data
  },
  SET_ENOUGH_CONSUME_DISCOUNTS: (state, data) => {
    state.enoughConsumeDiscounts = data
  },
  SET_ORDER_IS_FIRST: (state, data) => {
    state.orderIsFirst = data
  },
  SET_FIRST_ORDER_DISCOUNT_OPEN: (state, data) => {
    state.firstOrderDiscountOpen = data
  },
  SET_FIRST_ORDER_DISCOUNT: (state, data) => {
    state.firstOrderDiscount = parseFloat(data)
  },
  SET_BIRTHDAY_DISCOUNT_OPEN: (state, data) => {
    state.birthday_discount_open = data
  },
  SET_BIRTHDAY_DISCOUNT: (state, data) => {
    state.birthday_discount = parseFloat(data)
  },
  SET_OFFLINE_COUPON_ALIAS_NAME: (state, data) => {
    state.offline_coupon_alias_name = data
  },
}

const getters = {
  // 在进行中的满减活动
  ongoingFullReductionActivity: (state) => {
    return state.enoughConsumeDiscounts.filter((activity) => {
      const isCorrectTime = isItInTimeInterval(
        activity.start_at,
        activity.end_at,
        new Date()
      )
      return activity.is_running && isCorrectTime
    })
  },
  // 在进行中的限时优惠活动
  ongoingLimitedTimeActivities: (state) => {
    return state.limitedTimeActivities.filter((activity) => activity.is_running)
  },
  ongoingCombos: (state) => {
    return state.combos.filter((activity) => activity.is_running)
  },
  // 是否有首单优惠
  hasFirstOrderActivity: (state) => {
    return state.firstOrderDiscountOpen && state.orderIsFirst
  },
  // 是否有会员生日优惠
  hasBirthdayDiscount: (state) => {
    return state.birthday_discount_open && state.orderIsFirst
  },
}

const actions = {
  // 获取满减活动列表
  getEnoughConsumeDiscounts({ commit, getters }) {
    return new Promise((resolve, reject) => {
      getEnoughConsumeDiscounts()
        .then(({ data }) => {
          commit("SET_ENOUGH_CONSUME_DISCOUNTS", data)
          console.log("getEnoughConsumeDiscountsdata", data)
          console.log(
            "ongoingFullReductionActivity",
            getters.ongoingFullReductionActivity
          )
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 获取限时优惠活动列表
  getLimitedTimeActivities({ commit, getters }) {
    return new Promise((resolve, reject) => {
      getLimitedTimeActivities()
        .then(({ data }) => {
          commit("SET_LIMITED_TIME_ACTIVITIES", data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 获取商品套餐
  getCombos({ commit, getters }) {
    return new Promise((resolve, reject) => {
      getCombos()
        .then(({ data }) => {
          commit("SET_COMBOS", data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 首单优惠检测
  ordersCheckFirst({ commit }, id) {
    return new Promise((resolve, reject) => {
      ordersCheckFirst({ fresh_customer_id: id })
        .then(({ data = {} }) => {
          commit("SET_ORDER_IS_FIRST", data.first)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
