import i18n from "../../locales/index"
// 会员模块
export default [
  {
    // 搜索会员
    path: "/member",
    name: "member",
    component: () =>
      import(/* webpackChunkName: "member" */ "@/pages/member/index.vue"),
    children: [
      {
        path: "/member/index",
        name: "memberIndex",
        component: () =>
          import(
            /* webpackChunkName: "memberIndex" */ "@/pages/member/index.vue"
          ),
      },
    ],
  },
  {
    // 搜索会员
    path: "/memberSearch",
    name: "memberSearch",
    component: () =>
      import(
        /* webpackChunkName: "memberSearch" */ "@/pages/member/member-search.vue"
      ),
  },
  {
    // 会员详情
    path: "/memberDetail",
    name: "memberDetail",
    component: () =>
      import(
        /* webpackChunkName: "memberDetail" */ "@/pages/member/member-detail.vue"
      ),
  },
  {
    // 会员充值
    path: "/memberRecharge",
    name: "memberRecharge",
    component: () =>
      import(
        /* webpackChunkName: "memberRecharge" */ "@/pages/member/member-recharge.vue"
      ),
  },
  {
    // 会员添加
    path: "/memberAdd",
    name: "memberAdd",
    component: () =>
      import(
        /* webpackChunkName: "memberRecharge" */ "@/pages/member/member-add.vue"
      ),
  },
]
