import i18n from "../../../locales/index"
export default {
  name: "DialogSckey",
  data() {
    return {
      isVisible: false,
      tableData: [],
    }
  },
  props: {},

  computed: {},

  methods: {
    show(data) {
      this.tableData = data
      this.isVisible = true
    },
    hide() {
      this.isVisible = false
    },
    toggle(data) {
      this.tableData = data
      this.isVisible = !this.isVisible
    },
  },

  render(h) {
    const { width, isVisible, tableData } = this
    return (
      <elDialog
        title={i18n.t("app_1")}
        visible={isVisible}
        width="35%"
        on-close={this.hide}
      >
        <elTable data={tableData}>
          <elTableColumn
            property="name"
            label={i18n.t("components_common_dialogsckey_dialogsckey_24")}
          ></elTableColumn>
          <elTableColumn
            property="key"
            label={i18n.t("components_common_dialogsckey_dialogsckey_25")}
          ></elTableColumn>
        </elTable>
      </elDialog>
    )
  },
}
