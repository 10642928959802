<template>
  <div
    :class="[
      'baby-radio',
      {
        'baby-radio__checked': isChecked,
      },
      {
        'baby-radio__disabled': disabled,
      },
    ]"
    @click="change"
  >
    <slot>{{ $t("components_baby_ui_radio_radio_18") }}</slot>
  </div>
</template>

<script>
import i18n from "../../../locales/index"

import { computed, ref, getCurrentInstance } from "vue"
import { findComponentsSameLevel, isThisParent } from "../shared"

// interface Props {
//   name: any
//   checked: boolean
// }
export default {
  name: "BabyRadio",
  props: {
    name: {
      validator() {
        return true
      },
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const instance = getCurrentInstance().proxy
    const parent = instance.$parent
    const hasGroup = isThisParent(instance, "BabyRadioGroup")
    const elements = findComponentsSameLevel(instance)

    const isChecked = computed({
      get() {
        if (props.checked) {
          return props.checked
        } else {
          return value.value
        }
      },
      set(newValue) {
        context.emit("update:checked", newValue)
      },
    })
    const value = ref(false)
    const _id = ref(0)
    function clear() {
      value.value = false
      isChecked.value = false
    }
    function change() {
      const newValue = !value.value
      console.log("newValue", newValue, hasGroup, instance)
      value.value = newValue
      isChecked.value = newValue
      context.emit("change", newValue)
      if (hasGroup) {
        for (let element of elements) {
          if (element._uid === _id.value) {
            continue
          } else {
            element.clear && element.clear()
          }
        }
        instance?.$parent?.updateModel()
      }
    }
    function init() {
      if (!_id.value) {
        _id.value = this._uid
      }
      if (hasGroup) {
        for (let element of elements) {
          if (element._uid === _id.value) {
            if (parent.modelValue === props.name) {
              value.value = true
              isChecked.value = true
            } else {
              value.value = false
              isChecked.value = false
            }
          }
        }
      }
    }
    return {
      isChecked,
      change,
      clear,
      init,
    }
  },
  methods: {},
}
</script>
<style scope lang="scss">
.baby-radio {
  width: 100px;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  &__checked {
    border: 2px solid $brand-color;
    color: $brand-color;
  }
  &__disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.3;
  }
}
</style>
