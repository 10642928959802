import i18n from "../../locales/index"
export default [
  {
    path: "/401",
    name: "error401",
    meta: {},
    component: () =>
      import(/* webpackChunkName: "Error401" */ "@/pages/errorPage/401.vue"),
  },
  {
    path: "/404",
    name: "error404",
    meta: {},
    component: () =>
      import(/* webpackChunkName: "Error401" */ "@/pages/errorPage/404.vue"),
  },
  {
    path: "/500",
    name: "error500",
    meta: {},
    component: () =>
      import(/* webpackChunkName: "Error500" */ "@/pages/errorPage/500.vue"),
  },
  {
    path: "*",
    name: "error404",
    meta: {},
    component: () =>
      import(/* webpackChunkName: "Error404" */ "@/pages/errorPage/404.vue"),
  },
]
