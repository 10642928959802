import i18n from "../locales/index"
import { keepDecimal, keepRoundDecimal } from "@/utils"
import Big from "big.js"

/**
 * @param {(number)} arg1
 * @param {number} arg2
 * @returns {number}
 */
// 除法函数，用来得到精确的除法结果
// 说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
// 调用：accDiv(arg1,arg2)
// 返回值：arg1除以arg2的精确结果

export function divide(arg1, arg2) {
  if (!arg1) {
    arg1 = 0
  }
  if (!arg2) {
    arg2 = 0
  }
  const x = new Big(arg1)
  const y = new Big(arg2)
  return x.div(y).toNumber()
}

/**
 * @param {(number)} arg1
 * @param {number} arg2
 * @returns {number}
 */
// 乘法函数，用来得到精确的乘法结果
// 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
// 调用：accMul(arg1,arg2)
// 返回值：arg1乘以arg2的精确结果
export function multiply(arg1, arg2) {
  // const m = 0;
  if (!arg1) {
    arg1 = 0
  }
  if (!arg2) {
    arg2 = 0
  }
  const x = new Big(arg1)
  const y = new Big(arg2)
  return x.times(y).toNumber()
}

/**
 * @param {(number)} arg1
 * @param {number} arg2
 * @returns {number}
 */
// 加法函数，用来得到精确的加法结果
// 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
// 调用：accAdd(arg1,arg2)
// 返回值：arg1加上arg2的精确结果
export function add(arg1, arg2) {
  if (!arg1) {
    arg1 = 0
  }
  if (!arg2) {
    arg2 = 0
  }
  const x = new Big(arg1)
  const y = new Big(arg2)
  return x.plus(y).toNumber()
}

/**
 * @param {(number)} arg1
 * @param {number} arg2
 * @returns {number}
 */
// 减法函数，用来得到精确的减法结果
export function subtract(arg1, arg2) {
  if (!arg1) {
    arg1 = 0
  }
  if (!arg2) {
    arg2 = 0
  }
  const x = new Big(arg1)
  const y = new Big(arg2)
  return x.minus(y).toNumber()
}

/**
 * 数据抹零方法
 * @param {(number)} arg1
 * @param {number} arg2
 * @returns {number}
 */
export function sd(originPrice, digit) {
  let decimal = 2
  let price = 0
  if (originPrice.toString().slice(-1) === ".") {
    // 最后一位是., 去掉这个.
    originPrice = originPrice.toString().slice(0, -1)
  }
  if (!digit || digit === "none") {
    return originPrice
  }
  switch (digit) {
    case "jiao":
      decimal = 1
      break
    case "yuan":
      decimal = 0
      break
    case "shiyuan":
      decimal = -1
      break
    case "fen-round":
      decimal = "round-2"
      break
    case "jiao-round":
      decimal = "round-1"
      break
    case "yuan-round":
      decimal = "round-0"
      break
    case "shiyuan-round":
      decimal = "round--1"
      break
    default:
      decimal = 2
  }
  // 四舍五入抹零
  const reg = /round-(.+)/
  if (reg.test(decimal)) {
    // 是四舍五入抹零
    const dc = parseInt(RegExp.$1)
    if (dc !== -1) {
      price = keepRoundDecimal(originPrice, dc)
    } else if (originPrice > 100) {
      price = Math.round(originPrice / 100) * 100
    } else {
      price = keepRoundDecimal(originPrice, 2)
    }
    return price
  }

  // 正常抹零
  if (decimal !== -1) {
    price = keepDecimal(originPrice, decimal)
  } else if (originPrice > 100) {
    price = Math.floor(originPrice / 100) * 100
  } else {
    price = keepDecimal(originPrice, 2)
  }
  return price
}
